import { useParams } from "react-router-dom";

export default function PrintReview(){
    const {browser,msgIndex,lang,numOfPrints} = useParams();
    if(browser=="edge"){
        window.location.replace('https://microsoftedge.microsoft.com/addons/detail/print-button-extension/cnlgiopneojkkedfmolbfpobbgjcbbmo');
    }else{
        window.location.replace('https://chromewebstore.google.com/detail/print-print-button/mhcepidlpdgdfcliacjbmiomdiilmhij');
    }        
    return(
        <></>
    )
}