import { useParams } from "react-router-dom";

export default function ImageDownloaderReview(){
    const {browser,msgIndex,lang,numOfDownloads} = useParams();
    if(browser=="chrome"){
        window.location.replace('https://chromewebstore.google.com/detail/image-downloader-extensio/fpelahbljekknahkcaegamhcndkfpfnc');
    }else if(browser=="edge"){
        window.location.replace('https://microsoftedge.microsoft.com/addons/detail/image-downloader-save-a/flbolonkboilcbnohjlchlfgdalomdpl');
    }else{
        window.location.replace('https://addons.mozilla.org/en-US/firefox/addon/image-downloader-for-firefox');
    }
    return(
        <></>
    )
}