import { useParams } from "react-router-dom";


export default function ImageDownloaderMoreInfo(props){

const {language} = useParams();
    let lang = language;
    document.title = "Image downloader-more info";
const translation = {
  "en": [
    "April Update Notification",
    "The April update will require you to accept new permissions. Until you accept them, the browser will disable the extension.",
    "What are the new permissions?", 
    "The first new permission allows the extension to detect when the user enables it. The second permission allows the extension to block the window that pops up when the user downloads images.",
    "Why are we requesting these permissions?", 
    "These permissions help prevent confusing behavior in certain cases when the user re-enables the extension and enhance the user experience by preventing the browser from displaying unwanted popups.",
    "Current Behavior:", 
    "After re-enabling the extension, it does not work until the user refreshes the tab. Even then, the extension only works for the refreshed tab, requiring the user to manually refresh all other open tabs.",
    "When the user downloads images, the browser blocks the UI with a popup that shows the download progress.",
    "Future Behavior:", 
    "After re-enabling the extension, it will work immediately across all tabs without requiring a manual refresh.",
    "The extension will display download progress with an integrated progress bar. The user will not be interrupted, yet they can still use the browser's download tracker for more details."
  ],
  "am": [
    "የኤፕሪል አዘምንት ማሳወቂያ",
    "የኤፕሪል አዘምንት አዲስ ፈቃዶችን እንዲቀበሉ ያስፈልጋል። እስከሚቀበሉት ድረስ፣ አሳሽ ተሰኪውን ይሰናከል።",
    "አዲስ ፈቃዶች ምንድን ናቸው?", 
    "የመጀመሪያው ፈቃድ ተሰኪው በተጠቃሚው ሲነቃ የሚሆንበትን ይቀይር። ሁለተኛው ፈቃድ ተሰኪውን የምስሎችን ማውረድ ሲያከናውን የሚበተነውን መስኮት ይከለከላል።",
    "ለምን እነዚህን ፈቃዶች እየጠየቅን ነን?", 
    "እነዚህ ፈቃዶች ተሰኪውን ከድጋሚ ሲነቃ የሚከሰት የማይጠቅም ባህሪ ለመከላከል እና የተጠቃሚውን ልምድ ለማሻሻል ይረዳሉ።",
    "የአሁኑ ባህሪ:", 
    "ተሰኪው ከድጋሚ ሲነቃ እስከሚታደስ ድረስ አይሰራም። እንዲሁም፣ በተዳዳሪው ውስጥ ሌሎች ትዕይኖችን በተጠቃሚው በእርስዋ ማድረስ ይኖርበታል።",
    "ተጠቃሚው ምስሎችን ሲያውርድ፣ ተዳዳሪው አይነት የማውረድ ሂደትን ይሰራል።",
    "የወደፊቱ ባህሪ:", 
    "ተሰኪው ሲነቃ ያለ ማዳደስ በሁሉም ትዕይኖች ይሰራ።",
    "ተሰኪው የማውረድ ሂደትን በተዋሳኝ መስመር ይቀምጣል፣ ተጠቃሚው አይተውትም።"
  ],
  
  "ar": [
    "إشعار تحديث أبريل",
    "سيتطلب تحديث أبريل منك قبول الأذونات الجديدة. حتى تقبلها، سيقوم المتصفح بتعطيل الامتداد.",
    "ما هي الأذونات الجديدة؟", 
    "يسمح الإذن الأول للامتداد باكتشاف متى يقوم المستخدم بتمكينه. الإذن الثاني يسمح للامتداد بحظر النافذة التي تنبثق عند تنزيل الصور.",
    "لماذا نطلب هذه الأذونات؟", 
    "تساعد هذه الأذونات في منع السلوك المربك في بعض الحالات عند إعادة تمكين الامتداد، وتحسين تجربة المستخدم عن طريق منع المتصفح من عرض النوافذ المنبثقة غير المرغوب فيها.",
    "السلوك الحالي:", 
    "بعد إعادة تمكين الامتداد، لا يعمل حتى يقوم المستخدم بتحديث علامة التبويب. حتى بعد التحديث، يعمل الامتداد فقط للعلامة المحدثة، مما يتطلب تحديث جميع العلامات الأخرى يدويًا.",
    "عند تنزيل الصور، يقوم المتصفح بحجب الواجهة بنوافذ منبثقة تظهر تقدم التنزيل.",
    "السلوك المستقبلي:", 
    "بعد إعادة تمكين الامتداد، سيعمل فورًا عبر جميع علامات التبويب دون الحاجة إلى تحديث يدوي.",
    "سيعرض الامتداد تقدم التنزيل بشريط تقدم مدمج، ولن تتم مقاطعة المستخدم، ولكنه لا يزال قادرًا على استخدام متتبع تنزيل المتصفح للحصول على مزيد من المعلومات."
  ],

  "bn": [
    "এপ্রিল আপডেট বিজ্ঞপ্তি",
    "এপ্রিল আপডেট নতুন অনুমতি গ্রহণ করতে বলবে। আপনি যতক্ষণ না এটি গ্রহণ করবেন, ব্রাউজার এক্সটেনশনটি নিষ্ক্রিয় করে দেবে।",
    "নতুন অনুমতিগুলি কী?", 
    "প্রথম অনুমতি এক্সটেনশনকে ব্যবহারকারী এটি সক্রিয় করেছে কিনা তা সনাক্ত করতে দেয়। দ্বিতীয় অনুমতি এক্সটেনশনটিকে ছবি ডাউনলোড করার সময় পপআপ উইন্ডো ব্লক করতে দেয়।",
    "আমরা কেন এই অনুমতিগুলি অনুরোধ করছি?", 
    "এই অনুমতিগুলি ব্যবহারকারীর অভিজ্ঞতা উন্নত করে এবং কিছু বিভ্রান্তিকর আচরণ রোধ করতে সাহায্য করে।",
    "বর্তমান আচরণ:", 
    "এক্সটেনশন পুনরায় সক্রিয় করার পর এটি কাজ করে না যতক্ষণ না ব্যবহারকারী ট্যাব রিফ্রেশ করেন।",
    "ছবি ডাউনলোড করার সময়, ব্রাউজার একটি পপআপ খুলে যা ডাউনলোডের অগ্রগতি দেখায়।",
    "ভবিষ্যতের আচরণ:", 
    "এক্সটেনশন পুনরায় সক্রিয় করার পর এটি সমস্ত ট্যাবে স্বয়ংক্রিয়ভাবে কাজ করবে।",
    "এক্সটেনশনটি একটি একীভূত অগ্রগতি বার দেখাবে যাতে ব্যবহারকারী ব্যাহত না হন।"
  ],
  "bg": [
    "Известие за актуализация през април",
    "Актуализацията през април ще изисква да приемете нови разрешения. Докато не ги приемете, браузърът ще деактивира разширението.",
    "Какви са новите разрешения?", 
    "Първото ново разрешение позволява на разширението да открива кога потребителят го активира. Второто разрешение позволява на разширението да блокира изскачащия прозорец, който се появява при изтегляне на изображения.",
    "Защо искаме тези разрешения?", 
    "Тези разрешения помагат да се предотвратят объркващи ситуации при повторно активиране на разширението и подобряват потребителското изживяване, като предотвратяват показването на нежелани изскачащи прозорци.",
    "Настоящо поведение:", 
    "След повторно активиране разширението не работи, докато потребителят не опресни раздела. Освен това, работи само в обновения раздел, което налага ръчно обновяване на всички останали отворени раздели.",
    "Когато потребителят изтегля изображения, браузърът показва изскачащ прозорец с напредъка на изтеглянето.",
    "Бъдещо поведение:", 
    "След повторно активиране разширението ще работи незабавно във всички раздели без нужда от ръчно обновяване.",
    "Разширението ще показва напредъка на изтеглянето чрез вграден индикатор, без да прекъсва потребителя, като същевременно позволява използване на браузърния тракер за изтегляния за допълнителна информация."
  ],

  "ca": [
    "Notificació d'actualització d'abril",
    "L'actualització d'abril requerirà que accepteu nous permisos. Fins que els accepteu, el navegador desactivarà l'extensió.",
    "Quins són els nous permisos?", 
    "El primer permís permet a l'extensió detectar quan l'usuari l'activa. El segon permís permet bloquejar la finestra emergent que apareix en descarregar imatges.",
    "Per què sol·licitem aquests permisos?", 
    "Aquests permisos ajuden a evitar comportaments confusos quan l'usuari reactiva l'extensió i milloren l'experiència evitant finestres emergents no desitjades.",
    "Comportament actual:", 
    "Després de tornar a activar l'extensió, aquesta no funciona fins que l'usuari refresca la pestanya. A més, només funciona a la pestanya actualitzada, requerint una actualització manual de totes les altres pestanyes obertes.",
    "Quan l'usuari descarrega imatges, el navegador bloqueja la interfície amb una finestra emergent que mostra el progrés de la descàrrega.",
    "Comportament futur:", 
    "Després de tornar a activar l'extensió, aquesta funcionarà immediatament en totes les pestanyes sense necessitat d'una actualització manual.",
    "L'extensió mostrarà el progrés de la descàrrega amb una barra de progrés integrada, sense interrompre l'usuari, però permetent veure el seguiment de descàrrega del navegador per obtenir més informació."
  ],

  "zh_CN": [
    "四月更新通知",
    "四月更新需要您接受新的权限。在您接受之前，浏览器将禁用该扩展程序。",
    "新的权限是什么？", 
    "第一个新权限允许扩展程序检测用户何时启用它。第二个权限允许扩展程序阻止在下载图片时弹出的窗口。",
    "为什么请求这些权限？", 
    "这些权限有助于防止用户重新启用扩展程序时出现的混乱行为，并通过防止浏览器显示不必要的弹窗来提升用户体验。",
    "当前行为：", 
    "重新启用扩展程序后，它不会立即生效，用户必须刷新标签页。此外，扩展程序仅适用于已刷新的标签页，需要用户手动刷新所有其他打开的标签页。",
    "当用户下载图片时，浏览器会显示下载进度的弹出窗口，影响用户体验。",
    "未来行为：", 
    "重新启用扩展程序后，它将在所有标签页立即生效，无需手动刷新。",
    "扩展程序将通过集成进度条显示下载进度，不会打扰用户，同时用户仍然可以使用浏览器的下载管理器获取更多信息。"
  ],

  "zh_TW": [
    "四月更新通知",
    "四月更新將要求您接受新的權限。在您接受之前，瀏覽器將停用此擴充功能。",
    "新的權限是什麼？", 
    "第一個新權限允許擴充功能偵測使用者何時啟用它。第二個權限允許擴充功能阻擋下載圖片時彈出的視窗。",
    "為什麼請求這些權限？", 
    "這些權限可防止使用者重新啟用擴充功能時可能出現的混亂情況，並透過阻止瀏覽器顯示不必要的彈出視窗來提升使用者體驗。",
    "目前的行為：", 
    "重新啟用擴充功能後，它不會立即生效，使用者必須重新整理標籤頁。此外，擴充功能僅適用於已重新整理的標籤頁，使用者需要手動重新整理所有其他開啟的標籤頁。",
    "當使用者下載圖片時，瀏覽器會顯示下載進度的彈出視窗，影響使用者體驗。",
    "未來的行為：", 
    "重新啟用擴充功能後，它將立即在所有標籤頁生效，無需手動重新整理。",
    "擴充功能將透過內建進度條顯示下載進度，不會打擾使用者，同時仍可使用瀏覽器的下載管理器獲取更多資訊。"
  ],
  "hr": [
    "Obavijest o ažuriranju za travanj",
    "Ažuriranje u travnju zahtijevat će prihvaćanje novih dopuštenja. Dok ih ne prihvatite, preglednik će onemogućiti proširenje.",
    "Koja su nova dopuštenja?", 
    "Prvo novo dopuštenje omogućuje proširenju da otkrije kada ga korisnik omogući. Drugo dopuštenje omogućuje blokiranje skočnog prozora koji se pojavljuje prilikom preuzimanja slika.",
    "Zašto tražimo ova dopuštenja?", 
    "Ova dopuštenja pomažu u sprječavanju zbunjujućeg ponašanja kada korisnik ponovno omogući proširenje i poboljšavaju korisničko iskustvo sprječavanjem neželjenih skočnih prozora.",
    "Trenutno ponašanje:", 
    "Nakon ponovnog omogućavanja proširenje ne radi dok korisnik ne osvježi karticu.",
    "Prilikom preuzimanja slika, preglednik prikazuje skočni prozor s napretkom preuzimanja.",
    "Buduće ponašanje:", 
    "Nakon ponovnog omogućavanja, proširenje će odmah raditi na svim karticama bez ručnog osvježavanja.",
    "Proširenje će prikazivati napredak preuzimanja integriranom trakom, ne ometajući korisnika."
  ],
  "cs": [
    "Upozornění na dubnovou aktualizaci",
    "Dubnová aktualizace bude vyžadovat, abyste přijali nová oprávnění. Dokud je nepřijmete, prohlížeč rozšíření zakáže.",
    "Jaká jsou nová oprávnění?", 
    "První oprávnění umožňuje rozšíření zjistit, kdy jej uživatel povolí. Druhé oprávnění umožňuje rozšíření blokovat vyskakovací okno při stahování obrázků.",
    "Proč tato oprávnění požadujeme?", 
    "Tato oprávnění pomáhají zabránit nejasnému chování při znovuaktivaci rozšíření a zlepšují uživatelský zážitek tím, že zabraňují zobrazování nechtěných vyskakovacích oken.",
    "Současné chování:", 
    "Po znovuaktivaci rozšíření nefunguje, dokud uživatel neobnoví kartu. I poté funguje pouze na obnovené kartě, což vyžaduje ruční obnovení všech ostatních otevřených karet.",
    "Při stahování obrázků prohlížeč zobrazí vyskakovací okno s průběhem stahování.",
    "Budoucí chování:", 
    "Po znovuaktivaci rozšíření bude okamžitě fungovat na všech kartách bez nutnosti ručního obnovení.",
    "Rozšíření zobrazí průběh stahování pomocí integrovaného ukazatele průběhu, aniž by přerušovalo uživatele, a přesto umožní sledování stahování v prohlížeči."
  ],

  "da": [
    "April-opdateringsmeddelelse",
    "April-opdateringen kræver, at du accepterer nye tilladelser. Indtil du accepterer dem, vil browseren deaktivere udvidelsen.",
    "Hvad er de nye tilladelser?", 
    "Den første nye tilladelse giver udvidelsen mulighed for at registrere, når brugeren aktiverer den. Den anden tilladelse tillader udvidelsen at blokere det pop op-vindue, der vises, når brugeren downloader billeder.",
    "Hvorfor anmoder vi om disse tilladelser?", 
    "Disse tilladelser hjælper med at forhindre forvirrende adfærd, når brugeren genaktiverer udvidelsen, og forbedrer brugeroplevelsen ved at forhindre uønskede pop op-vinduer.",
    "Nuværende adfærd:", 
    "Efter genaktivering af udvidelsen fungerer den ikke, før brugeren opdaterer fanen. Desuden fungerer udvidelsen kun på den opdaterede fane, hvilket kræver manuel opdatering af alle andre åbne faner.",
    "Når brugeren downloader billeder, blokerer browseren brugergrænsefladen med et pop op-vindue, der viser downloadforløbet.",
    "Fremtidig adfærd:", 
    "Efter genaktivering vil udvidelsen straks fungere på alle faner uden behov for manuel opdatering.",
    "Udvidelsen vil vise downloadforløbet med en integreret statuslinje uden at afbryde brugeren, men stadig give adgang til browserens downloadtracker for yderligere information."
  ],

  "nl": [
    "April-update melding",
    "De april-update vereist dat je nieuwe machtigingen accepteert. Totdat je dit doet, wordt de extensie door de browser uitgeschakeld.",
    "Wat zijn de nieuwe machtigingen?", 
    "De eerste nieuwe machtiging stelt de extensie in staat om te detecteren wanneer de gebruiker deze inschakelt. De tweede machtiging laat de extensie het pop-upvenster blokkeren dat verschijnt bij het downloaden van afbeeldingen.",
    "Waarom vragen we om deze machtigingen?", 
    "Deze machtigingen helpen verwarrend gedrag te voorkomen wanneer de gebruiker de extensie opnieuw inschakelt en verbeteren de gebruikerservaring door ongewenste pop-ups te voorkomen.",
    "Huidig gedrag:", 
    "Na het opnieuw inschakelen werkt de extensie niet totdat de gebruiker het tabblad vernieuwt. Bovendien werkt de extensie alleen op het vernieuwde tabblad, waardoor de gebruiker alle andere geopende tabbladen handmatig moet vernieuwen.",
    "Wanneer de gebruiker afbeeldingen downloadt, blokkeert de browser de interface met een pop-up die de voortgang toont.",
    "Toekomstig gedrag:", 
    "Na het opnieuw inschakelen werkt de extensie direct op alle tabbladen zonder handmatige verversing.",
    "De extensie zal de voortgang van de download weergeven met een geïntegreerde voortgangsbalk, zonder de gebruiker te onderbreken, maar biedt nog steeds toegang tot de downloadtracker van de browser."
  ],

  "et": [
    "Aprilli värskendusteatis",
    "Aprilli värskendus nõuab uute lubade aktsepteerimist. Kuni te seda ei tee, keelab brauser laienduse.",
    "Mis on uued load?", 
    "Esimene luba võimaldab laiendusel tuvastada, millal kasutaja selle lubab. Teine luba võimaldab blokeerida hüpikakna, mis ilmub piltide allalaadimisel.",
    "Miks me neid lube küsime?", 
    "Need load aitavad vältida segadust tekitavat käitumist, kui kasutaja lubab laienduse uuesti, ning parandavad kasutajakogemust, takistades soovimatuid hüpikaknaid.",
    "Praegune käitumine:", 
    "Pärast uuesti lubamist ei tööta laiendus enne, kui kasutaja vahelehte värskendab. Pealegi töötab see ainult värskendatud vahekaardil, mistõttu tuleb kõik muud avatud vahelehed käsitsi värskendada.",
    "Kui kasutaja alla laadib pilte, kuvab brauser allalaadimise edenemise hüpikaknas.",
    "Tulevane käitumine:", 
    "Pärast uuesti lubamist töötab laiendus kohe kõigil vahekaartidel ilma käsitsi värskendamiseta.",
    "Laiendus kuvab allalaadimise edenemist integreeritud edenemisribaga, ilma et kasutajat segaks, kuid võimaldab siiski kasutada brauseri allalaadimise jälgijat täiendava teabe saamiseks."
  ],

  "fr": [
    "Notification de mise à jour d'avril",
    "La mise à jour d'avril nécessitera d'accepter de nouvelles autorisations. Jusqu'à ce que vous les acceptiez, le navigateur désactivera l'extension.",
    "Quelles sont les nouvelles autorisations ?", 
    "La première nouvelle autorisation permet à l'extension de détecter quand l'utilisateur l'active. La deuxième autorisation permet de bloquer la fenêtre contextuelle qui s'affiche lors du téléchargement d'images.",
    "Pourquoi demandons-nous ces autorisations ?", 
    "Ces autorisations permettent d'éviter un comportement confus lorsque l'utilisateur réactive l'extension et améliorent l'expérience utilisateur en empêchant l'affichage de fenêtres contextuelles indésirables."
  ],
  "de": [
    "Benachrichtigung über das April-Update",
    "Das April-Update erfordert, dass Sie neue Berechtigungen akzeptieren. Bis Sie dies tun, wird die Erweiterung vom Browser deaktiviert.",
    "Was sind die neuen Berechtigungen?", 
    "Die erste neue Berechtigung ermöglicht der Erweiterung zu erkennen, wann der Benutzer sie aktiviert. Die zweite Berechtigung erlaubt der Erweiterung, das Popup-Fenster zu blockieren, das beim Herunterladen von Bildern erscheint.",
    "Warum fordern wir diese Berechtigungen an?", 
    "Diese Berechtigungen helfen, verwirrendes Verhalten zu vermeiden, wenn der Benutzer die Erweiterung erneut aktiviert, und verbessern die Benutzererfahrung, indem sie unerwünschte Popups verhindern.",
    "Aktuelles Verhalten:", 
    "Nach der erneuten Aktivierung funktioniert die Erweiterung nicht, bis der Benutzer die Registerkarte aktualisiert. Außerdem funktioniert die Erweiterung nur auf der aktualisierten Registerkarte, sodass alle anderen geöffneten Registerkarten manuell aktualisiert werden müssen.",
    "Wenn der Benutzer Bilder herunterlädt, blockiert der Browser die Benutzeroberfläche mit einem Popup, das den Fortschritt anzeigt.",
    "Zukünftiges Verhalten:", 
    "Nach der erneuten Aktivierung funktioniert die Erweiterung sofort in allen Registerkarten, ohne dass eine manuelle Aktualisierung erforderlich ist.",
    "Die Erweiterung zeigt den Fortschritt des Downloads mit einer integrierten Fortschrittsleiste an, ohne den Benutzer zu unterbrechen, aber weiterhin Zugriff auf den Download-Tracker des Browsers zu ermöglichen."
  ],

  "hu": [
    "Áprilisi frissítési értesítés",
    "Az áprilisi frissítés új engedélyek elfogadását igényli. Amíg nem fogadja el ezeket, a böngésző letiltja a bővítményt.",
    "Mik az új engedélyek?", 
    "Az első új engedély lehetővé teszi a bővítmény számára, hogy észlelje, amikor a felhasználó engedélyezi azt. A második engedély lehetővé teszi a bővítmény számára, hogy blokkolja azt a felugró ablakot, amely a képek letöltésekor jelenik meg.",
    "Miért kérjük ezeket az engedélyeket?", 
    "Ezek az engedélyek segítenek elkerülni a félrevezető viselkedést, amikor a felhasználó újra engedélyezi a bővítményt, és javítják a felhasználói élményt azáltal, hogy megakadályozzák a nem kívánt felugró ablakok megjelenését.",
    "Jelenlegi működés:", 
    "A bővítmény újraengedélyezése után nem működik, amíg a felhasználó nem frissíti a lapot. Ezenkívül a bővítmény csak a frissített lapon működik, így a többi nyitott lapot manuálisan kell frissíteni.",
    "Amikor a felhasználó képeket tölt le, a böngésző egy felugró ablakot jelenít meg, amely megakadályozza az interfész használatát és megmutatja a letöltési folyamatot.",
    "Jövőbeni működés:", 
    "A bővítmény újraengedélyezése után azonnal működni fog minden lapon anélkül, hogy manuális frissítésre lenne szükség.",
    "A bővítmény a letöltési folyamatot egy beépített előrehaladási sávval jeleníti meg, anélkül, hogy megszakítaná a felhasználót, de továbbra is lehetővé téve a letöltési állapot nyomon követését a böngésző letöltéskezelőjében."
  ],
"tr": [
    "Nisan Güncelleme Bildirimi",
    "Nisan güncellemesi, yeni izinleri kabul etmenizi gerektirecektir. Kabul edene kadar, tarayıcı uzantıyı devre dışı bırakacaktır.",
    "Yeni izinler nelerdir?", 
    "İlk yeni izin, uzantının ne zaman etkinleştirildiğini algılamasına olanak tanır. İkinci izin, kullanıcının resim indirdiğinde açılan pencereyi engellemesine izin verir.",
    "Bu izinleri neden istiyoruz?", 
    "Bu izinler, kullanıcı uzantıyı yeniden etkinleştirdiğinde oluşabilecek kafa karıştırıcı davranışları önlemeye yardımcı olur ve tarayıcının istenmeyen açılır pencereler göstermesini engelleyerek kullanıcı deneyimini geliştirir.",
    "Mevcut Davranış:", 
    "Uzantıyı yeniden etkinleştirdikten sonra, kullanıcı sekmeyi yenileyene kadar çalışmaz. Ayrıca, yalnızca yenilenen sekmede çalışır ve tüm açık sekmelerin manuel olarak yenilenmesi gerekir.",
    "Kullanıcı resimleri indirdiğinde, tarayıcı bir açılır pencere göstererek indirme ilerlemesini görüntüler ve kullanıcı arayüzünü engeller.",
    "Gelecekteki Davranış:", 
    "Uzantıyı yeniden etkinleştirdikten sonra, manuel yenilemeye gerek kalmadan tüm sekmelerde hemen çalışacaktır.",
    "Uzantı, entegre bir ilerleme çubuğuyla indirme sürecini gösterecek, kullanıcıyı bölmeyecek ancak yine de tarayıcının indirme izleyicisini kullanmasına olanak tanıyacaktır."
  ],

  "ru": [
    "Уведомление об обновлении в апреле",
    "Апрельское обновление потребует от вас принять новые разрешения. Пока вы их не примете, браузер отключит расширение.",
    "Какие новые разрешения?", 
    "Первое новое разрешение позволяет расширению определять, когда пользователь его включает. Второе разрешение позволяет расширению блокировать всплывающее окно, которое появляется при загрузке изображений.",
    "Почему мы запрашиваем эти разрешения?", 
    "Эти разрешения помогают предотвратить сбивающее с толку поведение при повторном включении расширения и улучшают пользовательский опыт, предотвращая появление нежелательных всплывающих окон в браузере.",
    "Текущее поведение:", 
    "После повторного включения расширение не работает, пока пользователь не обновит вкладку. Более того, оно работает только в обновленной вкладке, и все остальные открытые вкладки нужно обновлять вручную.",
    "Когда пользователь загружает изображения, браузер показывает всплывающее окно, блокирующее интерфейс и отображающее процесс загрузки.",
    "Будущее поведение:", 
    "После повторного включения расширение сразу заработает во всех вкладках без необходимости ручного обновления.",
    "Расширение будет отображать процесс загрузки с помощью встроенной полосы прогресса, не прерывая пользователя, но при этом позволяя использовать стандартный загрузчик браузера для дополнительной информации."
  ],

  "he": [
    "הודעת עדכון אפריל",
    "עדכון אפריל ידרוש ממך לקבל הרשאות חדשות. עד שתקבל אותן, הדפדפן ישבית את התוסף.",
    "מהן ההרשאות החדשות?", 
    "ההרשאה הראשונה החדשה מאפשרת לתוסף לזהות מתי המשתמש מפעיל אותו. ההרשאה השנייה מאפשרת לתוסף לחסום את החלון הקופץ שמופיע כאשר המשתמש מוריד תמונות.",
    "מדוע אנו מבקשים הרשאות אלו?", 
    "הרשאות אלו עוזרות למנוע התנהגות מבלבלת במקרים מסוימים כאשר המשתמש מפעיל מחדש את התוסף, ומשפרות את חוויית המשתמש על ידי מניעת חלונות קופצים לא רצויים.",
    "התנהגות נוכחית:", 
    "לאחר הפעלה מחדש, התוסף אינו עובד עד שהמשתמש מרענן את הכרטיסייה. בנוסף, הוא פועל רק בכרטיסייה המרועננת, ולכן יש לרענן את כל הכרטיסיות הפתוחות באופן ידני.",
    "כאשר המשתמש מוריד תמונות, הדפדפן חוסם את הממשק עם חלון קופץ שמציג את התקדמות ההורדה.",
    "התנהגות עתידית:", 
    "לאחר הפעלה מחדש, התוסף יפעל באופן מיידי בכל הכרטיסיות ללא צורך ברענון ידני.",
    "התוסף יציג את התקדמות ההורדה באמצעות סרגל התקדמות משולב, המשתמש לא יופרע אך עדיין יוכל להשתמש במעקב ההורדות של הדפדפן לקבלת מידע נוסף."
  ],
  "hi": [
    "अप्रैल अपडेट सूचना",
    "अप्रैल अपडेट के लिए आपको नई अनुमतियों को स्वीकार करना होगा। जब तक आप इसे स्वीकार नहीं करते, ब्राउज़र एक्सटेंशन को अक्षम कर देगा।",
    "नई अनुमतियाँ क्या हैं?", 
    "पहली नई अनुमति एक्सटेंशन को यह पहचानने की अनुमति देती है कि उपयोगकर्ता इसे कब सक्षम करता है। दूसरी अनुमति एक्सटेंशन को उस विंडो को ब्लॉक करने की अनुमति देती है जो तब पॉप अप होती है जब उपयोगकर्ता चित्र डाउनलोड करता है।",
    "हम इन अनुमतियों का अनुरोध क्यों कर रहे हैं?", 
    "ये अनुमतियाँ कुछ मामलों में भ्रमित करने वाले व्यवहार को रोकने में मदद करती हैं जब उपयोगकर्ता एक्सटेंशन को पुनः सक्षम करता है, और अवांछित पॉपअप को रोककर उपयोगकर्ता अनुभव को बेहतर बनाती हैं।",
    "वर्तमान व्यवहार:", 
    "एक्सटेंशन को पुनः सक्षम करने के बाद, यह तब तक काम नहीं करता जब तक उपयोगकर्ता टैब को रिफ्रेश नहीं करता। यहां तक कि तब भी, यह केवल उसी टैब में काम करता है जिसे ताज़ा किया गया है, जिससे उपयोगकर्ता को सभी खुले टैब को मैन्युअली ताज़ा करना पड़ता है।",
    "जब उपयोगकर्ता चित्र डाउनलोड करता है, तो ब्राउज़र पॉपअप विंडो दिखाकर UI को ब्लॉक कर देता है।",
    "भविष्य का व्यवहार:", 
    "एक्सटेंशन को पुनः सक्षम करने के बाद, यह तुरंत सभी टैब में बिना मैन्युअल रिफ्रेश की आवश्यकता के काम करेगा।",
    "एक्सटेंशन एकीकृत प्रगति पट्टी के साथ डाउनलोड प्रगति दिखाएगा, उपयोगकर्ता बाधित नहीं होगा, लेकिन फिर भी ब्राउज़र डाउनलोड ट्रैकर का उपयोग कर सकेगा।"
  ],

  "hu": [
    "Áprilisi frissítési értesítés",
    "Az áprilisi frissítés új engedélyek elfogadását igényli. Amíg ezt nem fogadja el, a böngésző letiltja a bővítményt.",
    "Mik az új engedélyek?", 
    "Az első új engedély lehetővé teszi a bővítmény számára, hogy felismerje, amikor a felhasználó bekapcsolja. A második engedély lehetővé teszi a bővítmény számára, hogy blokkolja az ablakot, amely akkor jelenik meg, amikor a felhasználó képeket tölt le.",
    "Miért kérjük ezeket az engedélyeket?", 
    "Ez az engedély segít elkerülni a zavaró viselkedést bizonyos esetekben, amikor a felhasználó újra engedélyezi a bővítményt, és javítja a felhasználói élményt azáltal, hogy megakadályozza a nem kívánt felugró ablakokat.",
    "Jelenlegi viselkedés:", 
    "A bővítmény újraengedélyezése után nem működik, amíg a felhasználó nem frissíti a lapot. Ezenkívül csak a frissített lapon működik, így az összes nyitott lapot manuálisan kell frissíteni.",
    "Amikor a felhasználó képeket tölt le, a böngésző egy felugró ablakot jelenít meg, amely blokkolja a felületet és mutatja a letöltés állapotát.",
    "Jövőbeli viselkedés:", 
    "A bővítmény újraengedélyezése után azonnal működni fog az összes lapon anélkül, hogy manuális frissítésre lenne szükség.",
    "A bővítmény beépített előrehaladási sávval fogja megjeleníteni a letöltési folyamatot, nem fogja megszakítani a felhasználót, de továbbra is lehetőséget biztosít a böngésző letöltési nyomkövetőjének használatára."
  ],

  "id": [
    "Pemberitahuan Pembaruan April",
    "Pembaruan April akan meminta Anda untuk menerima izin baru. Hingga Anda menerimanya, browser akan menonaktifkan ekstensi.",
    "Apa izin baru itu?", 
    "Izin pertama memungkinkan ekstensi mendeteksi kapan pengguna mengaktifkannya. Izin kedua memungkinkan ekstensi memblokir jendela pop-up yang muncul saat pengguna mengunduh gambar.",
    "Mengapa kami meminta izin ini?", 
    "Izin ini membantu mencegah perilaku membingungkan dalam beberapa kasus ketika pengguna mengaktifkan ulang ekstensi dan meningkatkan pengalaman pengguna dengan mencegah pop-up yang tidak diinginkan.",
    "Perilaku Saat Ini:", 
    "Setelah mengaktifkan ulang ekstensi, ekstensi tidak berfungsi hingga pengguna menyegarkan tab. Bahkan setelah itu, ekstensi hanya berfungsi pada tab yang telah diperbarui, sehingga pengguna harus memperbarui semua tab yang terbuka secara manual.",
    "Saat pengguna mengunduh gambar, browser menampilkan jendela pop-up yang menghalangi UI untuk menunjukkan progres unduhan.",
    "Perilaku Masa Depan:", 
    "Setelah mengaktifkan ulang ekstensi, ekstensi akan segera berfungsi di semua tab tanpa perlu penyegaran manual.",
    "Ekstensi akan menampilkan progres unduhan dengan bilah kemajuan terintegrasi, pengguna tidak akan terganggu, tetapi tetap dapat menggunakan pelacak unduhan browser untuk informasi lebih lanjut."
  ],

  "it": [
    "Notifica di aggiornamento di aprile",
    "L'aggiornamento di aprile richiederà di accettare nuove autorizzazioni. Fino a quando non le accetterai, il browser disattiverà l'estensione.",
    "Quali sono le nuove autorizzazioni?", 
    "La prima nuova autorizzazione consente all'estensione di rilevare quando l'utente la attiva. La seconda autorizzazione consente all'estensione di bloccare la finestra popup che appare quando l'utente scarica immagini.",
    "Perché stiamo richiedendo queste autorizzazioni?", 
    "Queste autorizzazioni aiutano a prevenire comportamenti confusi in alcuni casi quando l'utente riattiva l'estensione e migliorano l'esperienza dell'utente impedendo la visualizzazione di popup indesiderati.",
    "Comportamento Attuale:", 
    "Dopo aver riattivato l'estensione, non funziona fino a quando l'utente non aggiorna la scheda. Inoltre, funziona solo nella scheda aggiornata, quindi tutte le altre schede aperte devono essere aggiornate manualmente.",
    "Quando l'utente scarica immagini, il browser blocca l'interfaccia utente con un popup che mostra l'avanzamento del download.",
    "Comportamento Futuro:", 
    "Dopo aver riattivato l'estensione, funzionerà immediatamente su tutte le schede senza bisogno di un aggiornamento manuale.",
    "L'estensione mostrerà l'avanzamento del download con una barra di progresso integrata, senza interrompere l'utente, che potrà comunque utilizzare il monitoraggio dei download del browser per maggiori informazioni."
  ],

  "ja": [
    "4月のアップデート通知",
    "4月のアップデートでは、新しい権限の承認が必要になります。承認するまで、ブラウザは拡張機能を無効にします。",
    "新しい権限とは？", 
    "最初の新しい権限は、ユーザーが拡張機能を有効にしたことを検出できるようにするものです。2つ目の権限は、ユーザーが画像をダウンロードするときに表示されるポップアップウィンドウをブロックするものです。",
    "なぜこの権限が必要なのですか？", 
    "これらの権限は、拡張機能を再有効化した際の混乱を防ぎ、不要なポップアップの表示を抑えることでユーザー体験を向上させます。",
    "現在の動作:", 
    "拡張機能を再有効化すると、タブを更新するまで動作しません。また、更新されたタブでのみ動作し、他のタブは手動で更新する必要があります。",
    "ユーザーが画像をダウンロードすると、ブラウザがポップアップを表示してUIをブロックします。",
    "将来の動作:", 
    "拡張機能を再有効化すると、すべてのタブで即座に動作します。",
    "拡張機能は統合された進行状況バーでダウンロードを表示し、ユーザーを邪魔しませんが、ブラウザのダウンロードトラッカーも利用できます。"
  ],
"kn": [
    "ಏಪ್ರಿಲ್ ನವೀಕರಣ ಅಧಿಸೂಚನೆ",
    "ಏಪ್ರಿಲ್ ನವೀಕರಣಕ್ಕಾಗಿ ನೀವು ಹೊಸ ಅನುಮತಿಗಳನ್ನು ಸ್ವೀಕರಿಸಬೇಕು. ನೀವು ಇದನ್ನು ಸ್ವೀಕರಿಸುವವರೆಗೆ, ಬ್ರೌಸರ್ ವಿಸ್ತರಣೆಯನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸುತ್ತದೆ.",
    "ಹೊಸ ಅನುಮತಿಗಳು ಏನಿವೆ?", 
    "ಮೊದಲ ಅನುಮತಿ ವಿಸ್ತರಣೆಯನ್ನು ಬಳಕೆದಾರರು ಇದನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಿದಾಗ ಪತ್ತೆಹಚ್ಚಲು ಅನುಮತಿಸುತ್ತದೆ. ಎರಡನೇ ಅನುಮತಿ ಬಳಕೆದಾರರು ಚಿತ್ರಗಳನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿದಾಗ ತೆರೆದುಕೊಳ್ಳುವ ವಿಂಡೋವನ್ನು ತಡೆಯಲು ವಿಸ್ತರಣೆಗೆ ಅವಕಾಶ ನೀಡುತ್ತದೆ.",
    "ನಾವು ಈ ಅನುಮತಿಗಳನ್ನು ಏಕೆ ಕೇಳುತ್ತಿದ್ದೇವೆ?", 
    "ಈ ಅನುಮತಿಗಳು ಕೆಲವೊಮ್ಮೆ ಬಳಕೆದಾರರು ವಿಸ್ತರಣೆಯನ್ನು ಪುನಃ ಸಕ್ರಿಯಗೊಳಿಸಿದಾಗ ಗೊಂದಲ ಸೃಷ್ಟಿಸುವ ವರ್ತನೆಯನ್ನು ತಡೆಯಲು ಸಹಾಯ ಮಾಡುತ್ತವೆ ಮತ್ತು ಅನಗತ್ಯ ಪೋಪ್ಅಪ್‌ಗಳನ್ನು ತಡೆಯುವ ಮೂಲಕ ಬಳಕೆದಾರ ಅನುಭವವನ್ನು ಸುಧಾರಿಸುತ್ತವೆ.",
    "ಪ್ರಸ್ತುತ ವರ್ತನೆ:", 
    "ವಿಸ್ತರಣೆಯನ್ನು ಪುನಃ ಸಕ್ರಿಯಗೊಳಿಸಿದ ನಂತರ, ಬಳಕೆದಾರರು ಟ್ಯಾಬ್ ಅನ್ನು ರಿಫ್ರೆಶ್ ಮಾಡುವವರೆಗೆ ಇದು ಕಾರ್ಯನಿರ್ವಹಿಸುವುದಿಲ್ಲ. ಅಷ್ಟೇ ಅಲ್ಲ, ಇದು ಕೇವಲ ನವೀಕರಿಸಿದ ಟ್ಯಾಬ್‌ನಲ್ಲಿ ಮಾತ್ರ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತದೆ, ಎಲ್ಲಾ ತೆರೆಯಲಾದ ಟ್ಯಾಬ್‌ಗಳನ್ನು ಹಸ್ತಚಾಲಿತವಾಗಿ ರಿಫ್ರೆಶ್ ಮಾಡಬೇಕಾಗುತ್ತದೆ.",
    "ಬಳಕೆದಾರರು ಚಿತ್ರಗಳನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿದಾಗ, ಬ್ರೌಸರ್ ಡೌನ್‌ಲೋಡ್ ಪ್ರೋಗ್ರೆಶನ್ ಪೋಪ್ಅಪ್ ತೋರಿಸಿ UI ಅನ್ನು ಬ್ಲಾಕ್ ಮಾಡುತ್ತದೆ.",
    "ಭವಿಷ್ಯದ ವರ್ತನೆ:", 
    "ವಿಸ್ತರಣೆಯನ್ನು ಪುನಃ ಸಕ್ರಿಯಗೊಳಿಸಿದ ನಂತರ, ಇದು ಎಲ್ಲಾ ಟ್ಯಾಬ್‌ಗಳಲ್ಲಿ ತಕ್ಷಣ ಕೆಲಸ ಮಾಡುತ್ತದೆ, ಕೈಯಾರೆ ರಿಫ್ರೆಶ್ ಮಾಡುವ ಅಗತ್ಯವಿಲ್ಲ.",
    "ವಿಸ್ತರಣೆ ಸಮಗ್ರ ಪ್ರಗತಿ ಪಟ್ಟಿಯೊಂದಿಗೆ ಡೌನ್‌ಲೋಡ್ ಪ್ರಗತಿಯನ್ನು ತೋರಿಸುತ್ತದೆ, ಬಳಕೆದಾರರಿಗೆ ಅಡ್ಡಿಯಾಗದೆ, ಆದರೆ ಅವರು ಇನ್ನೂ ಬ್ರೌಸರ್ ಡೌನ್‌ಲೋಡ್ ಟ್ರ್ಯಾಕರ್ ಅನ್ನು ಬಳಸಬಹುದು."
  ],

  "ko": [
    "4월 업데이트 알림",
    "4월 업데이트에서는 새로운 권한을 승인해야 합니다. 승인하기 전까지 브라우저가 확장 프로그램을 비활성화합니다.",
    "새로운 권한은 무엇인가요?", 
    "첫 번째 권한은 사용자가 확장 프로그램을 활성화할 때 이를 감지할 수 있도록 합니다. 두 번째 권한은 사용자가 이미지를 다운로드할 때 나타나는 팝업 창을 차단할 수 있도록 합니다.",
    "이 권한을 요청하는 이유는 무엇인가요?", 
    "이 권한은 확장 프로그램을 다시 활성화할 때 혼란스러운 동작을 방지하고, 불필요한 팝업을 차단하여 사용자 경험을 향상하는 데 도움을 줍니다.",
    "현재 동작:", 
    "확장 프로그램을 다시 활성화한 후, 사용자가 탭을 새로 고칠 때까지 작동하지 않습니다. 또한, 새로 고친 탭에서만 작동하며, 사용자가 모든 열린 탭을 수동으로 새로 고쳐야 합니다.",
    "사용자가 이미지를 다운로드할 때, 브라우저가 다운로드 진행 팝업을 표시하여 UI를 차단합니다.",
    "향후 동작:", 
    "확장 프로그램을 다시 활성화하면, 모든 탭에서 즉시 작동하며 수동 새로 고침이 필요하지 않습니다.",
    "확장 프로그램은 통합된 진행률 표시줄을 사용하여 다운로드 진행 상황을 표시하며, 사용자는 방해받지 않으면서도 브라우저의 다운로드 트래커를 계속 사용할 수 있습니다."
  ],

  "lv": [
    "Aprīļa atjauninājuma paziņojums",
    "Aprīļa atjauninājums prasīs jums pieņemt jaunas atļaujas. Kamēr jūs tās nepieņemsiet, pārlūkprogramma atspējos paplašinājumu.",
    "Kādas ir jaunās atļaujas?", 
    "Pirmā jaunā atļauja ļauj paplašinājumam noteikt, kad lietotājs to aktivizē. Otrā atļauja ļauj paplašinājumam bloķēt uznirstošo logu, kas parādās, kad lietotājs lejupielādē attēlus.",
    "Kāpēc mēs pieprasām šīs atļaujas?", 
    "Šīs atļaujas palīdz novērst neskaidru uzvedību dažos gadījumos, kad lietotājs atkārtoti aktivizē paplašinājumu, un uzlabo lietotāja pieredzi, novēršot nevēlamus uznirstošos logus.",
    "Pašreizējā darbība:", 
    "Pēc paplašinājuma atkārtotas aktivizēšanas tas nedarbojas, līdz lietotājs atsvaidzina cilni. Pat tad tas darbojas tikai atsvaidzinātajā cilnē, tāpēc lietotājam manuāli jāatsvaidzina visas atvērtās cilnes.",
    "Kad lietotājs lejupielādē attēlus, pārlūkprogramma parāda uznirstošo logu, kas bloķē lietotāja interfeisu, lai parādītu lejupielādes progresu.",
    "Nākotnes darbība:", 
    "Pēc paplašinājuma atkārtotas aktivizēšanas tas nekavējoties darbosies visās cilnēs bez manuālas atsvaidzināšanas.",
    "Paplašinājums parādīs lejupielādes progresu ar integrētu progresa joslu, netraucējot lietotājam, bet viņš joprojām varēs izmantot pārlūkprogrammas lejupielādes izsekotāju papildu informācijai."
  ],

  "lt": [
    "Balandžio atnaujinimo pranešimas",
    "Balandžio atnaujinimas reikalaus jūsų sutikimo su naujais leidimais. Kol jų nepriimsite, naršyklė išjungs plėtinį.",
    "Kokie yra nauji leidimai?", 
    "Pirmasis leidimas leidžia plėtiniui aptikti, kada vartotojas jį įjungia. Antrasis leidimas leidžia plėtiniui blokuoti iššokantį langą, kuris atsiranda, kai vartotojas atsisiunčia vaizdus.",
    "Kodėl prašome šių leidimų?", 
    "Šie leidimai padeda išvengti painaus elgesio tam tikrais atvejais, kai vartotojas iš naujo įjungia plėtinį, ir pagerina vartotojo patirtį, neleisdami atsirasti nepageidaujamiems iššokantiems langams.",
    "Dabartinis elgesys:", 
    "Po plėtinio iš naujo įjungimo jis neveikia, kol vartotojas neatnaujina skirtuko. Net tada jis veikia tik atnaujintame skirtuke, todėl vartotojas turi rankiniu būdu atnaujinti visus atidarytus skirtukus.",
    "Kai vartotojas atsisiunčia vaizdus, naršyklė rodo iššokantį langą, kuris užblokuoja naudotojo sąsają ir rodo atsisiuntimo eigą.",
    "Būsimasis elgesys:", 
    "Po plėtinio iš naujo įjungimo jis veiks iš karto visuose skirtukuose be rankinio atnaujinimo.",
    "Plėtinys rodys atsisiuntimo eigą su integruota progreso juosta, netrukdydamas vartotojui, tačiau jis vis tiek galės naudoti naršyklės atsisiuntimų sekimo įrankį papildomai informacijai."
  ],
  "ms": [
    "Pemberitahuan Kemas Kini April",
    "Kemas kini April memerlukan anda menerima kebenaran baharu. Sehingga anda menerimanya, pelayar akan menyahaktifkan sambungan.",
    "Apakah kebenaran baharu ini?", 
    "Kebenaran pertama membolehkan sambungan mengesan apabila pengguna mengaktifkannya. Kebenaran kedua membolehkan sambungan menyekat tetingkap timbul yang muncul apabila pengguna memuat turun imej.",
    "Mengapa kami meminta kebenaran ini?", 
    "Kebenaran ini membantu mengelakkan kelakuan yang mengelirukan dalam beberapa kes apabila pengguna mengaktifkan semula sambungan dan meningkatkan pengalaman pengguna dengan menghalang tetingkap timbul yang tidak diingini.",
    "Tingkah Laku Semasa:", 
    "Selepas mengaktifkan semula sambungan, ia tidak berfungsi sehingga pengguna menyegarkan tab. Walaupun begitu, sambungan hanya berfungsi pada tab yang telah disegarkan, memerlukan pengguna untuk menyegarkan semua tab lain secara manual.",
    "Apabila pengguna memuat turun imej, pelayar menyekat antara muka pengguna dengan tetingkap timbul yang menunjukkan kemajuan muat turun.",
    "Tingkah Laku Masa Depan:", 
    "Selepas mengaktifkan semula sambungan, ia akan berfungsi serta-merta di semua tab tanpa memerlukan penyegaran manual.",
    "Sambungan akan memaparkan kemajuan muat turun dengan bar kemajuan bersepadu, tanpa mengganggu pengguna, tetapi mereka masih boleh menggunakan penjejak muat turun pelayar untuk maklumat lanjut."
  ],

  "ml": [
    "ഏപ്രിൽ അപ്‌ഡേറ്റ് അറിയിപ്പ്",
    "ഏപ്രിൽ അപ്‌ഡേറ്റിന് നിങ്ങൾ പുതിയ അനുമതികൾ സ്വീകരിക്കേണ്ടതുണ്ട്. നിങ്ങൾ അത് സ്വീകരിക്കും വരെ, ബ്രൗസർ വിപുലീകരണത്തിനെ പ്രവർത്തനരഹിതമാക്കും.",
    "പുതിയ അനുമതികൾ എന്താണ്?", 
    "ആദ്യ അനുമതി ഉപയോക്താവ് വിപുലീകരണം പ്രവർത്തനക്ഷമമാക്കിയാൽ അത് കണ്ടെത്താൻ അനുവദിക്കുന്നു. രണ്ടാമത്തെ അനുമതി ഉപയോക്താവ് ചിത്രങ്ങൾ ഡൗൺലോഡ് ചെയ്യുന്ന സമയത്ത് ഉയരുന്ന പോപ്പ്-അപ്പ് ബ്ലോക്ക് ചെയ്യാൻ അനുവദിക്കുന്നു.",
    "നാം ഈ അനുമതികൾ എന്തിനാണ് അഭ്യർത്ഥിക്കുന്നത്?", 
    "ഈ അനുമതികൾ ചില സന്ദർഭങ്ങളിൽ ഉപയോക്താവ് വിപുലീകരണം വീണ്ടും പ്രവർത്തനക്ഷമമാക്കുമ്പോൾ സംഭവിക്കാവുന്ന ആശയക്കുഴപ്പം ഒഴിവാക്കാൻ സഹായിക്കും, കൂടാതെ ആവശ്യപ്പെട്ടിട്ടില്ലാത്ത പോപ്പ്-അപ്പുകൾ തടയുന്നതിനാൽ ഉപയോക്തൃ അനുഭവം മെച്ചപ്പെടുത്തുന്നു.",
    "നിലവിലുള്ള പ്രവൃത്തികൾ:", 
    "വിപുലീകരണം വീണ്ടും പ്രവർത്തനക്ഷമമാക്കിയ ശേഷം, ഉപയോക്താവ് ടാബ് പുതുക്കുംവരെ ഇത് പ്രവർത്തിക്കില്ല. അതിന് ശേഷം, ഇത് പുതുക്കിയ ടാബിൽ മാത്രം പ്രവർത്തിക്കും, എല്ലാ ടാബുകളും കയ്യാൽ പുതുക്കേണ്ടതുണ്ട്.",
    "ഉപയോക്താവ് ചിത്രങ്ങൾ ഡൗൺലോഡ് ചെയ്യുമ്പോൾ, ബ്രൗസർ ഡൗൺലോഡ് പുരോഗതി കാണിക്കുന്ന ഒരു പോപ്പ്-അപ്പ് വഴി UI തടസ്സപ്പെടുത്തുന്നു.",
    "ഭാവിയിലെ പ്രവൃത്തികൾ:", 
    "വിപുലീകരണം വീണ്ടും പ്രവർത്തനക്ഷമമാക്കിയാൽ, ഇത് എല്ലാ ടാബുകളിലും ഉടനടി പ്രവർത്തിക്കും, കൈമാറ്റം ആവശ്യമില്ല.",
    "വിപുലീകരണം ഒരു ഏകീകൃത പുരോഗതി ബാറിൽ ഡൗൺലോഡ് പുരോഗതി പ്രദർശിപ്പിക്കും, ഉപയോക്താവിന് തടസ്സമില്ലാതെ, പക്ഷേ ബ്രൗസറിന്റെ ഡൗൺലോഡ് ട്രാക്കർ ഉപയോഗിച്ച് കൂടുതൽ വിവരങ്ങൾ ലഭ്യമാക്കാം."
  ],

  "mr": [
    "एप्रिल अपडेट सूचना",
    "एप्रिल अपडेटसाठी तुम्हाला नवीन परवानग्या स्वीकाराव्या लागतील. तुम्ही त्यास मान्यता देईपर्यंत, ब्राउझर हे एक्सटेंशन अक्षम करेल.",
    "नवीन परवानग्या कोणत्या आहेत?", 
    "पहिली परवानगी एक्सटेंशनला वापरकर्ता ते सक्रिय केव्हा करतो ते ओळखण्याची परवानगी देते. दुसरी परवानगी वापरकर्ता प्रतिमा डाउनलोड करत असताना दिसणारी पॉपअप विंडो ब्लॉक करण्यास मदत करते.",
    "आम्ही ह्या परवानग्या का मागत आहोत?", 
    "ही परवानगी एक्सटेंशन पुन्हा सक्रिय केल्यावर संभाव्य गोंधळ टाळण्यास मदत करते आणि अवांछित पॉपअप टाळून वापरकर्ता अनुभव सुधारते.",
    "सध्याची कार्यपद्धती:", 
    "एक्सटेंशन पुन्हा सक्रिय केल्यानंतर, तोपर्यंत कार्यरत होत नाही जोपर्यंत वापरकर्ता टॅब रीफ्रेश करत नाही. तसेच, हा फक्त रीफ्रेश केलेल्या टॅबसाठीच कार्यरत होतो, त्यामुळे सर्व टॅब्स मॅन्युअली रीफ्रेश करावे लागतात.",
    "जेव्हा वापरकर्ता प्रतिमा डाउनलोड करतो, ब्राउझर डाउनलोड प्रगती दाखवणाऱ्या पॉपअपसह UI ब्लॉक करतो.",
    "भावी कार्यपद्धती:", 
    "एक्सटेंशन पुन्हा सक्रिय केल्यानंतर, हे सर्व टॅब्सवर त्वरित कार्यरत होईल आणि मॅन्युअल रीफ्रेशची गरज राहणार नाही.",
    "एक्सटेंशन डाउनलोड प्रगती एकत्रित प्रगती पट्टीसह दर्शवेल, ज्यामुळे वापरकर्त्याला कोणताही व्यत्यय येणार नाही, पण तरीही ब्राउझर डाउनलोड ट्रॅकर वापरता येईल."
  ],

  "no": [
    "Apriloppdateringsvarsel",
    "Apriloppdateringen krever at du godtar nye tillatelser. Inntil du godtar dem, vil nettleseren deaktivere utvidelsen.",
    "Hva er de nye tillatelsene?", 
    "Den første tillatelsen lar utvidelsen oppdage når brukeren aktiverer den. Den andre tillatelsen lar utvidelsen blokkere popup-vinduet som vises når brukeren laster ned bilder.",
    "Hvorfor ber vi om disse tillatelsene?", 
    "Disse tillatelsene hjelper med å forhindre forvirrende oppførsel når utvidelsen aktiveres på nytt, og forbedrer brukeropplevelsen ved å forhindre uønskede popup-vinduer.",
    "Nåværende oppførsel:", 
    "Etter å ha aktivert utvidelsen på nytt, fungerer den ikke før brukeren oppdaterer fanen. Selv da fungerer den bare på den oppdaterte fanen, og brukeren må manuelt oppdatere alle andre åpne faner.",
    "Når brukeren laster ned bilder, blokkerer nettleseren grensesnittet med en popup som viser nedlastingsfremdriften.",
    "Fremtidig oppførsel:", 
    "Etter at utvidelsen er aktivert på nytt, vil den fungere umiddelbart på alle faner uten behov for manuell oppdatering.",
    "Utvidelsen vil vise nedlastingsfremdrift med en integrert fremdriftslinje, uten å forstyrre brukeren, men de kan fortsatt bruke nettleserens nedlastingsovervåker for mer informasjon."
  ],
  "fa": [
    "اعلان بروزرسانی آوریل",
    "بروزرسانی آوریل نیاز به پذیرش مجوزهای جدید دارد. تا زمانی که آن را بپذیرید، مرورگر افزونه را غیرفعال می‌کند.",
    "این مجوزهای جدید چیستند؟", 
    "مجوز اول به افزونه اجازه می‌دهد تا زمانی که کاربر آن را فعال می‌کند، شناسایی کند. مجوز دوم به افزونه اجازه می‌دهد تا پنجره پاپ‌آپی که هنگام دانلود تصاویر باز می‌شود را مسدود کند.",
    "چرا این مجوزها را درخواست می‌کنیم؟", 
    "این مجوز کمک می‌کند تا از بروز رفتارهای گیج‌کننده در زمان‌های خاص که کاربر افزونه را دوباره فعال می‌کند، جلوگیری شود و تجربه کاربری را با جلوگیری از نمایش پاپ‌آپ‌های ناخواهشای مرورگر بهبود دهد.",
    "رفتار فعلی:", 
    "پس از فعال‌سازی مجدد افزونه، تا زمانی که کاربر تب را تازه نکند، کار نمی‌کند. حتی پس از آن، افزونه فقط در تب تازه‌شده کار می‌کند و کاربر باید تمام تب‌های دیگر را به صورت دستی تازه کند.",
    "هنگام دانلود تصاویر، مرورگر با یک پاپ‌آپ که پیشرفت دانلود را نمایش می‌دهد، رابط کاربری را مسدود می‌کند.",
    "رفتار آینده:", 
    "پس از فعال‌سازی مجدد افزونه، به‌طور فوری در تمام تب‌ها بدون نیاز به تازه‌سازی دستی کار خواهد کرد.",
    "افزونه پیشرفت دانلود را با یک نوار پیشرفت یکپارچه نمایش خواهد داد، بدون اینکه کاربر دچار مزاحمت شود، اما آنها همچنان می‌توانند از ردیاب دانلود مرورگر برای دریافت اطلاعات بیشتر استفاده کنند."
  ],

  "pl": [
    "Powiadomienie o aktualizacji kwietniowej",
    "Aktualizacja kwietniowa wymaga zaakceptowania nowych uprawnień. Dopóki ich nie zaakceptujesz, przeglądarka wyłączy rozszerzenie.",
    "Czym są te nowe uprawnienia?", 
    "Pierwsze uprawnienie pozwala rozszerzeniu wykrywać, kiedy użytkownik je włącza. Drugie uprawnienie pozwala rozszerzeniu zablokować okno popup, które pojawia się, gdy użytkownik pobiera obrazy.",
    "Dlaczego prosimy o te uprawnienia?", 
    "To uprawnienie pomaga uniknąć mylącego zachowania w niektórych przypadkach, gdy użytkownik ponownie włącza rozszerzenie, oraz poprawia doświadczenia użytkownika, zapobiegając wyświetlaniu niechcianych okienek popup przez przeglądarkę.",
    "Obecne zachowanie:", 
    "Po ponownym włączeniu rozszerzenia nie działa ono, dopóki użytkownik nie odświeży karty. Nawet wtedy rozszerzenie działa tylko na odświeżonej karcie, co wymaga, aby użytkownik ręcznie odświeżył wszystkie inne otwarte karty.",
    "Gdy użytkownik pobiera obrazy, przeglądarka blokuje interfejs użytkownika za pomocą okna popup, które pokazuje postęp pobierania.",
    "Przyszłe zachowanie:", 
    "Po ponownym włączeniu rozszerzenia będzie ono działać natychmiast na wszystkich kartach, bez potrzeby ręcznego odświeżania.",
    "Rozszerzenie będzie wyświetlać postęp pobierania za pomocą zintegrowanego paska postępu, nie zakłócając działania użytkownika, ale użytkownik nadal może korzystać z menedżera pobierania przeglądarki w celu uzyskania dalszych informacji."
  ],

  "pt": [
    "Notificação de Atualização de Abril",
    "A atualização de abril exigirá que você aceite novas permissões. Até que você aceite, o navegador desativará a extensão.",
    "Quais são as novas permissões?", 
    "A primeira permissão permite que a extensão detecte quando o usuário a habilita. A segunda permissão permite que a extensão bloqueie a janela pop-up que aparece quando o usuário baixa imagens.",
    "Por que estamos solicitando essas permissões?", 
    "Essa permissão nos ajuda a evitar comportamentos confusos em alguns casos quando o usuário reativa a extensão e melhora a experiência do usuário, impedindo o navegador de exibir pop-ups indesejados.",
    "Comportamento Atual:", 
    "Após reativar a extensão, ela não funciona até que o usuário atualize a aba. Mesmo assim, a extensão funciona apenas para a aba atualizada, exigindo que o usuário atualize manualmente todas as outras abas abertas.",
    "Quando o usuário baixa imagens, o navegador bloqueia a interface com um pop-up que mostra o progresso do download.",
    "Comportamento Futuro:", 
    "Após reativar a extensão, ela funcionará imediatamente em todas as abas sem precisar de uma atualização manual.",
    "A extensão exibirá o progresso do download com uma barra de progresso integrada, sem interromper o usuário, mas ele ainda poderá usar o rastreador de downloads do navegador para obter mais informações."
  ],
  "ro": [
    "Notificare de actualizare aprilie",
    "Actualizarea din aprilie va necesita acceptarea unor noi permisiuni. Până când le acceptați, browserul va dezactiva extensia.",
    "Care sunt noile permisiuni?", 
    "Prima permisiune permite extensiei să detecteze când utilizatorul o activează. A doua permisiune permite extensiei să blocheze fereastra pop-up care apare atunci când utilizatorul descarcă imagini.",
    "De ce solicităm aceste permisiuni?", 
    "Această permisiune ne ajută să prevenim comportamente confuze în anumite cazuri când utilizatorul reactivează extensia și îmbunătățește experiența utilizatorului prin prevenirea apariției pop-upurilor nedorite în browser.",
    "Comportament actual:", 
    "După reactivarea extensiei, aceasta nu funcționează până când utilizatorul nu reîmprospătează fila. Chiar și atunci, extensia funcționează doar pe fila actualizată, necesitând ca utilizatorul să reîmprospăteze manual toate celelalte file deschise.",
    "Când utilizatorul descarcă imagini, browserul blochează UI-ul cu o fereastră pop-up care arată progresul descărcării.",
    "Comportament viitor:", 
    "După reactivarea extensiei, aceasta va funcționa imediat pe toate filele, fără a necesita o reîmprospătare manuală.",
    "Extensia va afișa progresul descărcării cu o bară de progres integrată, fără a întrerupe utilizatorul, dar acesta va putea în continuare să folosească tracker-ul de descărcări al browserului pentru mai multe informații."
  ],

  "ru": [
    "Уведомление об обновлении апреля",
    "Обновление апреля потребует от вас принять новые разрешения. Пока вы не примете их, браузер отключит расширение.",
    "Что такое новые разрешения?", 
    "Первое разрешение позволяет расширению обнаруживать, когда пользователь его включает. Второе разрешение позволяет расширению блокировать всплывающее окно, которое появляется при загрузке изображений.",
    "Зачем мы запрашиваем эти разрешения?", 
    "Это разрешение помогает нам избежать путаницы в определенных случаях, когда пользователь повторно включает расширение, и улучшает опыт пользователя, предотвращая появление нежелательных всплывающих окон в браузере.",
    "Текущее поведение:", 
    "После повторной активации расширения оно не работает, пока пользователь не обновит вкладку. Даже после этого расширение работает только для обновленной вкладки, требуя от пользователя вручную обновить все другие открытые вкладки.",
    "Когда пользователь загружает изображения, браузер блокирует интерфейс всплывающим окном, показывающим прогресс загрузки.",
    "Будущее поведение:", 
    "После повторной активации расширение будет работать сразу на всех вкладках без необходимости вручную обновлять их.",
    "Расширение будет отображать прогресс загрузки с интегрированным индикатором прогресса, не прерывая действия пользователя, но он все равно сможет использовать трекер загрузки браузера для получения дополнительной информации."
  ],

  "sr": [
    "Obaveštenje o aprilskom ažuriranju",
    "Aprilskom ažuriranju će biti potrebno da prihvatite nove dozvole. Dok ih ne prihvatite, pregledač će onemogućiti ekstenziju.",
    "Šta su nove dozvole?", 
    "Prva dozvola omogućava ekstenziji da detektuje kada korisnik omogućava ekstenziju. Druga dozvola omogućava ekstenziji da blokira iskačući prozor koji se pojavljuje kada korisnik preuzima slike.",
    "Zašto tražimo ove dozvole?", 
    "Ova dozvola nam pomaže da sprečimo zbunjujuće ponašanje u određenim slučajevima kada korisnik ponovo aktivira ekstenziju, i poboljšava korisničko iskustvo sprečavanjem pojave neželjenih iskačućih prozora u pregledaču.",
    "Trenutno ponašanje:", 
    "Nakon ponovne aktivacije ekstenzije, ona ne radi dok korisnik ne osveži karticu. Čak i tada, ekstenzija funkcioniše samo na osveženoj kartici, što znači da korisnik mora ručno osvežiti sve ostale otvorene kartice.",
    "Kada korisnik preuzima slike, pregledač blokira korisnički interfejs sa iskačućim prozorom koji pokazuje napredak preuzimanja.",
    "Buduće ponašanje:", 
    "Nakon ponovne aktivacije ekstenzije, ona će odmah raditi na svim karticama, bez potrebe za ručnim osvežavanjem.",
    "Ekstenzija će prikazivati napredak preuzimanja sa integrisanim trakama napretka, bez ometanja korisnika, ali korisnik će i dalje moći da koristi praćenje preuzimanja u pregledaču za više informacija."
  ],

  "sk": [
    "Oznámenie o aprílovom aktualizovaní",
    "Aprílová aktualizácia bude vyžadovať, aby ste prijali nové oprávnenia. Kým ich neprijmete, prehliadač deaktivuje rozšírenie.",
    "Aké sú nové oprávnenia?", 
    "Prvé oprávnenie umožňuje rozšíreniu zistiť, keď ho používateľ aktivuje. Druhé oprávnenie umožňuje rozšíreniu zablokovať vyskakovacie okno, ktoré sa zobrazí pri sťahovaní obrázkov.",
    "Prečo požadujeme tieto oprávnenia?", 
    "Toto oprávnenie nám pomáha zabrániť mätúcemu správaniu v určitých prípadoch, keď používateľ znovu aktivuje rozšírenie, a zlepšuje používateľskú skúsenosť tým, že zabraňuje zobrazeniu nechcených vyskakovacích okien v prehliadači.",
    "Aktuálne správanie:", 
    "Po opätovnom aktivovaní rozšírenia nefunguje, pokým používateľ neobnoví kartu. Dokonca aj potom rozšírenie funguje len na obnovenú kartu, čo znamená, že používateľ musí manuálne obnoviť všetky ostatné otvorené karty.",
    "Keď používateľ sťahuje obrázky, prehliadač zablokuje používateľské rozhranie vyskakovacím oknom, ktoré zobrazuje priebeh sťahovania.",
    "Budúce správanie:", 
    "Po opätovnom aktivovaní rozšírenia bude okamžite fungovať na všetkých kartách bez potreby manuálneho obnovenia.",
    "Rozšírenie bude zobrazovať priebeh sťahovania so zabudovaným indikátorom postupu, bez prerušenia používateľa, ale používateľ bude stále môcť použiť sledovač sťahovania prehliadača na získanie ďalších informácií."
  ],

  "sl": [
    "Obvestilo o aprilski posodobitvi",
    "Aprilska posodobitev bo zahtevala, da sprejmete nova dovoljenja. Dokler jih ne sprejmete, bo brskalnik onemogočil razširitev.",
    "Katera so nova dovoljenja?", 
    "Prvo dovoljenje omogoči razširitvi, da zazna, kdaj jo uporabnik omogoči. Drugo dovoljenje omogoči razširitvi, da blokira pojavna okna, ki se prikažejo, ko uporabnik prenese slike.",
    "Zakaj zahtevamo ta dovoljenja?", 
    "To dovoljenje nam pomaga preprečiti zmedeno vedenje v določenih primerih, ko uporabnik ponovno omogoči razširitev, in izboljša uporabniško izkušnjo z preprečevanjem prikaza nezaželenih pojavnih oken v brskalniku.",
    "Trenutno vedenje:", 
    "Po ponovni aktivaciji razširitve ne deluje, dokler uporabnik ne osveži zavihka. Tudi takrat razširitev deluje samo na osveženem zavihku, kar pomeni, da mora uporabnik ročno osvežiti vse druge odprte zavihke.",
    "Ko uporabnik prenaša slike, brskalnik blokira uporabniški vmesnik z pojavnim oknom, ki prikazuje napredek prenosa.",
    "Pričakovano vedenje:", 
    "Po ponovni aktivaciji razširitve bo ta takoj delovala na vseh zavihkih brez potrebe po ročnem osveževanju.",
    "Razširitev bo prikazovala napredek prenosa z vgrajenim trakom napredovanja, ne da bi uporabnika motila, vendar bo uporabnik še vedno lahko uporabil sledenje prenosu v brskalniku za več informacij."
  ],

  "es": [
    "Notificación de actualización de abril",
    "La actualización de abril requerirá que acepte nuevos permisos. Hasta que los acepte, el navegador desactivará la extensión.",
    "¿Cuáles son los nuevos permisos?", 
    "El primer permiso permite que la extensión detecte cuando el usuario la habilita. El segundo permiso permite que la extensión bloquee la ventana emergente que aparece cuando el usuario descarga imágenes.",
    "¿Por qué estamos solicitando estos permisos?", 
    "Este permiso nos ayuda a evitar comportamientos confusos en ciertos casos cuando el usuario reactiva la extensión y mejora la experiencia del usuario al evitar que el navegador muestre ventanas emergentes no deseadas.",
    "Comportamiento actual:", 
    "Después de reactivar la extensión, no funcionará hasta que el usuario actualice la pestaña. Incluso entonces, la extensión solo funciona en la pestaña actualizada, lo que requiere que el usuario actualice manualmente todas las demás pestañas abiertas.",
    "Cuando el usuario descarga imágenes, el navegador bloquea la interfaz con una ventana emergente que muestra el progreso de la descarga.",
    "Comportamiento futuro:", 
    "Después de reactivar la extensión, funcionará inmediatamente en todas las pestañas sin necesidad de actualización manual.",
    "La extensión mostrará el progreso de la descarga con una barra de progreso integrada, sin interrumpir al usuario, pero el usuario aún podrá usar el rastreador de descargas del navegador para obtener más información."
  ],

  "sw": [
    "Arifa ya Sasisho la Aprili",
    "Sasisho la Aprili lita hitaji uonyeshe ruhusa mpya. Hadi ukubali, kivinjari kita zima programu-jalizi.",
    "Ruhusa mpya ni zipi?", 
    "Ruhusa ya kwanza inaruhusu programu-jalizi kugundua wakati mtumiaji anaizindua. Ruhusa ya pili inaruhusu programu-jalizi kuzima dirisha la popup linalojitokeza wakati mtumiaji anapakua picha.",
    "Kwa nini tunahitaji ruhusa hizi?", 
    "Ruhusa hii inatusaidia kuzuia tabia inayochanganya katika hali fulani ambapo mtumiaji anazindua tena programu-jalizi, na kuboresha uzoefu wa mtumiaji kwa kuzuia kivinjari kuonyesha popup zisizohitajika.",
    "Tabia ya sasa:", 
    "Baada ya kuzindua tena programu-jalizi, haitafanya kazi hadi mtumiaji akasasisha tabo. Hata hivyo, programu-jalizi inafanya kazi tu kwa tabo iliyo sasishwa, na mtumiaji atahitaji kubofya kuboresha tabo zote zilizofunguliwa kwa mikono.",
    "Wakati mtumiaji anapakua picha, kivinjari kinazuiya UI kwa dirisha la popup linaloonyesha maendeleo ya upakuaji.",
    "Tabia ya baadaye:", 
    "Baada ya kuzindua tena programu-jalizi, itafanya kazi mara moja kwenye tabo zote bila haja ya kuboresha kwa mikono.",
    "Programu-jalizi itaonyesha maendeleo ya upakuaji kwa kutumia upau wa maendeleo ulio jumuishwa, bila kuingilia mtumiaji, lakini mtumiaji bado anaweza kutumia kipengele cha kufuatilia upakuaji cha kivinjari kwa maelezo zaidi."
  ],
   "sv": [
    "Apriluppdateringsmeddelande",
    "Apriluppdateringen kräver att du accepterar nya behörigheter. Tills du accepterar dem kommer webbläsaren att inaktivera tillägget.",
    "Vad är de nya behörigheterna?", 
    "Den första nya behörigheten gör att tillägget kan upptäcka när användaren aktiverar det. Den andra behörigheten gör att tillägget kan blockera popup-fönstret som visas när användaren laddar ner bilder.",
    "Varför begär vi dessa behörigheter?", 
    "Denna behörighet hjälper oss att förhindra förvirrande beteende i vissa fall när användaren återaktiverar tillägget och förbättrar användarupplevelsen genom att förhindra att webbläsaren visar oönskade popup-fönster.",
    "Nuvarande beteende:", 
    "Efter att ha återaktiverat tillägget fungerar det inte förrän användaren uppdaterar fliken. Även då fungerar tillägget bara för den uppdaterade fliken, vilket kräver att användaren manuellt uppdaterar alla andra öppna flikar.",
    "När användaren laddar ner bilder blockerar webbläsaren användargränssnittet med ett popup-fönster som visar nedladdningsprogressionen.",
    "Framtida beteende:", 
    "Efter att ha återaktiverat tillägget kommer det att fungera omedelbart på alla flikar utan att kräva en manuell uppdatering.",
    "Tillägget kommer att visa nedladdningsprogressionen med ett integrerat framstegsfält, utan att störa användaren, men användaren kommer fortfarande att kunna använda webbläsarens nedladdningsspårare för mer information."
  ],

  "ta": [
    "ஏப்ரல் புதுப்பிப்பு அறிவிப்பு",
    "ஏப்ரல் புதுப்பிப்புக்கு புதிய அனுமதிகளை ஏற்றுக்கொள்வது தேவையானது. நீங்கள் அவற்றை ஏற்றுக்கொள்ளும் வரை, உலாவி இந்த நீட்சியை செயலிழக்க வைக்கப்படும்.",
    "புதிய அனுமதிகள் என்ன?", 
    "முதலாவது புதிய அனுமதி, பயனர் அதை இயக்கியபோது அதைக் கண்டுபிடிக்க நீட்சிக்கு உதவுகிறது. இரண்டாவது அனுமதி, பயனர் படங்களை பதிவிறக்கம் செய்யும் போது பாப்அப் ஐ வெளியிடும் பெட்டி (popup) ஐ பிளாக் செய்ய உதவுகிறது.",
    "இந்த அனுமதிகளை நாம் ஏன் கேட்கிறோம்?", 
    "இந்த அனுமதி, பயனர் நீட்சியை மீண்டும் இயக்கும் போது குழப்பத்தைத் தவிர்க்க உதவுகிறது, மேலும் உலாவியில் தேவையில்லாத பாப்அப் விண்டோவை காட்டாமல் பயனர் அனுபவத்தை மேம்படுத்துகிறது.",
    "நடப்பு நடத்தைகள்:", 
    "பயனர் நீட்சியை மீண்டும் இயக்கிய பின், அதில் எந்தச் செயல்பாட்டும் நிகழவில்லை, பயனர் அங்கீகாரம் அளிக்கும்போது. இதற்குக் பிறகு, அந்தக் காட்சிக்கு மட்டும் இயங்கும், மற்ற திறந்த பக்கங்களுக்குக் கையேடு மூலம் இவற்றை புதுப்பிக்க வேண்டும்.",
    "பயனர் படங்களை பதிவிறக்கம் செய்யும்போது, உலாவி பாப்அப் படத்தை அநேகமாகக் காண்பிக்கும்.",
    "எதிர்கால நடத்தை:", 
    "பயனர் நீட்சியை மீண்டும் இயக்கிய பின் அது அனைத்து பக்கங்களிலும் உடனடியாக இயங்கும்.",
    "நீட்சி பதிவிறக்க முன்னேற்றத்துடன் இணைக்கப்பட்டுள்ள progress bar ஐ காட்டும், பயனருக்கு இடைபாடுகளில்லாமல், அவன் இன்னும் உலாவி பதிவிறக்கம் கணினி மேலாண்மையை பயன்படுத்த முடியும்."
  ],

  "th": [
    "การแจ้งเตือนการอัปเดตเดือนเมษายน",
    "การอัปเดตเดือนเมษายนจะต้องให้คุณยอมรับสิทธิ์ใหม่ ก่อนที่คุณจะยอมรับ สิ่งนี้ เว็บเบราว์เซอร์จะปิดการใช้งานส่วนขยาย",
    "สิทธิ์ใหม่คืออะไร?", 
    "สิทธิ์ใหม่แรกช่วยให้ส่วนขยายตรวจจับเมื่อผู้ใช้เปิดใช้งานมัน สิทธิ์ที่สองช่วยให้ส่วนขยายบล็อกหน้าต่างป๊อปอัพที่แสดงขึ้นเมื่อผู้ใช้ดาวน์โหลดรูปภาพ",
    "ทำไมเราถึงขอสิทธิ์เหล่านี้?", 
    "สิทธิ์นี้ช่วยป้องกันพฤติกรรมที่สับสนในบางกรณีเมื่อผู้ใช้เปิดใช้งานส่วนขยายใหม่และช่วยปรับปรุงประสบการณ์ของผู้ใช้โดยการป้องกันไม่ให้เบราว์เซอร์แสดงป๊อปอัพที่ไม่ต้องการ",
    "พฤติกรรมปัจจุบัน:", 
    "หลังจากเปิดใช้งานส่วนขยายอีกครั้ง มันจะไม่ทำงานจนกว่าผู้ใช้จะรีเฟรชแท็บ แม้ว่าผู้ใช้จะรีเฟรชแท็บแล้ว ส่วนขยายจะทำงานเฉพาะในแท็บที่รีเฟรชแล้ว และผู้ใช้ต้องรีเฟรชแท็บที่เปิดอยู่ทั้งหมดด้วยตนเอง",
    "เมื่อผู้ใช้ดาวน์โหลดรูปภาพ เว็บเบราว์เซอร์จะบล็อก UI ด้วยหน้าต่างป๊อปอัพที่แสดงความคืบหน้าในการดาวน์โหลด",
    "พฤติกรรมในอนาคต:", 
    "หลังจากเปิดใช้งานส่วนขยายอีกครั้ง มันจะทำงานทันทีในทุกแท็บโดยไม่ต้องรีเฟรชด้วยตนเอง",
    "ส่วนขยายจะแสดงความคืบหน้าในการดาวน์โหลดด้วยแถบความคืบหน้าที่รวมอยู่ ผู้ใช้จะไม่ถูกรบกวน แต่มันยังสามารถใช้ตัวติดตามการดาวน์โหลดของเบราว์เซอร์เพื่อข้อมูลเพิ่มเติม"
  ],

  "te": [
    "ఏప్రిల్ అప్‌డేట్ నోటిఫికేషన్",
    "ఏప్రిల్ అప్‌డేట్ మీకు కొత్త అనుమతులు అంగీకరించాలని అవసరం. మీరు అవి అంగీకరించేముదల, బ్రౌజర్ ఈ ఎక్స్‌టెన్షన్‌ను డిసేబుల్ చేస్తుంది.",
    "కొత్త అనుమతులు ఏమిటి?", 
    "మొదటి కొత్త అనుమతి ఎక్స్‌టెన్షన్‌ను వినియోగదారు ప్రవేశపెట్టినప్పుడు అంగీకరించడం సులభం చేస్తుంది. రెండవ అనుమతి వినియోగదారు చిత్రాలను డౌన్లోడ్ చేస్తే పాప్ అప్ వస్తుంది.",
    "ఈ అనుమతులు మేము ఎందుకు అభ్యర్థిస్తున్నాము?", 
    "ఈ అనుమతి కొన్ని సందర్భాలలో సరికొత్త ఎక్స్‌టెన్షన్‌ను తిరిగి ప్రారంభించేటప్పుడు సమస్యలను నివారించడంలో మరియు బ్రౌజర్ నుండి అప్రతిష్టిత పాప్ అప్‌లను నిరోధించి వినియోగదారు అనుభవాన్ని మెరుగుపరచడంలో సహాయపడుతుంది.",
    "ప్రస్తుతం నిర్వహణ:", 
    "ఎక్స్‌టెన్షన్‌ను తిరిగి ప్రారంభించిన తర్వాత అది పనిచేయదు, వినియోగదారు ట్యాబ్‌ను రిఫ్రెష్ చేయాలి. అలాగే, అది కేవలం రిఫ్రెష్ చేసిన ట్యాబ్‌కు మాత్రమే పనిచేస్తుంది, మిగతా ట్యాబ్‌లను మరలా రిఫ్రెష్ చేయడం అవసరం.",
    "వినియోగదారు చిత్రాలను డౌన్లోడ్ చేసినప్పుడు, బ్రౌజర్ డౌన్లోడ్ పురోగతిని చూపించే పాప్ అప్ ను బ్లాక్ చేస్తుంది",
    "భవిష్యత్తులో నిర్వహణ:", 
    "ఎక్స్‌టెన్షన్‌ను తిరిగి ప్రారంభించిన తర్వాత అది సమర్ధంగా పనిచేస్తుంది.",
    "ఎక్స్‌టెన్షన్ డౌన్లోడ్ పురోగతిని ప్రదర్శిస్తుంది, ఇది వినియోగదారును తొలగించే ప్రక్రియను మరింత అందంగా చేస్తుంది."
  ],
  "tr": [
    "Nisan Güncellemesi Bildirimi",
    "Nisan güncellemesi yeni izinler kabul etmenizi gerektirecek. Bunları kabul edene kadar tarayıcı uzantıyı devre dışı bırakacak.",
    "Yeni izinler nedir?", 
    "İlk yeni izin, uzantının kullanıcının uzantıyı etkinleştirdiğini tespit etmesini sağlar. İkinci izin, uzantının kullanıcı resim indirirken açılan pop-up penceresini engellemesini sağlar.",
    "Bu izinleri neden istiyoruz?", 
    "Bu izin, kullanıcı uzantıyı yeniden etkinleştirdiğinde karışıklığı önlemeye yardımcı olur ve tarayıcıyı istenmeyen pop-up'ları göstermemesi konusunda kullanıcının deneyimini geliştirir.",
    "Mevcut Davranış:", 
    "Uzantıyı yeniden etkinleştirdikten sonra, kullanıcı sekmeyi yenileyene kadar çalışmaz. Yenilendikten sonra, uzantı yalnızca yenilenen sekmede çalışır, diğer açık sekmelerin manuel olarak yenilenmesi gerekir.",
    "Kullanıcı resim indirirken, tarayıcı UI'yi, indirilen resmin ilerlemesini gösteren bir pop-up ile engeller.",
    "Gelecek Davranış:", 
    "Uzantıyı yeniden etkinleştirdikten sonra, tüm sekmelerde hemen çalışacak ve manuel yenileme gerekmeyecek.",
    "Uzantı, bir ilerleme çubuğu ile indirme ilerlemesini gösterecek ve kullanıcıyı rahatsız etmeyecek, ancak kullanıcı hala daha fazla bilgi için tarayıcı indirme izleyicisini kullanabilecektir."
  ],

  "uk": [
    "Повідомлення про оновлення квітня",
    "Оновлення квітня вимагатиме від вас прийняти нові дозволи. Доки ви не приймете їх, браузер вимкне розширення.",
    "Що таке нові дозволи?", 
    "Перше нове дозволу дозволяє розширенню визначати, коли користувач увімкне його. Друге дозволяє розширенню блокувати спливаюче вікно, яке з'являється при завантаженні зображень користувачем.",
    "Чому ми запитуємо ці дозволи?", 
    "Це дозволяє нам запобігти непорозумінням, коли користувач повторно увімкне розширення та покращити досвід користувача, запобігаючи показу небажаних спливаючих вікон у браузері.",
    "Поточна поведінка:", 
    "Після повторного увімкнення розширення воно не працює, поки користувач не оновить вкладку. Навіть тоді розширення працює лише для оновленої вкладки, тому користувач повинен вручну оновити всі інші відкриті вкладки.",
    "Коли користувач завантажує зображення, браузер блокує інтерфейс користувача спливаючим вікном, що показує прогрес завантаження.",
    "Майбутня поведінка:", 
    "Після повторного увімкнення розширення воно працюватиме негайно на всіх вкладках без необхідності вручну оновлювати їх.",
    "Розширення відображатиме прогрес завантаження з інтегрованою смугою прогресу, не турбуючи користувача, але користувач все одно може використовувати відстежувач завантажень браузера для отримання додаткової інформації."
  ],

  "vi": [
    "Thông báo cập nhật tháng 4",
    "Cập nhật tháng 4 sẽ yêu cầu bạn chấp nhận các quyền mới. Cho đến khi bạn chấp nhận, trình duyệt sẽ vô hiệu hóa tiện ích mở rộng.",
    "Quyền mới là gì?", 
    "Quyền mới đầu tiên cho phép tiện ích mở rộng phát hiện khi người dùng bật tiện ích này. Quyền thứ hai cho phép tiện ích mở rộng chặn cửa sổ bật lên hiển thị khi người dùng tải xuống hình ảnh.",
    "Tại sao chúng tôi yêu cầu những quyền này?", 
    "Quyền này giúp chúng tôi ngăn ngừa hành vi nhầm lẫn khi người dùng bật lại tiện ích và cải thiện trải nghiệm người dùng bằng cách ngăn trình duyệt hiển thị các cửa sổ bật lên không mong muốn.",
    "Hành vi hiện tại:", 
    "Sau khi bật lại tiện ích, nó không hoạt động cho đến khi người dùng làm mới tab. Ngay cả khi làm mới, tiện ích chỉ hoạt động trên tab được làm mới, yêu cầu người dùng làm mới thủ công tất cả các tab khác.",
    "Khi người dùng tải xuống hình ảnh, trình duyệt chặn UI bằng cửa sổ bật lên hiển thị tiến trình tải xuống.",
    "Hành vi tương lai:", 
    "Sau khi bật lại tiện ích, nó sẽ hoạt động ngay lập tức trên tất cả các tab mà không cần làm mới thủ công.",
    "Tiện ích sẽ hiển thị tiến trình tải xuống với thanh tiến trình tích hợp, người dùng sẽ không bị gián đoạn, nhưng vẫn có thể sử dụng trình theo dõi tải xuống của trình duyệt để có thêm thông tin."
  ]
                  };
                                                
    return(
        <div style={{color:"white",direction: lang != 'he' ? 'ltr' : 'rtl'}}>
       <br/><br/><br/><br/>
       <h2>
{translation[lang][0]}
</h2>
<h1>
{translation[lang][1]}
</h1>
<h2><u>{translation[lang][2]}</u></h2>{translation[lang][3]}
<h2><u>{translation[lang][4]}</u></h2>{translation[lang][5]}
<br/><br/>
<table style={{ borderWidth: "2px", borderColor: "white", borderStyle: "solid" }}>
                <tbody>
                    <tr>
                        <td style={{ border: "2px solid white", padding: "8px" }}><strong>{translation[lang][6]}</strong></td>
                        <td style={{ border: "2px solid white", padding: "8px" }}><strong>{translation[lang][9]}</strong></td>
                    </tr>
                    <tr>
                        <td style={{ border: "2px solid white", padding: "8px" }}>{translation[lang][7]}</td>
                        <td style={{ border: "2px solid white", padding: "8px" }}>{translation[lang][10]}</td>
                    </tr>
                    <tr>
                        <td style={{ border: "2px solid white", padding: "8px" }}>{translation[lang][8]}</td>
                        <td style={{ border: "2px solid white", padding: "8px" }}>{translation[lang][11]}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        )
}