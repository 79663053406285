import { useParams } from "react-router-dom";

export default function PrintWithoutImagesReview(){
    const {browser,msgIndex,lang,numOfPrints} = useParams();
    if(browser=="edge"){
        window.location.replace('https://microsoftedge.microsoft.com/addons/detail/print-without-images-butt/cicgipmnmfpghkepflobccobfdejmobg');
    }else{
        window.location.replace('https://chromewebstore.google.com/detail/print-without-images-butt/bkhgnaijpkfmmdnhefolnpnjgllkgbmk');
    }
    return(
        <></>
    )
}