import { useParams } from "react-router-dom";

export default function PostContactGeneral(){
    
    document.title = "Suricata Software - Contact (post)";
    const {language} = useParams();
    let lang = language;
    const locals = ['pt','es','fr','de','it','hu','tr','ru','he','vi','th','ko','zh_CN','zh_TW','ja'];                
    if(!locals.includes(lang)){
      lang='en';
    }
    const txt ={
        "en": "Thank you for contacting us. We will be in touch with you very soon.",
        "pt": "Obrigado por entrar em contato conosco. Entraremos em contato com você em breve.",
        "es": "Gracias por contactarnos. Nos pondremos en contacto contigo muy pronto.",
        "fr": "Merci de nous avoir contactés. Nous vous répondrons très bientôt.",
        "de": "Danke, dass Sie uns kontaktiert haben. Wir werden uns bald mit Ihnen in Verbindung setzen.",
        "it": "Grazie per averci contattato. Ti contatteremo molto presto.",
        "hu": "Köszönjük, hogy kapcsolatba lépett velünk. Hamarosan felvesszük Önnel a kapcsolatot.",
        "tr": "Bizimle iletişime geçtiğiniz için teşekkür ederiz. En kısa sürede sizinle iletişime geçeceğiz.",
        "ru": "Спасибо, что связались с нами. Мы скоро с вами свяжемся.",
        "he": "תודה שפנית אלינו. אנו ניצור איתך קשר בקרוב מאוד.",
        "vi": "Cảm ơn bạn đã liên hệ với chúng tôi. Chúng tôi sẽ sớm liên lạc với bạn.",
        "th": "ขอบคุณที่ติดต่อเรา เราจะติดต่อคุณในไม่ช้า",
        "ko": "문의해 주셔서 감사합니다. 곧 연락드리겠습니다.",
        "zh_CN": "感谢您联系我们。我们将很快与您取得联系。",
        "zh_TW": "感謝您與我們聯繫。我們將很快與您聯絡。",
        "ja": "お問い合わせいただきありがとうございます。すぐにご連絡いたします。"
      };
    return (
        <div className="main-container">
            <h1>
                <p style={{color:"white"}}>{txt[lang]}</p>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </h1>
        </div>
    )
}