import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function NoSwipeRateUs(props){

    const appNameTrans = {
        "ar": {
            "appName": {"message": "No Swipe - تعطيل السحب"},
            "appDesc": {"message": "تعطيل التنقل بالإصبعين على لوحة اللمس."}
        },
        "am": {
            "appName": {"message": "No Swipe - ትክክለኛ ያግኝ"},
            "appDesc": {"message": "የአካባቢ እርምጃ ያደርጋል ሁለት አንቀላፍ የካርታ በመጠቀም።"}
        },
        "bg": {
            "appName": {"message": "No Swipe - Деактивиране на плъзгането"},
            "appDesc": {"message": "Деактивиране на навигацията с два пръста върху тъчпада."}
        },
        "bn": {
            "appName": {"message": "No Swipe - স্যুইপ অক্ষম করুন"},
            "appDesc": {"message": "টাচপ্যাডের দুটি আঙুল নেভিগেশন নিষ্ক্রিয় করুন।"}
        },
        "ca": {
            "appName": {"message": "No Swipe - Desactivar el lliscament"},
            "appDesc": {"message": "Desactiva la navegació amb dos dits al touchpad."}
        },
        "cs": {
            "appName": {"message": "No Swipe - Zakázat posouvání"},
            "appDesc": {"message": "Zakázat navigaci dvěma prsty na touchpadu."}
        },
        "da": {
            "appName": {"message": "No Swipe - Deaktiver swipe"},
            "appDesc": {"message": "Deaktiver tofingernavigation på touchpad."}
        },
        "de": {
            "appName": {"message": "No Swipe - Wischen deaktivieren"},
            "appDesc": {"message": "Deaktiviere die Touchpad-Zwei-Finger-Navigation."}
        },
        "el": {
            "appName": {"message": "No Swipe - Απενεργοποίηση σύρσιμου"},
            "appDesc": {"message": "Απενεργοποίηση πλοήγησης με δύο δάχτυλα στο touchpad."}
        },
        "en": {
            "appName": {"message": "No Swipe - Disable Swipe"},
            "appDesc": {"message": "Disable trackpad two fingers navigation."}
        },
        "en_AU": {
            "appName": {"message": "No Swipe - Disable Swipe"},
            "appDesc": {"message": "Disable trackpad two fingers navigation."}
        },
        "en_GB": {
            "appName": {"message": "No Swipe - Disable Swipe"},
            "appDesc": {"message": "Disable trackpad two fingers navigation."}
        },
        "en_US": {
            "appName": {"message": "No Swipe - Disable Swipe"},
            "appDesc": {"message": "Disable trackpad two fingers navigation."}
        },
        "es": {
            "appName": {"message": "No Swipe - Desactivar deslizamiento"},
            "appDesc": {"message": "Desactiva la navegación con dos dedos en el panel táctil."}
        },
        "es_419": {
            "appName": {"message": "No Swipe - Desactivar deslizamiento"},
            "appDesc": {"message": "Desactiva la navegación con dos dedos en el panel táctil."}
        },
        "et": {
            "appName": {"message": "No Swipe - Keela libistamine"},
            "appDesc": {"message": "Keela puuteplaatide navigeerimine kahe sõrmega."}
        },
        "fa": {
            "appName": {"message": "No Swipe - غیرفعال کردن کشیدن"},
            "appDesc": {"message": "غیرفعال کردن دو انگشت ناوبری در تاچ‌پد."}
        },
        "fi": {
            "appName": {"message": "No Swipe - Poista pyyhkäisy"},
            "appDesc": {"message": "Poista kosketuslevyn kahden sormen navigointi."}
        },
        "fil": {
            "appName": {"message": "No Swipe - Huwag Galawin ang Swipe"},
            "appDesc": {"message": "I-disable ang daliring navigasyon sa touchpad."}
        },
        "fr": {
            "appName": {"message": "No Swipe - Désactiver le balayage"},
            "appDesc": {"message": "Désactivez la navigation à deux doigts sur le pavé tactile."}
        },
        "gu": {
            "appName": {"message": "No Swipe - સ્વાઈપ અક્ષમ કરો"},
            "appDesc": {"message": "ટચપેડ દોડા નેવિગેશન નિષ્ક્રિય કરો બીજી ટેકલીનો સાંધાન."}
        },
        "he": {
            "appName": {"message": "No Swipe - השבת החלקה"},
            "appDesc": {"message": "מבטל ניווט בעזרת שתי אצבעות על משטח מגע."}
        },
        "hi": {
            "appName": {"message": "No Swipe - स्वाइप को अक्षम करें"},
            "appDesc": {"message": "टचपैड पर दो उंगलियों की नेविगेशन को निष्क्रिय करें।"}
        },
        "hr": {
            "appName": {"message": "No Swipe - Onemogući povlačenje"},
            "appDesc": {"message": "Onemogući navigaciju dva prsta na touchpadu."}
        },
        "hu": {
            "appName": {"message": "No Swipe - Pörges tiltása"},
            "appDesc": {"message": "A touchpad kéttakarós navigációjának letiltása."}
        },
        "id": {
            "appName": {"message": "No Swipe - Menonaktifkan Swipe"},
            "appDesc": {"message": "Menonaktifkan navigasi dua jari di touchpad."}
        },
        "it": {
            "appName": {"message": "No Swipe - Disabilita lo Swipe"},
            "appDesc": {"message": "Disabilita la navigazione a due dita sul touchpad."}
        },
        "ja": {
            "appName": {"message": "No Swipe - スワイプを無効にする"},
            "appDesc": {"message": "タッチパッドの2本指ナビゲーションを無効にします。"}
        },
        "kn": {
            "appName": {"message": "No Swipe - ಸ್ವೈಪ್ ನಿಲ್ಲಿಸಿ"},
            "appDesc": {"message": "ಟಚ್‌ಪ್ಯಾಡ್‌ನಲ್ಲಿ ಎರಡು ಬೆರಳು ನ್ಯಾವಿಗೇಷನ್‌ನನ್ನು ನಿಲ್ಲಿಸಿ."}
        },
        "ko": {
            "appName": {"message": "No Swipe - 스와이프 비활성화"},
            "appDesc": {"message": "터치 패드 두 손가락 네비게이션 비활성화."}
        },
        "lt": {
            "appName": {"message": "No Swipe - Išjungti Swipe"},
            "appDesc": {"message": "Išjungti navigaciją dviem pirštais ant touchpad."}
        },
        "lv": {
            "appName": {"message": "No Swipe - Deaktivēt Swipe"},
            "appDesc": {"message": "Deaktivēt divpirkstu navigāciju uz pieskāriena paneli."}
        },
        "ml": {
            "appName": {"message": "No Swipe - സ്വൈപ് അടയ്ക്കുക"},
            "appDesc": {"message": "ടച്ച്‌പാഡിലെ രണ്ട് അങ്കുളകളുടെ നാവിഗേഷൻ അടയ്ക്കുക."}
        },
        "mr": {
            "appName": {"message": "No Swipe - स्वाईप अक्षम करा"},
            "appDesc": {"message": "टचपॅडवर दोन बोटांचे नेव्हिगेशन अक्षम करा."}
        },
        "ms": {
            "appName": {"message": "No Swipe - Matikan Swipe"},
            "appDesc": {"message": "Matikan navigasi dua jari di touchpad."}
        },
        "nl": {
            "appName": {"message": "No Swipe - Swipe uitschakelen"},
            "appDesc": {"message": "Schakel de touchpad-navigatie met twee vingers uit."}
        },
        "no": {
            "appName": {"message": "No Swipe - Deaktiver sveiping"},
            "appDesc": {"message": "Deaktiver berøringsskjermens to-finger-navigasjon."}
        },
        "pl": {
            "appName": {"message": "No Swipe - Wyłącz Swipe"},
            "appDesc": {"message": "Wyłącz nawigację dwoma palcami na touchpadzie."}
        },
        "pt": {
            "appName": {"message": "No Swipe - Desativar Swipe"},
            "appDesc": {"message": "Desativar navegação de dois dedos no touchpad."}
        },
        "ro": {
            "appName": {"message": "No Swipe - Dezactivați Swipe"},
            "appDesc": {"message": "Dezactivați navigarea cu două degete pe touchpad."}
        },
        "ru": {
            "appName": {"message": "No Swipe - Отключить свайп"},
            "appDesc": {"message": "Отключите навигацию двумя пальцами на тачпаде."}
        },
        "sk": {
            "appName": {"message": "No Swipe - Zakázať Swipe"},
            "appDesc": {"message": "Zakázať navigáciu dvoma prstami na touchpad."}
        },
        "sl": {
            "appName": {"message": "No Swipe - Onemogoči povleci"},
            "appDesc": {"message": "Onemogoči navigacijo z dvema prstoma na sledilni ploščici."}
        },
        "sr": {
            "appName": {"message": "No Swipe - Onemogući povlačenje"},
            "appDesc": {"message": "Onemogućite navigaciju dva prsta na touchpadu."}
        },
        "sv": {
            "appName": {"message": "No Swipe - Inaktivera Swipe"},
            "appDesc": {"message": "Inaktivera tvåfingernavigering på styrplattan."}
        },
        "sw": {
            "appName": {"message": "No Swipe - Lemaza Swipe"},
            "appDesc": {"message": "Lemaza usafiri wa vidole viwili kwenye kifaa cha kugusa."}
        },
        "ta": {
            "appName": {"message": "No Swipe - ஸ்வைப் முடக்கு"},
            "appDesc": {"message": "டச்சிப்பேடில் இரண்டு விரல் நாவிகேசனை முடக்கு."}
        },
        "te": {
            "appName": {"message": "No Swipe - స్వైప్‌ను అమలు చేయి"},
            "appDesc": {"message": "టచ్‌ప్యాడ్‌లో రెండు వంటి నావిగేషన్‌ను నిల్వ చేయండి."}
        },
        "th": {
            "appName": {"message": "No Swipe - ปิดการใช้งาน Swipe"},
            "appDesc": {"message": "ปิดการนำทางด้วยนิ้วสองบนแผงสัมผัส"}
        },
        "tr": {
            "appName": {"message": "No Swipe - Swipe'yı Devre Dışı Bırak"},
            "appDesc": {"message": "Dokunmatik yüzeyde iki parmakla gezinmeyi devre dışı bırakın."}
        },
        "uk": {
            "appName": {"message": "No Swipe - Вимкніть Swipe"},
            "appDesc": {"message": "Вимкніть навігацію двома пальцями на тачпаді."}
        },
        "vi": {
            "appName": {"message": "No Swipe - Vô hiệu hóa Swipe"},
            "appDesc": {"message": "Vô hiệu hóa điều hướng hai ngón tay trên bàn di chuột."}
        },
        "zh_CN": {
            "appName": {"message": "No Swipe - 禁用滑动"},
            "appDesc": {"message": "禁用触摸板的双指导航。"}
        },
        "zh_TW": {
            "appName": {"message": "No Swipe - 停用滑動"},
            "appDesc": {"message": "停用觸控板的雙指導覽。"}
        }
    }
    const headers = {
        "ar": ["تم تحديث ملحقك No Swipe إلى أحدث إصدار."],
        "am": ["እባክህ የአካባቢ ትክክለኛ ያግኝ የሆነው ምሳሌ No Swipe ወደ ቅርብ እትም ተቀይሯል።"],
        "bg": ["Вашето разширение No Swipe беше актуализирано до последната версия."],
        "bn": ["আপনার এক্সটেনশন No Swipe সর্বশেষ সংস্করণে আপডেট করা হয়েছে।"],
        "ca": ["La teva extensió No Swipe s'ha actualitzat a l'última versió."],
        "cs": ["Vaše rozšíření No Swipe bylo aktualizováno na nejnovější verzi."],
        "da": ["Din udvidelse No Swipe blev opdateret til den nyeste version."],
        "de": ["Ihre Erweiterung No Swipe wurde auf die neueste Version aktualisiert."],
        "el": ["Η επέκτασή σας No Swipe ενημερώθηκε στην τελευταία έκδοση."],
        "en": ["Your extension No Swipe was updated to the latest version."],
        "es": ["Tu extensión No Swipe se actualizó a la última versión."],
        "et": ["Teie laiendus No Swipe on uuendatud uusimasse versiooni."],
        "fa": ["اکستنشن شما No Swipe به جدیدترین نسخه بروزرسانی شد."],
        "fi": ["Laajennuksesi No Swipe päivitettiin uusimpaan versioonin."],
        "fil": ["In-update ang iyong extension No Swipe sa pinakabagong bersyon."],
        "fr": ["Votre extension No Swipe a été mise à jour vers la dernière version."],
        "gu": ["તમારી વિસ્તરણ No Swipe નવીનતમ આવૃત્તિમાં અપડેટ થઈ છે."],
        "he": ["ההרחבה שלך No Swipe עודכנה לגרסה האחרונה."],
        "hi": ["आपका विस्तार No Swipe नवीनतम संस्करण में अपडेट किया गया था।"],
        "hr": ["Vaša ekstenzija No Swipe ažurirana je na najnoviju verziju."],
        "hu": ["A No Swipe kiegészítője a legújabb verzióra frissült."],
        "id": ["Ekstensi No Swipe Anda telah diperbarui ke versi terbaru."],
        "it": ["La tua estensione No Swipe è stata aggiornata all'ultima versione."],
        "ja": ["あなたの拡張機能No Swipeは最新バージョンに更新されました。"],
        "kn": ["ನಿಮ್ಮ ಎಕ್ಸ್ಟೆನ್ಷನ್ No Swipe ಇತ್ತೀಚಿನ ಆವೃತ್ತಿಗೆ ನವೀಕರಿಸಲಾಗಿದೆ."],
        "ko": ["귀하의 확장 프로그램 No Swipe가 최신 버전으로 업데이트되었습니다."],
        "lt": ["Jūsų plėtinys No Swipe buvo atnaujintas į naujausią versiją."],
        "lv": ["Jūsu paplašinājums No Swipe tika atjaunināts uz jaunāko versiju."],
        "ml": ["നിന്റെ എക്സ്റ്റൻഷൻ No Swipe പുതിയ പതിപ്പിലേക്ക് അപ്‌ഡേറ്റ് ചെയ്യപ്പെട്ടു."],
        "mr": ["तुमच्या विस्तार No Swipe चे नवीनतम आवृत्तीमध्ये अद्यतनित केले गेले आहे."],
        "ms": ["Sambungan anda No Swipe telah dikemas kini ke versi terkini."],
        "nl": ["Je extensie No Swipe is bijgewerkt naar de laatste versie."],
        "no": ["Utvidelsen din No Swipe ble oppdatert til den nyeste versjonen."],
        "pl": ["Twoje rozszerzenie No Swipe zostało zaktualizowane do najnowszej wersji."],
        "pt": ["Sua extensão No Swipe foi atualizada para a versão mais recente."],
        "ro": ["Extensia dvs. No Swipe a fost actualizată la ultima versiune."],
        "ru": ["Ваше расширение No Swipe было обновлено до последней версии."],
        "sk": ["Vaša rozšírenie No Swipe bolo aktualizované na najnovšiu verziu."],
        "sl": ["Vaša razširitev No Swipe je bila posodobljena na najnovejšo različico."],
        "sr": ["Vaša ekstenzija No Swipe ažurirana je na najnoviju verziju."],
        "sv": ["Din förlängning No Swipe har uppdaterats till den senaste versionen."],
        "sw": ["Uganiaji wako No Swipe umeboreshwa hadi toleo jipya."],
        "ta": ["உங்கள் விரிவாக்கம் No Swipe புதிய பதிப்புக்கு புதுப்பிக்கப்பட்டது."],
        "te": ["మీ ఎక్స్టెన్షన్ No Swipe తాజా సంచికలో అప్‌డేట్ చేయబడింది."],
        "th": ["ส่วนขยายของคุณ No Swipe ได้รับการอัปเดตเป็นเวอร์ชันล่าสุดแล้ว."],
        "tr": ["No Swipe uzantınız en son sürüme güncellendi."],
        "uk": ["Ваше розширення No Swipe було оновлено до останньої версії."],
        "vi": ["Tiện ích mở rộng No Swipe của bạn đã được cập nhật lên phiên bản mới nhất."],
        "zh_CN": ["您的扩展 No Swipe 已更新到最新版本。"],
        "zh_TW": ["您的擴充功能 No Swipe 已更新至最新版本。"]
    };
    
    
    const text = {
            "en": {
              "p1": "We have some important announcements:",
              "p2": "New installations will require a paid subscription to use the white and black lists (and other upcoming features). However, your current installation will continue to be free.",
              "p3": "Recent versions of Chrome and Edge now support disabling swiping natively. This setting can be found under the Accessibility tab. If all you want is to disable swipe gestures across all sites, you no longer need the extension for that."
            },
            "ar": {
              "p1": "لدينا بعض الإعلانات المهمة:",
              "p2": "الإصدارات الجديدة ستتطلب اشتراكًا مدفوعًا لاستخدام القوائم البيضاء والسوداء (وميزات أخرى قادمة). ومع ذلك، سيظل التثبيت الحالي مجانيًا.",
              "p3": "الإصدارات الأخيرة من Chrome و Edge تدعم الآن تعطيل السحب بشكل أصلي. يمكن العثور على هذا الإعداد تحت تبويب الوصول. إذا كنت تريد فقط تعطيل إيماءات السحب عبر جميع المواقع، فلن تحتاج إلى الامتداد لذلك."
            },
            "am": {
              "p1": "እኛ አስፈላጊ ማስታወቂያዎች አለን:",
              "p2": "አዲስ እንሳት ለትክክለኛ እና ግምገማ ስምንትን ይፈልጋሉ (እንዲሁም በሚመጣ ባለሞያ ምርቶች). ነገር ግን የአሁን እንሳት በታማኝ ይቆያል.",
              "p3": "በቅርብ የምእመናን የChrome እና Edge እትም ሳምንታት ስምምን ይፈለጋል."
            },
              "bg": {
              "p1": "Имаме някои важни съобщения:",
              "p2": "Нови инсталации ще изискват платена абонаментна услуга за използване на белите и черни списъци (и други предстоящи функции). Въпреки това, вашата текуща инсталация ще остане безплатна.",
              "p3": "Последните версии на Chrome и Edge вече поддържат деактивирането на прелистването по подразбиране. Това настройка може да бъде намерена в раздела за достъпност. Ако искате просто да деактивирате жестовете за плъзгане на всички сайтове, вече не ви трябва разширението."
            },
            "bn": {
              "p1": "আমাদের কিছু গুরুত্বপূর্ণ ঘোষণা রয়েছে:",
              "p2": "নতুন ইনস্টলেশনগুলির জন্য সাদা এবং কালো তালিকা (এবং অন্যান্য আসন্ন বৈশিষ্ট্য) ব্যবহার করতে একটি পেইড সাবস্ক্রিপশন প্রয়োজন। তবে, আপনার বর্তমান ইনস্টলেশনটি ফ্রি থাকতে থাকবে।",
              "p3": "সাম্প্রতিক Chrome এবং Edge সংস্করণগুলি এখন নেটিভভাবে স্যুইপিং নিষ্ক্রিয় করতে সমর্থন করে। এই সেটিংটি অ্যাক্সেসিবিলিটি ট্যাবের অধীনে পাওয়া যাবে। যদি আপনি শুধুমাত্র সমস্ত সাইটে স্যুইপ ইশারা নিষ্ক্রিয় করতে চান, তবে আর সেই জন্য এক্সটেনশনের প্রয়োজন নেই।"
            },
            "ca": {
              "p1": "Tenim algunes notícies importants:",
              "p2": "Les noves instal·lacions requeriran una subscripció de pagament per utilitzar les llistes blanques i negres (i altres funcions properes). Tanmateix, la vostra instal·lació actual seguirà sent gratuïta.",
              "p3": "Les versions recents de Chrome i Edge ara admeten la desactivació de l'arrossegament de forma nativa. Aquesta configuració es pot trobar a la pestanya d'Accessibilitat. Si el que voleu és desactivar els gestos de desplaçament a tots els llocs, ja no necessitareu l'extensió per això."
            },
            "cs": {
              "p1": "Máme několik důležitých oznámení:",
              "p2": "Nové instalace budou vyžadovat placené předplatné pro používání bílých a černých seznamů (a dalších nadcházejících funkcí). Vaše aktuální instalace však zůstane zdarma.",
              "p3": "Novější verze prohlížečů Chrome a Edge nyní podporují nativní deaktivaci gesta pro přetahování. Toto nastavení lze najít v záložce Přístupnost. Pokud chcete pouze deaktivovat gesta přetahování na všech stránkách, již nepotřebujete tuto rozšíření."
            },
            "da": {
              "p1": "Vi har nogle vigtige meddelelser:",
              "p2": "Nye installationer vil kræve et betalt abonnement for at bruge de hvide og sorte lister (og andre kommende funktioner). Din nuværende installation vil dog fortsat være gratis.",
              "p3": "De nyeste versioner af Chrome og Edge understøtter nu at deaktivere swipe-gester som standard. Denne indstilling kan findes under fanen Tilgængelighed. Hvis du kun vil deaktivere swipe-gester på alle websteder, behøver du ikke længere udvidelsen."
            },
            "de": {
              "p1": "Wir haben einige wichtige Ankündigungen:",
              "p2": "Neue Installationen erfordern ein kostenpflichtiges Abonnement, um die weißen und schwarzen Listen (und andere kommende Funktionen) zu nutzen. Ihre aktuelle Installation bleibt jedoch kostenlos.",
              "p3": "Die neuesten Versionen von Chrome und Edge unterstützen jetzt das native Deaktivieren von Wischgesten. Diese Einstellung finden Sie unter dem Tab 'Barrierefreiheit'. Wenn Sie nur die Wischgesten auf allen Websites deaktivieren möchten, benötigen Sie die Erweiterung dafür nicht mehr."
            },
            "el": {
              "p1": "Έχουμε μερικές σημαντικές ανακοινώσεις:",
              "p2": "Οι νέες εγκαταστάσεις θα απαιτούν συνδρομή για τη χρήση των λευκών και μαύρων λιστών (και άλλων επερχόμενων χαρακτηριστικών). Ωστόσο, η τρέχουσα εγκατάστασή σας θα παραμείνει δωρεάν.",
              "p3": "Οι τελευταίες εκδόσεις των Chrome και Edge υποστηρίζουν τώρα την απενεργοποίηση των κινήσεων σάρωσης εγγενώς. Αυτή η ρύθμιση μπορεί να βρεθεί στην καρτέλα Προσβασιμότητα. Εάν το μόνο που θέλετε είναι να απενεργοποιήσετε τις κινήσεις σάρωσης σε όλες τις τοποθεσίες, δεν χρειάζεστε πλέον την επέκταση για αυτό."
            },
            "es": {
              "p1": "Tenemos algunos anuncios importantes:",
              "p2": "Las nuevas instalaciones requerirán una suscripción paga para usar las listas blanca y negra (y otras funciones próximas). Sin embargo, su instalación actual continuará siendo gratuita.",
              "p3": "Las versiones recientes de Chrome y Edge ahora admiten la desactivación del deslizamiento de forma nativa. Esta configuración se puede encontrar en la pestaña de Accesibilidad. Si lo único que desea es desactivar los gestos de deslizamiento en todos los sitios, ya no necesitará la extensión para eso."
            },
            "et": {
              "p1": "Meil on mõned olulised teadanded:",
              "p2": "Uued installatsioonid nõuavad tasulist tellimust valge ja musta nimekirja (ning teiste tulevaste funktsioonide) kasutamiseks. Teie praegune installatsioon jääb siiski tasuta.",
              "p3": "Viimased Chrome'i ja Edge'i versioonid toetavad nüüd lohvimise keelamist natiivse funktsioonina. Seda seadet saab leida ligipääsetavuse vahekaartilt. Kui soovite lihtsalt keelata lohvimise žeste kõikidel saitidel, ei ole teil enam selleks laiendust vaja."
            },
                "fa": {
                  "p1": "ما برخی اطلاعیه‌های مهم داریم:",
                  "p2": "نصب‌های جدید نیاز به اشتراک پولی برای استفاده از لیست‌های سفید و سیاه (و ویژگی‌های آینده) دارند. با این حال، نصب فعلی شما همچنان رایگان باقی خواهد ماند.",
                  "p3": "نسخه‌های جدید Chrome و Edge اکنون از غیرفعال کردن سوایپ به طور بومی پشتیبانی می‌کنند. این تنظیمات را می‌توانید در تب دسترس‌پذیری پیدا کنید. اگر تنها هدف شما غیرفعال کردن حرکات سوایپ در تمام سایت‌ها است، دیگر نیازی به افزونه برای این کار ندارید."
                },
                "fi": {
                  "p1": "Meillä on joitakin tärkeitä ilmoituksia:",
                  "p2": "Uudet asennukset vaativat maksullisen tilauksen valkoisten ja mustien listojen (ja muiden tulevien ominaisuuksien) käyttämiseen. Nykyinen asennuksesi pysyy kuitenkin ilmaisena.",
                  "p3": "Uusimmat Chrome- ja Edge-versiot tukevat nyt pyyhkäisyn poistamista käytöstä natively. Tämä asetus löytyy saatavuus-välilehdeltä. Jos haluat vain poistaa pyyhkäisyliikkeet käytöstä kaikilla sivustoilla, et enää tarvitse laajennusta siihen."
                },
                "fil": {
                  "p1": "Mayroon kaming ilang mahahalagang anunsyo:",
                  "p2": "Ang mga bagong installation ay mangangailangan ng isang bayad na subscription upang magamit ang mga puti at itim na listahan (at iba pang mga darating na tampok). Gayunpaman, ang iyong kasalukuyang installation ay mananatiling libre.",
                  "p3": "Ang mga pinakabagong bersyon ng Chrome at Edge ay ngayon ay sumusuporta sa pag-disable ng swipe gestures nang katutubong. Ang setting na ito ay matatagpuan sa ilalim ng Accessibility tab. Kung ang nais mo lamang ay i-disable ang swipe gestures sa lahat ng mga site, hindi mo na kailangan ang extension para doon."
                },
                "fr": {
                  "p1": "Nous avons quelques annonces importantes :",
                  "p2": "Les nouvelles installations nécessiteront un abonnement payant pour utiliser les listes blanches et noires (et d'autres fonctionnalités à venir). Cependant, votre installation actuelle restera gratuite.",
                  "p3": "Les dernières versions de Chrome et Edge prennent désormais en charge la désactivation des gestes de balayage nativement. Ce paramètre peut être trouvé sous l'onglet Accessibilité. Si tout ce que vous voulez est de désactiver les gestes de balayage sur tous les sites, vous n'avez plus besoin de l'extension pour cela."
                },
                "gu": {
                  "p1": "અમારી પાસે કેટલીક મહત્વની જાહેરાતો છે:",
                  "p2": "નવી ઇન્સ્ટોલેશન માટે વ્હાઇટ અને બ્લેક લિસ્ટ (અને અન્ય આવનારી સુવિધાઓ) માટે પેઇડ સબ્સ્ક્રિપ્શનની જરૂર પડશે. જોકે, તમારી વર્તમાન ઇન્સ્ટોલેશન મફતમાં ચાલુ રહેશે.",
                  "p3": "નવી Chrome અને Edge સંસ્કરણો હવે સ્વાઇપિંગને નેટિવલી ડિએક્ટિવેટ કરવાનો આધાર આપે છે. આ સેટિંગ્સ ઍક્સેસિબિલિટી ટૅબ હેઠળ મળે છે. જો તમે માત્ર બધાં સાઇટ્સ પર સ્વાઇપ ગેસ્ટર્સને ડિએક્ટિવેટ કરવા માંગતા હો, તો તમને હવે આ માટે એક્સટેંશનની જરૂર નહીં હોય."
                },
                "he": {
                  "p1": "יש לנו כמה הודעות חשובות:",
                  "p2": "התקנות חדשות ידרשו מנוי בתשלום לשימוש ברשימות הלבנות והשחורות (ופיצ'רים נוספים שיבואו). עם זאת, ההתקנה הנוכחית שלך תישאר חינמית.",
                  "p3": "גרסאות עדכניות של Chrome ו-Edge תומכות כעת בהשבתת החלקה בצורה מקורית. ההגדרה הזו נמצאת תחת לשונית נגישות. אם כל מה שאתה רוצה הוא להשבית את מחוות החלקה בכל האתרים, כבר לא תזדקק להרחבה לצורך זה."
                },
                "hi": {
                  "p1": "हमारे पास कुछ महत्वपूर्ण घोषणाएँ हैं:",
                  "p2": "नई इंस्टॉलेशन के लिए सफेद और काले सूची (और अन्य आगामी सुविधाओं) का उपयोग करने के लिए एक भुगतान की सदस्यता की आवश्यकता होगी। हालांकि, आपकी वर्तमान इंस्टॉलेशन मुफ्त में जारी रहेगी।",
                  "p3": "Chrome और Edge के नवीनतम संस्करण अब स्वाइपिंग को स्वाभाविक रूप से निष्क्रिय करने का समर्थन करते हैं। यह सेटिंग एक्सेसिबिलिटी टैब के तहत पाई जा सकती है। यदि आप केवल सभी साइटों पर स्वाइप इशारों को निष्क्रिय करना चाहते हैं, तो आपको इसके लिए अब एक्सटेंशन की आवश्यकता नहीं होगी।"
                },
                "hr": {
                  "p1": "Imamo nekoliko važnih obavijesti:",
                  "p2": "Nove instalacije zahtijevat će plaćenu pretplatu za korištenje bijelih i crnih lista (i drugih nadolazećih značajki). Međutim, vaša trenutna instalacija ostat će besplatna.",
                  "p3": "Nedavne verzije Chrome-a i Edge-a sada podržavaju onemogućavanje swiping gesti nativno. Ova postavka može se pronaći u kartici Pristupačnost. Ako želite samo onemogućiti swipe geste na svim web stranicama, više vam neće trebati ekstenzija za to."
                },
                "hu": {
                  "p1": "Fontos bejelentéseink vannak:",
                  "p2": "Az új telepítésekhez fizetős előfizetés szükséges a fehér és fekete listák (és más közelgő funkciók) használatához. Azonban a jelenlegi telepítésed továbbra is ingyenes marad.",
                  "p3": "A Chrome és Edge legújabb verziói mostantól natívan támogatják a swipe gesztusok letiltását. Ezt a beállítást az Akadálymentesség fülön találhatod. Ha csak a swipe gesztusokat szeretnéd letiltani minden oldalon, már nem lesz szükséged kiegészítőre ehhez."
                },
                "id": {
                  "p1": "Kami memiliki beberapa pengumuman penting:",
                  "p2": "Instalasi baru akan memerlukan langganan berbayar untuk menggunakan daftar putih dan hitam (dan fitur lainnya yang akan datang). Namun, instalasi Anda saat ini akan tetap gratis.",
                  "p3": "Versi terbaru dari Chrome dan Edge kini mendukung penonaktifan gesekan secara native. Pengaturan ini dapat ditemukan di tab Aksesibilitas. Jika yang Anda inginkan hanya menonaktifkan gerakan gesekan di semua situs, Anda tidak lagi memerlukan ekstensi untuk itu."
                },
                "it": {
                  "p1": "Abbiamo alcuni annunci importanti:",
                  "p2": "Le nuove installazioni richiederanno un abbonamento a pagamento per utilizzare le liste bianche e nere (e altre funzionalità future). Tuttavia, l'installazione attuale rimarrà gratuita.",
                  "p3": "Le versioni più recenti di Chrome e Edge ora supportano la disabilitazione dei gesti di scorrimento in modo nativo. Questa impostazione può essere trovata nella scheda Accessibilità. Se tutto ciò che desideri è disabilitare i gesti di scorrimento su tutti i siti, non avrai più bisogno dell'estensione per farlo."
                },
                    "ja": {
                      "p1": "重要なお知らせがあります：",
                      "p2": "新しいインストールには、ホワイトリストとブラックリスト（および今後の機能）を使用するための有料サブスクリプションが必要です。ただし、現在のインストールは引き続き無料で使用できます。",
                      "p3": "最近のChromeおよびEdgeのバージョンでは、ネイティブでスワイプ動作を無効にすることがサポートされています。この設定はアクセシビリティタブにあります。すべてのサイトでスワイプジェスチャーを無効にしたいだけであれば、もう拡張機能は必要ありません。"
                    },
                    "kn": {
                      "p1": "ನಮಗೆ ಕೆಲವು ಮಹತ್ವಪೂರ್ಣ ಘೋಷಣಗಳು ಇದ್ದವೆ:",
                      "p2": "ಹೊಸ ಇನ್‌ಸ್ಟಾಲ್‌ಗಳನ್ನು ಸೈಟ್‌ಗಳ ವೈಟ್ ಮತ್ತು ಬ್ಲ್ಯಾಕ್ ಲಿಸ್ಟ್‌ಗಳನ್ನು (ಮತ್ತು ಇತರ आने वाले ವೈಶಿಷ್ಟ್ಯಗಳನ್ನು) ಬಳಸಲು ಪಾವತಿಸಬಹುದಾದ ಸಬ್ಸ್ಕ್ರಿಪ್ಷನ್‌ ಅಗತ್ಯವಿದೆ. ಆದರೆ, ನಿಮ್ಮ ಪ್ರಸ್ತುತ ಇನ್‌ಸ್ಟಾಲೇಶನ್ ನಂಬಿಕಾರಿರಿಯಂತೆ ಉಚಿತವಾಗಿರುತ್ತದೆ.",
                      "p3": "ಇತ್ತೀಚಿನ Chrome ಮತ್ತು Edge ಆವೃತ್ತಿಗಳು ಈಗ ಸ್ವೈಪಿಂಗ್‌ಗೆ ನೇಟಿವ್ ಆಗಿ ಅವಧಿಯನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲು ಬೆಂಬಲಿಸುತ್ತವೆ. ಈ ಸೆಟ್ಟಿಂಗ್‌ ಅನ್ನು Accessibility ಟ್ಯಾಬ್‌ನಲ್ಲಿ ಕಾಣಬಹುದು. ನೀವು ಎಲ್ಲಾ ಸೈಟ್‌ಗಳಲ್ಲಿ ಸ್ವೈಪ್ ಚಲನೆಗಳನ್ನು ನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲು ಬಯಸಿದರೆ, ನಿಮಗೆ ಈಗ ಅದಕ್ಕಾಗಿ ಇಕ್ಸ್ಟೆಂಶನ್ ಅವಶ್ಯಕವಿಲ್ಲ."
                    },
                    "ko": {
                      "p1": "중요한 공지가 있습니다:",
                      "p2": "새로운 설치에는 화이트리스트와 블랙리스트(및 다른 예정된 기능)를 사용하기 위해 유료 구독이 필요합니다. 그러나 현재 설치는 계속 무료로 제공됩니다.",
                      "p3": "최근 Chrome 및 Edge 버전은 이제 기본적으로 스와이프 기능을 비활성화할 수 있습니다. 이 설정은 접근성 탭에서 찾을 수 있습니다. 모든 사이트에서 스와이프 제스처만 비활성화하고 싶다면 더 이상 확장 프로그램이 필요하지 않습니다."
                    },
                    "lt": {
                      "p1": "Turime keletą svarbių pranešimų:",
                      "p2": "Naujos įdiegimo versijos reikalaus apmokamo prenumeratos naudojimui baltųjų ir juodųjų sąrašų (ir kitų artėjančių funkcijų). Tačiau jūsų dabartinis įdiegimas liks nemokamas.",
                      "p3": "Naujausios „Chrome“ ir „Edge“ versijos dabar palaiko galimybę išjungti braukimo gestus tiesiogiai. Šis nustatymas yra pasiekiamas pasiekiamumo skiltyje. Jei norite tik išjungti braukimo gestus visose svetainėse, jums nebereikės papildinio."
                    },
                    "lv": {
                      "p1": "Mums ir daži svarīgi paziņojumi:",
                      "p2": "Jaunām instalācijām būs nepieciešama maksas abonēšana baltā un melnā saraksta (un citu nākotnes funkciju) izmantošanai. Tomēr jūsu pašreizējā instalācija turpinās būt bezmaksas.",
                      "p3": "Jaunākās Chrome un Edge versijas tagad atbalsta slīdēšanas izslēgšanu tieši. Šī iestatījuma var atrast piekļuves iestatījumos. Ja vienīgais, ko vēlaties, ir izslēgt slīdēšanas žestus visās vietnēs, jums vairs nav nepieciešama paplašinājuma."
                    },
                    "ml": {
                      "p1": "ഞങ്ങളോട് ചില പ്രധാന പ്രഖ്യാപനങ്ങൾ ഉണ്ട്:",
                      "p2": "പുതിയ ഇൻസ്റ്റലേഷനുകൾക്ക് വൈറ്റ് ലിസ്റ്റ്, ബ്ലാക്ക് ലിസ്റ്റ് (മറ്റുള്ളവയുടെ കൂടെ) ഉപയോഗിക്കാൻ പണം കൊടുത്ത സബ്‌സ്‌ക്രിപ്ഷൻ ആവശ്യമാണ്. എങ്കിലും, നിലവിലെ ഇൻസ്റ്റലേഷൻ ഫ്രീ ആയിരിക്കും.",
                      "p3": "ഇന്നത്തെ Chrome, Edge വേർഷനുകൾ ഇപ്പോൾ സ്വൈപ്പ് അണിയുന്നത് നാട്ടു വഴി പിന്തുണയ്ക്കുന്നു. ഈ സെറ്റിംഗ് ആക്സസിബിലിറ്റി ടാബിൽ കണ്ടെത്താവുന്നതാണ്. നിങ്ങൾക്ക് എല്ലാ വെബ്സൈറ്റുകളിലും സ്വൈപ്പ് തന്ത്രങ്ങൾ നീക്കം ചെയ്യണമെന്ന് മാത്രം ആഗ്രഹിച്ചാൽ, ഇനി എക്സ്റ്റെൻഷനിന്റെ ആവശ്യമില്ല."
                    },
                    "mr": {
                      "p1": "आमच्याकडे काही महत्त्वाची घोषणाएँ आहेत:",
                      "p2": "नवीन स्थापना करण्यासाठी श्वेतसूची आणि कृष्णसूची (आणि इतर आगामी वैशिष्ट्ये) वापरण्यासाठी एक सशुल्क सदस्यता आवश्यक असेल. तथापि, आपली सध्याची स्थापना मोफत राहील.",
                      "p3": "नवीनतम Chrome आणि Edge आवृत्त्यांमध्ये आता स्वाइपिंगला नैतिक पद्धतीने अक्षम करण्याचे समर्थन आहे. या सेटिंगला ऍक्सेसिबिलिटी टॅबमध्ये शोधता येईल. जर तुम्हाला सर्व साइट्सवर स्वाइप गेस्टर्स निष्क्रिय करायचे असतील, तर तुम्हाला त्यासाठी आता एक्सटेंशनची आवश्यकता नाही."
                    },
                    "ms": {
                      "p1": "Kami mempunyai beberapa pengumuman penting:",
                      "p2": "Pemasangan baru akan memerlukan langganan berbayar untuk menggunakan senarai putih dan senarai hitam (dan ciri-ciri lain yang akan datang). Walau bagaimanapun, pemasangan semasa anda akan terus percuma.",
                      "p3": "Versi terkini Chrome dan Edge kini menyokong penonaktifan isyarat sapuan secara asli. Tetapan ini boleh didapati di tab Aksesibiliti. Jika yang anda mahu hanyalah untuk mematikan isyarat sapuan di semua laman, anda tidak lagi memerlukan sambungan untuk itu."
                    },
                    "nl": {
                      "p1": "We hebben enkele belangrijke aankondigingen:",
                      "p2": "Nieuwe installaties vereisen een betaald abonnement voor het gebruik van de witte en zwarte lijsten (en andere komende functies). Je huidige installatie blijft echter gratis.",
                      "p3": "Recente versies van Chrome en Edge ondersteunen nu het uitschakelen van swipen op native wijze. Deze instelling is te vinden onder het tabblad Toegankelijkheid. Als je alleen swipe-bewegingen op alle sites wilt uitschakelen, heb je de extensie daar niet meer voor nodig."
                    },
                    "no": {
                      "p1": "Vi har noen viktige kunngjøringer:",
                      "p2": "Nye installasjoner vil kreve et betalt abonnement for å bruke hvite- og svarte lister (og andre kommende funksjoner). Din nåværende installasjon vil derimot forbli gratis.",
                      "p3": "Nyeste versjoner av Chrome og Edge støtter nå å deaktivere sveipebevegelser nativt. Denne innstillingen finnes under Tilgjengelighet-fanen. Hvis alt du ønsker er å deaktivere sveipebevegelser på alle nettsteder, trenger du ikke lenger utvidelsen for det."
                    },
                    "pl": {
                      "p1": "Mamy kilka ważnych ogłoszeń:",
                      "p2": "Nowe instalacje będą wymagały płatnej subskrypcji, aby używać białych i czarnych list (oraz innych nadchodzących funkcji). Twoja obecna instalacja pozostanie jednak darmowa.",
                      "p3": "Najnowsze wersje Chrome i Edge teraz wspierają wyłączanie gestów przesuwania natywnie. Ustawienie to znajduje się w zakładce Dostępność. Jeśli chcesz tylko wyłączyć gesty przesuwania na wszystkich stronach, nie będziesz już potrzebować rozszerzenia do tego celu."
                    },  
                        "pt": {
                          "p1": "Temos alguns anúncios importantes:",
                          "p2": "Novas instalações exigirão uma assinatura paga para usar as listas brancas e negras (e outras funcionalidades futuras). No entanto, sua instalação atual continuará gratuita.",
                          "p3": "As versões mais recentes do Chrome e Edge agora suportam a desativação do gesto de deslizar nativamente. Esta configuração pode ser encontrada na aba Acessibilidade. Se você deseja apenas desativar os gestos de deslizar em todos os sites, não precisará mais da extensão para isso."
                        },
                        "ro": {
                          "p1": "Avem câteva anunțuri importante:",
                          "p2": "Noile instalări vor necesita un abonament plătit pentru a utiliza listele albe și negre (și alte caracteristici viitoare). Cu toate acestea, instalarea dumneavoastră curentă va rămâne gratuită.",
                          "p3": "Versiunile recente ale Chrome și Edge acceptă acum dezactivarea gesturilor de derulare nativ. Această setare poate fi găsită în fila Accesibilitate. Dacă doriți doar să dezactivați gesturile de derulare pe toate site-urile, nu mai aveți nevoie de extensie pentru asta."
                        },
                        "ru": {
                          "p1": "У нас есть несколько важных объявлений:",
                          "p2": "Новые установки потребуют платной подписки для использования белого и черного списков (и других предстоящих функций). Однако ваша текущая установка останется бесплатной.",
                          "p3": "Недавние версии Chrome и Edge теперь поддерживают отключение свайпов нативно. Эта настройка находится в разделе доступности. Если вам нужно просто отключить жесты свайпа на всех сайтах, вам больше не нужно расширение для этого."
                        },
                        "sk": {
                          "p1": "Máme niekoľko dôležitých oznámení:",
                          "p2": "Nové inštalácie budú vyžadovať platené predplatné na používanie bielych a čiernych zoznamov (a ďalších pripravovaných funkcií). Vaša súčasná inštalácia však zostane zadarmo.",
                          "p3": "Novšie verzie prehliadačov Chrome a Edge teraz podporujú natívne vypnutie gest pri prekladaní. Toto nastavenie nájdete v záložke Prístupnosť. Ak chcete iba vypnúť gesto posúvania na všetkých stránkach, už nebudete potrebovať túto rozšírenie."
                        },
                        "sl": {
                          "p1": "Imamo nekaj pomembnih obvestil:",
                          "p2": "Nove namestitve bodo zahtevale plačano naročnino za uporabo bele in črne liste (in drugih prihajajočih funkcij). Vaša trenutna namestitev pa bo še vedno brezplačna.",
                          "p3": "Najnovejše različice Chrome in Edge zdaj podpirajo onemogočanje pomikanja na naraven način. To nastavitev lahko najdete v zavihku Dostopnost. Če želite le onemogočiti geste pomikanja na vseh spletnih straneh, razširitve za to več ne boste potrebovali."
                        },
                        "sr": {
                          "p1": "Imamo nekoliko važnih obaveštenja:",
                          "p2": "Nove instalacije će zahtevati plaćenu pretplatu za korišćenje belih i crnih listi (i drugih budućih funkcija). Međutim, vaša trenutna instalacija će i dalje biti besplatna.",
                          "p3": "Nedavne verzije Chrome i Edge sada podržavaju isključivanje swipe gestova nativno. Ova opcija se nalazi u kartici za pristupačnost. Ako želite samo da isključite swipe geste na svim sajtovima, više vam neće biti potrebna ekstenzija za to."
                        },
                        "sv": {
                          "p1": "Vi har några viktiga meddelanden:",
                          "p2": "Nya installationer kommer att kräva ett betalt abonnemang för att använda vita och svarta listor (och andra kommande funktioner). Din nuvarande installation kommer dock att fortsätta vara gratis.",
                          "p3": "De senaste versionerna av Chrome och Edge stöder nu att inaktivera svepning nativt. Denna inställning finns under fliken Tillgänglighet. Om allt du vill göra är att inaktivera svepgesten på alla webbplatser, behöver du inte längre tillägget för det."
                        },
                        "sw": {
                          "p1": "Tunayo matangazo muhimu:",
                          "p2": "Mizizi mpya itahitaji usajili wa kulipia ili kutumia orodha nyeupe na nyeusi (na vipengele vingine vinavyokuja). Walakini, usakinishaji wako wa sasa utaendelea kuwa bure.",
                          "p3": "Matoleo mapya ya Chrome na Edge sasa yanaunga mkono kuzima upigaji makaratasi kwa asili. Hii inaweza kupatikana chini ya kichupo cha Upatikanaji. Ikiwa unataka tu kuzima michomo ya kupiga mikono kwenye tovuti zote, hutahitaji tena nyongeza kwa hilo."
                        },
                        "ta": {
                          "p1": "நாம் சில முக்கிய அறிவிப்புகளை வழங்குகிறோம்:",
                          "p2": "புதிய நிறுவல்களுக்கு வெள்ளை மற்றும் கருப்பு பட்டியல்கள் (மேலும் வரும் அம்சங்கள்) பயன்படுத்த பத்தொறுக்கும் சந்தா தேவையாக இருக்கும். எனினும், உங்கள் தற்போதைய நிறுவல் தொடர்ந்து இலவசமாக இருக்கும்.",
                          "p3": "சமீபத்திய Chrome மற்றும் Edge பதிப்புகள் தற்போது மாறுபட்ட செயல்களை இயல்பாக முடக்க ஆதரவு அளிக்கின்றன. இந்த அமைப்பு அணுகல் சிறப்பு பதிவில் கிடைக்கும். நீங்கள் அனைத்து இணையதளங்களிலும் பாய்ச்சல் செரிக்களை முடக்க விரும்பினால், இதற்கான நீட்சியை இனி தேவையில்லை."
                        },
                        "te": {
                          "p1": "మాకు కొన్ని ముఖ్యమైన ప్రకటనలు ఉన్నాయి:",
                          "p2": "కొత్త ఇన్‌స్టాలేషన్ల కోసం వైట్ మరియు బ్లాక్ లిస్ట్‌లు (మరియు ఇతర రాబోయే ఫీచర్లను) ఉపయోగించడానికి చెల్లింపు సభ్యత్వం అవసరం. అయినప్పటికీ, మీ ప్రస్తుత ఇన్‌స్టాలేషన్ ఉచితంగా కొనసాగుతుంది.",
                          "p3": "ఇటీవలి Chrome మరియు Edge వెర్షన్లు ఇప్పుడు స్వైప్ చేయడాన్ని నేటివ్‌గా ఆపడానికి మద్దతు ఇస్తున్నాయి. ఈ సెట్టింగ్‌ని యాక్ససిబిలిటీ టాబ్‌లో కనుగొనవచ్చు. మీరు అన్ని వెబ్‌సైట్లలో స్వైప్ జెస్టర్స్‌ను మాత్రమే ఆపాలనుకుంటే, దానికి ఇకపై ఎక్స్‌టెన్షన్ అవసరం లేదు."
                        },
                        "th": {
                          "p1": "เรามีประกาศสำคัญบางประการ:",
                          "p2": "การติดตั้งใหม่จะต้องสมัครสมาชิกแบบชำระเงินเพื่อใช้รายชื่อขาวและดำ (และฟีเจอร์อื่น ๆ ที่จะมาถึง) อย่างไรก็ตาม การติดตั้งของคุณในปัจจุบันจะยังคงเป็นฟรีต่อไป",
                          "p3": "เวอร์ชันล่าสุดของ Chrome และ Edge ตอนนี้รองรับการปิดการใช้งานการปัดนิ้วโดยเนทีฟ วิธีการตั้งค่านี้สามารถพบได้ในแท็บการเข้าถึง หากคุณต้องการเพียงแค่ปิดการใช้งานการปัดนิ้วบนเว็บไซต์ทั้งหมด คุณไม่ต้องการส่วนขยายอีกต่อไป"
                        },
                        "tr": {
                          "p1": "Bazı önemli duyurularımız var:",
                          "p2": "Yeni kurulumlar, beyaz ve siyah liste (ve diğer gelecek özellikler) kullanmak için ücretli bir abonelik gerektirecektir. Ancak mevcut kurulumunuz ücretsiz olarak devam edecektir.",
                          "p3": "Son Chrome ve Edge sürümleri, artık kaydırma hareketlerini yerel olarak devre dışı bırakmayı destekliyor. Bu ayar, Erişilebilirlik sekmesinde bulunabilir. Eğer tek yapmak istediğiniz şey, tüm sitelerde kaydırma hareketlerini devre dışı bırakmaksa, bunun için artık uzantıya ihtiyacınız yok."
                        },
                        "uk": {
                          "p1": "У нас є кілька важливих оголошень:",
                          "p2": "Нові інсталяції вимагатимуть платну підписку для використання білих і чорних списків (та інших майбутніх функцій). Однак ваша поточна інсталяція залишатиметься безкоштовною.",
                          "p3": "Останні версії Chrome та Edge тепер підтримують вимкнення свайпів на рідному рівні. Цю настройку можна знайти на вкладці Доступність. Якщо ви хочете лише вимкнути жести свайпу на всіх сайтах, вам більше не потрібне розширення для цього."
                        },
                        "vi": {
                          "p1": "Chúng tôi có một số thông báo quan trọng:",
                          "p2": "Cài đặt mới sẽ yêu cầu đăng ký trả phí để sử dụng danh sách trắng và đen (và các tính năng sắp tới). Tuy nhiên, cài đặt hiện tại của bạn sẽ vẫn miễn phí.",
                          "p3": "Các phiên bản gần đây của Chrome và Edge hiện đã hỗ trợ tắt cử chỉ vuốt bản địa. Cài đặt này có thể tìm thấy trong tab Truy cập. Nếu bạn chỉ muốn tắt cử chỉ vuốt trên tất cả các trang web, bạn sẽ không còn cần tiện ích mở rộng này nữa."
                        },
                        "zh_CN": {
                          "p1": "我们有一些重要的公告：",
                          "p2": "新的安装将需要付费订阅以使用白名单和黑名单（以及其他即将推出的功能）。但是，您当前的安装将继续保持免费。",
                          "p3": "最新版本的Chrome和Edge现在支持本地禁用滑动手势。此设置可以在可访问性标签下找到。如果您只想禁用所有站点上的滑动手势，您不再需要扩展程序了。"
                        },
                        "zh_TW": {
                          "p1": "我們有一些重要的公告：",
                          "p2": "新的安裝將需要付費訂閱以使用白名單和黑名單（以及其他即將推出的功能）。然而，您當前的安裝將繼續免費。",
                          "p3": "最新版本的Chrome和Edge現在支持本地禁用滑動手勢。此設定可以在無障礙選項卡下找到。如果您只想禁用所有網站上的滑動手勢，您不再需要此擴展程式。"
                        }
                                                            
    }
            
    const { browser, lang } = useParams();
    let url ="https://chromewebstore.google.com/detail/image-downloader-save-all/fpelahbljekknahkcaegamhcndkfpfnc";
    if(browser=="edge"){
        url ="https://microsoftedge.microsoft.com/addons/detail/image-downloader-save-a/flbolonkboilcbnohjlchlfgdalomdpl"
    }else if(browser=="opera"){
        url="opera"
    }
    let header = headers[lang];
    let appName =appNameTrans[lang]["appName"]["message"];
    let p1 = text[lang]['p1'];
    let p2 = text[lang]['p2'];
    let p3 = text[lang]['p3'];
    let p4 = text[lang]['p4'];
    let p5 = text[lang]['p5'];
    let p6 = text[lang]['p6'];
    let enLink = "../no_swipe-rate_us/"+browser+"/en";
        return(
            <>
        <div  style={{backgroundImage:"URL(/../assets/meercat-download-images.jpeg)", width:"1024px", height:"1024px"}}>
            {lang=='en'?"":<NavLink to= {enLink}><img src="/../assets/en.svg" style={{float:"right", width:"44px", margin:"24px 12px"}} /></NavLink>}
            <div style={{color:"white",margin:"24px,8px,24px,8px"}}>
            <div>
                <img src="/assets/NoSwipe16T.png" height={"16px"} ></img> <h2 style={{paddingTop:"0px",color:"white",display:"inline-block"}}>{appName}</h2>
</div>
<h2 style={{backgroundColor:"rgb(0,0,0,0.6)",color:"white",padding:'2px,8px, 2px'}}>

    <div style={{paddingLeft:'8px', paddingTop:'0px',paddingBottom:'2px'}}><p style={{marginTop:'0'}}></p>

<div>
<h2 style={{paddingTop:"24px",color:"white"}}>{header}</h2>

</div>
    {p1}
        <ol>
            <li>
                {p2}
                </li>
                <li>
                {p3}
                </li>
            </ol>
        </div>
</h2>
</div>
        </div>
        </>
        )
}


