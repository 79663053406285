import Header from "../../Header.js";
import Limitaions from "../Limitations.js"
import OperaAUsers from "../OperaUsers.js";
import PinExtension from "../PinExtension.js";
import SupportUS from "../SupportUs.js";

export default function PrintWithoutImages(props){
    return(
        <>
        <Header title="Print without images button" x="30"/>
        <div className="article">
        Save ink! replace all images with white blanks before printing.<br/><br/>

This extension is designed for quick use. It features a simple button with no customization options. The extension replaces all images on a web page with blank placeholders, rather than removing them entirely. This prevents layout issues while saving ink when printing.

<br/><br/>
Available for: <a href="https://chromewebstore.google.com/detail/print-without-images-butt/bkhgnaijpkfmmdnhefolnpnjgllkgbmk" className="button-link">Chrome</a>&nbsp;
 <a href="https://microsoftedge.microsoft.com/addons/detail/print-without-images-butt/cicgipmnmfpghkepflobccobfdejmobg" className="button-link">Edge</a>&nbsp;
 <a href="https://addons.mozilla.org/en-US/firefox/addon/print-without-images-button/" className="button-link">Firefox</a> . 
        </div>
            <Limitaions />
            <PinExtension lang="en" />
            <OperaAUsers />
            <SupportUS />
        </>
        )
}