import { HashLink as Link } from 'react-router-hash-link';
import PinExtension from "../PinExtension.js";
import SupportUS from "../SupportUs.js";
import Header from '../../Header.js';
import { useEffect } from 'react';
import { useHash } from '../../UseHash';
import { scrollToSection } from '../../Utils';

import './styles.css';

export default function ImageDownloader(props){
    document.title = "Image downloader";
    const hash = useHash();
  useEffect(() => {
    const section = hash.replace("#", "");
    if (section) scrollToSection(section);
  }, [hash]);
    return(
        <>
        <Header title="Image Downloader" x="20"/>
        <div id="i-d-quick-nav">
                <b>
                    <ul style={{listStyleType:'none', margin: '0 0 0 0', padding: '0 0 0 0'}}>
                        <li><a href="#limitations">Limitations</a></li>
                        <li><a href="#check-settings">Check browser settings</a></li>
                        <li><a href="#pin-extension-icon">Pin extension icon</a></li>
                        <li><a href="#tutorial">User guide</a></li>
                        <li><a href="#troubleshooting">Troubleshooting</a></li>
                        <li><a href="#support-us">Support us</a></li>
                    </ul>
                </b>
            </div>
        <div className="article">
        <p><b>Quickly find and download images from web pages in bulk</b></p>
        <p>
You can filter the images based on image characteristics.<br/>
You can select which image to download or download all images that pass filtering.<br/>
You can choose a custom folder for the images and a custom file name sequence.</p>
<p>
<b>Main features:</b>
<br/>
<ul>
    <li>Very quick and clean group display of all images.</li>
    <li>Download a single, multiple or all images in the web page.</li>
    <li>Filter images by: width, height, type, orientation and aspect ratio or URL.</li>
    <li>Custom or auto generated folder and file names.</li>
    <li>Auto convert files to JPEG, PNG or WebP.</li>
    <li>Side panel support.</li>
    </ul></p>
Available for: <a href="https://chromewebstore.google.com/detail/image-downloader-save-all/fpelahbljekknahkcaegamhcndkfpfnc" className="button-link">Chrome</a> &nbsp;
 <a href="https://microsoftedge.microsoft.com/addons/detail/image-downloader-save-a/flbolonkboilcbnohjlchlfgdalomdpl" className="button-link">Edge</a>  &nbsp;
 <a href="https://addons.mozilla.org/en-US/firefox/addon/image-downloader-for-firefox/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search" className="button-link">Firefox</a>
        </div>
<div id="limitations" className="main-title">
    <p>Limitations</p>
</div>
<div className="article">
    <ul>
        <li>(All) extensions do not work on add-on download sites such as the Chrome Web Store and non-URL sites such as the first page when you open a new tab.</li>
        <li>Usually, only images that are loaded prior to using the extension can be detected.</li>
        <li>If a site uses a thumbnail, you are most likely not able to capture the larger image.</li>
        <li>Some sites employ special techniques that prevent the detection and download of images.</li>
        <li>Before using the Image Downloader extension, follow the instructions below to ensure that the browser settings won't interfere.</li>
    </ul>
</div>
            <div id="check-settings" className="main-title"><p>Check browser settings</p></div>
        <div className="article">
            On <b>Chrome</b> and <b>Edge</b> go to <b>settings -> downloads</b>, make sure that <b>"Ask where to save each file before downloading"</b> is turned <b>off</b>.
            <br/><img id="i-d-check" src="../assets/checkBrowser.png" style={{marginTop:"24px"}}/><br/>
            On <b>Firefox</b> go to <b>settings -> general -> files and applications</b>, make sure that <b>"Always ask you where to save files"</b> is <b>unchecked</b>.
        </div>
        <PinExtension lang="en" />
        <div id="tutorial" className="main-title"><p>User guide</p></div>
        <div className="article">
        <p>To use the Image Downloader press the extension icon, this will open the popup window and start scanning the web page for images.
The popup menu is divided into 3 main parts: the main menu, the submenu and the results. </p><img src="../assets/image downloader 1.png" /> <br/>
Use the menu buttons to change the submenu being displayed. Use the save icon <img src="../assets/save-icon.png" /> in the submenu to save 
the settings of that submenu (only of that submenu). 
<p>You can select one or more images by clicking on them in the result part. Notice that large images have this <img src="../assets/new-tab.svg" width="20px" /> "open in a new tab" icon. 
To download your selected images click the download selected 
    images icon <img src="../assets/download-selected.png" />. To download all the images in the result part press the download all
     icon <img src="../assets/download-all.svg" width="20px" />.
    </p> The last icon in the main menu <img src="../assets/hamburger.svg" width="20px" /> opens a dropdown menu there you will find, among others, a link to this guide.
    <p><h3 className='submenu'>Size submenu</h3></p> This submenu is opened by default, use it to filter images by pixel width and height. You can use the <b>S M L A</b> buttons to quickly set the filter settings to show <b>S</b>mall, <b>M</b>edium, <b>L</b>arge, or <b>A</b>ll images.
            Leave the max values empty if you don't want to set an upper limit.
            <p><h3 className='submenu'>Type submenu</h3></p> Here you set the file extensions you want to filter.
            <p><h3 className='submenu'>Layout submenu</h3></p> You can use this submenu to filter images based on orientation by pressing one of the left buttons, or more specifically by width to height ratio by changing the sign and value in the input box. Leave the ratio empty to show all ratios (or just press the "All" button).
            <p><h3 className='submenu'>URL submenu</h3></p>Image downloader lets you filter images based on partial URL matches. You can choose to include or exclude images that their URL matches the terms in this submenu. 
            To disable this filter, leave the URL field empty, you can press the clean icon <img src="../assets/trash.svg" width="20px" /> to delete the URLs.
            You can use multiple terms to match a URL by separating them with a semicolon <b>without space</b>. for example: wikipedia;google;pixabay.
            <p><h3 className='submenu'>Advanced submenu</h3></p>Some sites have alternative versions of the image they display, usually it is the same image in different sizes. Here you can choose which version to capture.
             By default it is the largest version that passes your other filters. Other options include capturing the smallest one, all of them or all that have unique dimensions.
             <p><h3 className='submenu' id="save-as"><img src="../assets/save config.svg" width="30px" /> (Save config) submenu</h3></p>Here you can set the folders, file names, and auto-conversion of file formats.<p>All folders are saved in your downloads folder. 
                By default each time you download an image the extension generates an increasing number as a folder name, made from the date and time. 
                To set the folder manually change the radio button to the custom field.
                Leave it empty to save directly to your downloads folder or set a custom name, you can also set a sub folder.</p> 
                <p>Depending on your settings file names will be generated automatically or with a custom prefix name followed by an auto generated number. If you set the prefix to cat, then upon downloading 3 file, the names of the files will be: cat1, cat2, cat3.
                    </p><p>To set the auto format converter, press the convert icon <img src="../assets/convarte.svg" width="36px" />. In the pop-up window that opens, choose the file format to convert to. You can convert to JPEG, PNG, or WebP. For JPEG and WebP, you can also set the quality between 1 and 10, with 10 being the highest quality and 1 being the smallest file size. PNG is a lossless format, so the quality is always set to 10.
                </p>
                <p><h3 className='submenu'><img src="../assets/display.svg" width="24px" /> (Display) submenu</h3></p> You can set here the following:<br/><ul>
                <li>
                <img src="../assets/popup.svg" width="32px" />&nbsp;<img src="../assets/side-panel.svg" width="32px" />&nbsp;If the extension will be opened in a popup or as a side panel (not availble in Firefox)                    
                </li>
                    <li>
                    <img src="../assets/result-width.svg" width="32px" />&nbsp;The max width of the image in the result area (make it smaller to fit more images in the visible area at any given time).
                    </li>
                    <li>
                        <img src="../assets/theme.png"/>&nbsp;The theme.
                    </li>
                    <li>
                        <img src="../assets/language.svg" width="32px" />&nbsp;The user interface language.
                    </li>
                    </ul>
        </div>
        <div id="troubleshooting" className="main-title">
    <p>Troubleshooting</p>
</div>

<div className="article">
    <div>
        <h3 className='submenu'>Extension failed to capture the images</h3>
        <p>
            Refresh the page, wait until the images are loaded on the page, then open the extension. If it still fails, it is possible your filter is set too strict or that the site employs techniques that prevent image detection. See if you can capture images on a different site.
        </p>
    </div>
    
    <div>
        <h3 className='submenu'>Can't set the name prefix</h3>
        <p>
        This is probably due to a conflict with another later installed extension. try to disable and then enable the image downloader extension.
        </p>
    </div>

    <div>
        <h3 className='submenu'>Browser prompts you to manually save every image</h3>
        <p>
            Follow the <Link to="#check-settings"><span className="button-link">Check browser settings</span></Link> section instructions.
        </p>
    </div>

    <div>
        <h3 className='submenu'>Unable to use in private mode</h3>
        <p>
            Browsers require users to explicitly allow extensions to work in private (incognito) mode. Here are Instructions on how to set the extension in private mode for:&nbsp; 
            <a href="https://support.google.com/chrome/a/answer/13130396?hl=en" className="button-link">Chrome</a>&nbsp;
            <a href="https://www.tenforums.com/tutorials/166235-allow-extensions-inprivate-browsing-microsoft-edge-chromium.html" className="button-link">Edge</a>&nbsp;
            <a href="https://support.mozilla.org/en-US/kb/extensions-private-browsing" className="button-link">Firefox</a>
        </p>
    </div>

    <div>
        <h3><b>If you encounter an issue, you can always <Link to="/en/contact"><span className="button-link">contact</span></Link> us for help.</b></h3>
    </div>
</div>
        <img src="../assets/rate-us.png" style={{paddingTop:'48px',paddingLeft:'16px', float: 'right'}}/>

            <SupportUS />
        </>
        )
}{/*ToDo:     
    theme icon
    side panel
    translate
    convartion popup-menu    
    */}