import { useState } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore"; 
import { emailPass } from "./Utils.js";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import Lang from './Lang';

export default function ContactGeneral(props){
    document.title = "Suricata Software - Contact";

    const {language} = useParams();
    let lang = language;
    const locals = ['pt','es','fr','de','it','hu','tr','ru','he','vi','th','ko','zh_CN','zh_TW','ja'];                
    if(!locals.includes(lang)){
      lang='en';
    }
    
    const [subject, setSubject] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [body, setBody] = useState("");
    const navigate = useNavigate();
    const submit = async (event) =>{
        event.preventDefault();
        if(emailPass(email)){
            const docRef = await addDoc(collection(props.db, "emails"), {
                message: {
                    subject: subject,
                    html: email +"\nname: "+name+"\n\n"+body,
                }
            });
            navigate("/post-contact/"+lang);
        }
    }

const txt = {
    "en": ["We want to hear from you...", "Contact us", "Subject", "Feature request", "Bug report", "Help me", "Other", "Name", "Your name", "Email", "Your email", "What's on your mind?", "Hi...", "Submit"],
    "pt": ["Queremos ouvir você...", "Fale conosco", "Assunto", "Solicitação de recurso", "Relatar um erro", "Ajude-me", "Outro", "Nome", "Seu nome", "E-mail", "Seu e-mail", "O que está em sua mente?", "Oi...", "Enviar"],
    "es": ["Queremos saber de ti...", "Contáctanos", "Asunto", "Solicitud de función", "Informe de error", "Ayúdame", "Otro", "Nombre", "Tu nombre", "Correo electrónico", "Tu correo electrónico", "¿En qué estás pensando?", "Hola...", "Enviar"],
    "fr": ["Nous voulons avoir de vos nouvelles...", "Contactez-nous", "Objet", "Demande de fonctionnalité", "Rapport de bug", "Aidez-moi", "Autre", "Nom", "Votre nom", "E-mail", "Votre e-mail", "Qu'avez-vous en tête ?", "Salut...", "Envoyer"],
    "de": ["Wir möchten von Ihnen hören...", "Kontaktieren Sie uns", "Betreff", "Funktionsanfrage", "Fehlermeldung", "Hilf mir", "Andere", "Name", "Ihr Name", "E-Mail", "Ihre E-Mail", "Was haben Sie auf dem Herzen?", "Hallo...", "Senden"],
    "it": ["Vogliamo sentire la tua opinione...", "Contattaci", "Oggetto", "Richiesta di funzionalità", "Segnalazione di bug", "Aiutami", "Altro", "Nome", "Il tuo nome", "Email", "La tua email", "Cosa hai in mente?", "Ciao...", "Invia"],
    "hu": ["Szeretnénk hallani rólad...", "Lépjen kapcsolatba velünk", "Tárgy", "Funkció kérése", "Hibajelentés", "Segítség", "Egyéb", "Név", "A neved", "E-mail", "Az e-mailed", "Mi jár a fejedben?", "Szia...", "Küldés"],
    "tr": ["Sizden haber almak istiyoruz...", "Bize ulaşın", "Konu", "Özellik talebi", "Hata bildirimi", "Bana yardım et", "Diğer", "Ad", "Adınız", "E-posta", "E-postanız", "Aklınızda ne var?", "Merhaba...", "Gönder"],
    "ru": ["Мы хотим услышать вас...", "Свяжитесь с нами", "Тема", "Запрос функции", "Сообщить об ошибке", "Помогите мне", "Другое", "Имя", "Ваше имя", "Электронная почта", "Ваш email", "О чем вы думаете?", "Привет...", "Отправить"],
    "he": ["אנחנו רוצים לשמוע ממך...", "צור קשר", "נושא", "בקשת תכונה", "דיווח על באג", "עזור לי", "אחר", "שם", "שמך", "אימייל", "האימייל שלך", "על מה אתה חושב?", "היי...", "שלח"],
    "vi": ["Chúng tôi muốn nghe từ bạn...", "Liên hệ với chúng tôi", "Chủ đề", "Yêu cầu tính năng", "Báo cáo lỗi", "Giúp tôi", "Khác", "Tên", "Tên của bạn", "Email", "Email của bạn", "Bạn đang nghĩ gì?", "Chào...", "Gửi"],
    "th": ["เราต้องการรับฟังความคิดเห็นจากคุณ...", "ติดต่อเรา", "เรื่อง", "คำขอฟีเจอร์", "รายงานข้อบกพร่อง", "ช่วยฉัน", "อื่นๆ", "ชื่อ", "ชื่อของคุณ", "อีเมล", "อีเมลของคุณ", "คุณกำลังคิดอะไรอยู่?", "สวัสดี...", "ส่ง"],
    "ko": ["여러분의 의견을 듣고 싶습니다...", "문의하기", "제목", "기능 요청", "버그 신고", "도와주세요", "기타", "이름", "이름 입력", "이메일", "이메일 입력", "무슨 생각을 하고 계신가요?", "안녕하세요...", "제출"],
    "zh_CN": ["我们想听取您的意见...", "联系我们", "主题", "功能请求", "错误报告", "帮助我", "其他", "姓名", "您的姓名", "电子邮件", "您的电子邮件", "您在想什么？", "你好...", "提交"],
    "zh_TW": ["我們想聽聽您的意見...", "聯絡我們", "主題", "功能請求", "錯誤回報", "幫助我", "其他", "姓名", "您的姓名", "電子郵件", "您的電子郵件", "您在想什麼？", "嗨...", "提交"],
    "ja": ["あなたの声を聞かせてください...", "お問い合わせ", "件名", "機能リクエスト", "バグ報告", "助けて", "その他", "名前", "あなたの名前", "メール", "あなたのメール", "何を考えていますか？", "こんにちは...", "送信"]
  };
    return (
        <>
            <Lang />
            <div className="page-header"><p style={{color:"white"}}>{txt[lang][0]}</p></div>
            <div className="main-title"><p>{txt[lang][1]}</p></div>
            <div className="container">

                <form onSubmit={submit}>

                    <label htmlFor="subject">{txt[lang][2]}</label>
                    <select id="subject" name="subject" onChange={(e) => setSubject(e.target.value)}>
                        <option value="feature-request">{txt[lang][3]}</option>
                        <option value="bug-report">{txt[lang][4]}</option>
                        <option value="help">{txt[lang][5]}</option>
                        <option value="other">{txt[lang][6]}</option>
                    </select>

                    <label htmlFor="name">{txt[lang][7]}</label>
                    <input type="text" id="name" name="name" placeholder={txt[lang][8]} onChange={(e) => setName(e.target.value)} />

                    <label htmlFor="email">{txt[lang][9]}</label>
                    <input type="email" id="email" name="email" placeholder={txt[lang][10]} onChange={(e) => setEmail(e.target.value)} />

                    <label htmlFor="content">{txt[lang][11]}</label>
                    <textarea id="c-content" name="content" placeholder={txt[lang][12]} onChange={(e) => setBody(e.target.value)} ></textarea>

                    <input type="submit" value={txt[lang][13]} />

                </form>
                
            </div>
        </>
    )
}