import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';


export default function NoSwipeGetCode(props){

    return(
<>
  <div style={{ color: "white" }}>
    <h2>
    <p>A subscription is required in order to continue using the white and black lists after the trial period.</p>

    <p>A 1-year subscription costs $2.99/month × 12 months = $35.88. This includes a license for use on 4 browser installations.</p>
    <p>If you need a license for additional browsers, it will cost $1/month × 12 = $12 per installation (starting from the fifth installation).</p>

    <p><u>How to sign up?</u></p>
    <ol>
      <li>Contact us using the form in the link below and request a subscription. Be sure to provide the email address you'd like to use.</li>
      <li>You will receive instructions on where to send the payment via PayPal.</li>
      <li>Once we receive your payment (within 48 hours), we will send you the activation code.</li>
    </ol>

    <p><b style={{ color: "red" }}>Important: You must contact us and wait for our reply before sending any payments, even if you already know where to send the payment. This ensures we can confirm receipt and verify that we are available to process your subscription.</b></p>

    <NavLink to="../../en/contact">Contact us form link</NavLink>
    </h2>
  </div>
</>
    )
}