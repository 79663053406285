import { useParams } from "react-router-dom";

export default function ImageDownloaderAd(){
    const {browser,msgIndex,lang,numOfDownloads} = useParams();
    if(msgIndex == 102){
        window.location.replace('https://suricataart.etsy.com/il-en/listing/1871491368/handmade-epoxy-coaster-with-embedded');
    }else if(browser=="chrome"){
        window.location.replace('https://chromewebstore.google.com/detail/print-without-images-butt/bkhgnaijpkfmmdnhefolnpnjgllkgbmk');
    }else if(browser=="edge"){
        window.location.replace('https://microsoftedge.microsoft.com/addons/detail/print-without-images-butt/cicgipmnmfpghkepflobccobfdejmobg');
    }else{
        window.location.replace('https://addons.mozilla.org/en-US/firefox/addon/print-without-images-button/');
    }
    return(
        <></>
    )
}