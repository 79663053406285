import Header from "../../Header.js";
import Limitaions from "../Limitations.js"
import OperaAUsers from "../OperaUsers.js";
import PinExtension from "../PinExtension.js";
import SupportUS from "../SupportUs.js";

export default function Print(props){
    return(
        <>
        <Header title="Print! button" x="30"/>
        <div className="article">
        This extension is intended:
        <ul>
            <li>To be used on touch screens.</li>
            <li>To be used in situations where there isn't access to a keyboard.</li>
            <li>To provide a fast option for people that prefer to use a mouse.</li>
            <li>To increase accessibility.</li>
        </ul>

The extension does not change the print, you will get the same thing you will get if you press Ctrl+p or Cmd+p.
<br/><br/>
Available for: <a href="https://chromewebstore.google.com/detail/print-print-button/mhcepidlpdgdfcliacjbmiomdiilmhij" className="button-link">Chrome</a> and&nbsp;
 <a href="https://microsoftedge.microsoft.com/addons/detail/print-print-button/packoppfoicmljjmmjnhiigdeianmaog" className="button-link">Edge</a>. 
        </div>
            <Limitaions />
            <PinExtension lang="en" />
            <OperaAUsers />
            <SupportUS />
        </>
        )
}