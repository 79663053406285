
export default function PinExtension(props){
    const description = {
        "en": [
          "Pin extension icon",
          "In ",
          "Press the puzzle icon next to the address bar. Then press the pin icon next to the extension name.",
          "Press the puzzle icon next to the address bar. Then press the eye icon next to the extension name.",
          "Press the cube icon next to the address bar. Then press the pin icon next to the extension name",
          "Press the puzzle icon next to the address bar. Then press the gear icon next to the extension name. Choose \"Pin to Toolbar\"",
        ],
        "pt": [
              "Fixar ícone da extensão",
              "No ",
              "Pressione o ícone do quebra-cabeça ao lado da barra de endereços. Em seguida, pressione o ícone de alfinete ao lado do nome da extensão.",
              "Pressione o ícone do quebra-cabeça ao lado da barra de endereços. Em seguida, pressione o ícone de olho ao lado do nome da extensão.",
              "Pressione o ícone do cubo ao lado da barra de endereços. Em seguida, pressione o ícone de alfinete ao lado do nome da extensão.",
              "Pressione o ícone do quebra-cabeça ao lado da barra de endereços. Em seguida, pressione o ícone de engrenagem ao lado do nome da extensão. Escolha \"Fixar na barra de ferramentas\"."
            ],
            "es": [
              "Fijar icono de la extensión",
              "En ",
              "Presiona el ícono de rompecabezas junto a la barra de direcciones. Luego, presiona el ícono de pin junto al nombre de la extensión.",
              "Presiona el ícono de rompecabezas junto a la barra de direcciones. Luego, presiona el ícono de ojo junto al nombre de la extensión.",
              "Presiona el ícono de cubo junto a la barra de direcciones. Luego, presiona el ícono de pin junto al nombre de la extensión.",
              "Presiona el ícono de rompecabezas junto a la barra de direcciones. Luego, presiona el ícono de engranaje junto al nombre de la extensión. Elige \"Fijar en la barra de herramientas\"."
            ],
            "fr": [
              "Épingler l'icône de l'extension",
              "Dans ",
              "Appuyez sur l'icône en forme de puzzle à côté de la barre d'adresse. Ensuite, appuyez sur l'icône d'épingle à côté du nom de l'extension.",
              "Appuyez sur l'icône en forme de puzzle à côté de la barre d'adresse. Ensuite, appuyez sur l'icône en forme d'œil à côté du nom de l'extension.",
              "Appuyez sur l'icône en forme de cube à côté de la barre d'adresse. Ensuite, appuyez sur l'icône d'épingle à côté du nom de l'extension.",
              "Appuyez sur l'icône en forme de puzzle à côté de la barre d'adresse. Ensuite, appuyez sur l'icône en forme d'engrenage à côté du nom de l'extension. Choisissez \"Épingler dans la barre d'outils\"."
            ],
            "de": [
              "Erweiterungssymbol anheften",
              "In ",
              "Drücken Sie das Puzzle-Symbol neben der Adressleiste. Drücken Sie dann das Stecknadelsymbol neben dem Namen der Erweiterung.",
              "Drücken Sie das Puzzle-Symbol neben der Adressleiste. Drücken Sie dann das Augensymbol neben dem Namen der Erweiterung.",
              "Drücken Sie das Würfelsymbol neben der Adressleiste. Drücken Sie dann das Stecknadelsymbol neben dem Namen der Erweiterung.",
              "Drücken Sie das Puzzle-Symbol neben der Adressleiste. Drücken Sie dann das Zahnradsymbol neben dem Namen der Erweiterung. Wählen Sie „An Symbolleiste anheften“."
            ],
            "it": [
              "Fissare l'icona dell'estensione",
              "In ",
              "Premi l'icona del puzzle accanto alla barra degli indirizzi. Quindi premi l'icona della puntina accanto al nome dell'estensione.",
              "Premi l'icona del puzzle accanto alla barra degli indirizzi. Quindi premi l'icona dell'occhio accanto al nome dell'estensione.",
              "Premi l'icona del cubo accanto alla barra degli indirizzi. Quindi premi l'icona della puntina accanto al nome dell'estensione.",
              "Premi l'icona del puzzle accanto alla barra degli indirizzi. Quindi premi l'icona dell'ingranaggio accanto al nome dell'estensione. Scegli \"Fissa alla barra degli strumenti\"."
            ],
            "hu": [
              "Bővítmény ikon rögzítése",
              "-ban",
              "Nyomja meg a puzzle ikont a címsáv mellett. Ezután nyomja meg a rajzszög ikont a bővítmény neve mellett.",
              "Nyomja meg a puzzle ikont a címsáv mellett. Ezután nyomja meg a szem ikont a bővítmény neve mellett.",
              "Nyomja meg a kocka ikont a címsáv mellett. Ezután nyomja meg a rajzszög ikont a bővítmény neve mellett.",
              "Nyomja meg a puzzle ikont a címsáv mellett. Ezután nyomja meg a fogaskerék ikont a bővítmény neve mellett. Válassza a „Rögzítés az eszköztárra” lehetőséget."
            ],
            "tr": [
              "Uzantı simgesini sabitle",
              "'da",
              "Adres çubuğunun yanındaki yapboz simgesine basın. Ardından, uzantı adının yanındaki raptiye simgesine basın.",
              "Adres çubuğunun yanındaki yapboz simgesine basın. Ardından, uzantı adının yanındaki göz simgesine basın.",
              "Adres çubuğunun yanındaki küp simgesine basın. Ardından, uzantı adının yanındaki raptiye simgesine basın.",
              "Adres çubuğunun yanındaki yapboz simgesine basın. Ardından, uzantı adının yanındaki dişli simgesine basın. \"Araç çubuğuna sabitle\"yi seçin."
            ],
            "ru": [
              "Закрепить значок расширения",
              "В ",
              "Нажмите значок пазла рядом с адресной строкой. Затем нажмите значок булавки рядом с названием расширения.",
              "Нажмите значок пазла рядом с адресной строкой. Затем нажмите значок глаза рядом с названием расширения.",
              "Нажмите значок куба рядом с адресной строкой. Затем нажмите значок булавки рядом с названием расширения.",
              "Нажмите значок пазла рядом с адресной строкой. Затем нажмите значок шестерёнки рядом с названием расширения. Выберите «Закрепить на панели инструментов»."
            ],
            "he": [
                "הצמדת סמל הרחבה",
                "ב-",
                "לחץ על סמל הפאזל ליד שורת הכתובת. לאחר מכן לחץ על סמל הסיכה ליד שם ההרחבה.",
                "לחץ על סמל הפאזל ליד שורת הכתובת. לאחר מכן לחץ על סמל העין ליד שם ההרחבה.",
                "לחץ על סמל הקובייה ליד שורת הכתובת. לאחר מכן לחץ על סמל הסיכה ליד שם ההרחבה.",
                "לחץ על סמל הפאזל ליד שורת הכתובת. לאחר מכן לחץ על סמל גלגל השיניים ליד שם ההרחבה. בחר \"הצמד לסרגל הכלים\"."
              ],
              "vi": [
                "Ghim biểu tượng tiện ích mở rộng",
                "Trong ",
                "Nhấn vào biểu tượng hình ghép bên cạnh thanh địa chỉ. Sau đó, nhấn vào biểu tượng ghim bên cạnh tên tiện ích mở rộng.",
                "Nhấn vào biểu tượng hình ghép bên cạnh thanh địa chỉ. Sau đó, nhấn vào biểu tượng mắt bên cạnh tên tiện ích mở rộng.",
                "Nhấn vào biểu tượng hình khối bên cạnh thanh địa chỉ. Sau đó, nhấn vào biểu tượng ghim bên cạnh tên tiện ích mở rộng.",
                "Nhấn vào biểu tượng hình ghép bên cạnh thanh địa chỉ. Sau đó, nhấn vào biểu tượng bánh răng bên cạnh tên tiện ích mở rộng. Chọn \"Ghim vào thanh công cụ\"."
              ],
              "th": [
                "ปักหมุดไอคอนส่วนขยาย",
                "ใน ",
                "กดไอคอนจิ๊กซอว์ข้างแถบที่อยู่ จากนั้นกดไอคอนหมุดข้างชื่อส่วนขยาย",
                "กดไอคอนจิ๊กซอว์ข้างแถบที่อยู่ จากนั้นกดไอคอนรูปตาข้างชื่อส่วนขยาย",
                "กดไอคอนลูกบาศก์ข้างแถบที่อยู่ จากนั้นกดไอคอนหมุดข้างชื่อส่วนขยาย",
                "กดไอคอนจิ๊กซอว์ข้างแถบที่อยู่ จากนั้นกดไอคอนเฟืองข้างชื่อส่วนขยาย เลือก \"ปักหมุดไปที่แถบเครื่องมือ\""
              ],
              "ko": [
                "확장 프로그램 아이콘 고정",
                "에서",
                "주소 표시줄 옆의 퍼즐 아이콘을 누르세요. 그런 다음 확장 프로그램 이름 옆의 고정 핀 아이콘을 누르세요.",
                "주소 표시줄 옆의 퍼즐 아이콘을 누르세요. 그런 다음 확장 프로그램 이름 옆의 눈 아이콘을 누르세요.",
                "주소 표시줄 옆의 큐브 아이콘을 누르세요. 그런 다음 확장 프로그램 이름 옆의 고정 핀 아이콘을 누르세요.",
                "주소 표시줄 옆의 퍼즐 아이콘을 누르세요. 그런 다음 확장 프로그램 이름 옆의 톱니바퀴 아이콘을 누르세요. \"도구 모음에 고정\"을 선택하세요."
              ],
              "zh_CN": [
                "固定扩展程序图标",
                "在  中",
                "点击地址栏旁的拼图图标。然后点击扩展程序名称旁的图钉图标。",
                "点击地址栏旁的拼图图标。然后点击扩展程序名称旁的眼睛图标。",
                "点击地址栏旁的立方体图标。然后点击扩展程序名称旁的图钉图标。",
                "点击地址栏旁的拼图图标。然后点击扩展程序名称旁的齿轮图标。选择“固定到工具栏”。"
              ],
              "zh_TW": [
                "固定擴充功能圖示",
                "在  中",
                "點擊網址列旁的拼圖圖示。然後點擊擴充功能名稱旁的圖釘圖示。",
                "點擊網址列旁的拼圖圖示。然後點擊擴充功能名稱旁的眼睛圖示。",
                "點擊網址列旁的立方體圖示。然後點擊擴充功能名稱旁的圖釘圖示。",
                "點擊網址列旁的拼圖圖示。然後點擊擴充功能名稱旁的齒輪圖示。選擇“固定到工具列”。"
              ],
              "ja": [
                "拡張機能アイコンを固定",
                "で",
                "アドレスバーの横にあるパズルアイコンを押します。その後、拡張機能の名前の横にあるピンアイコンを押します。",
                "アドレスバーの横にあるパズルアイコンを押します。その後、拡張機能の名前の横にある目のアイコンを押します。",
                "アドレスバーの横にあるキューブアイコンを押します。その後、拡張機能の名前の横にあるピンアイコンを押します。",
                "アドレスバーの横にあるパズルアイコンを押します。その後、拡張機能の名前の横にある歯車アイコンを押します。「ツールバーに固定」を選択します。"
              ]
    };
    return(
        <>
<div id="pin-extension-icon" className="main-title">
<p>
    {description[props.lang][0]}
</p>
</div>
<div className="article">  
<p>
    <b>{description[props.lang][1]}Chrome</b> {description[props.lang][2]}
    <br /><b>{description[props.lang][1]}Edge</b> {description[props.lang][3]}
    <br /><b>{description[props.lang][1]}Opera</b> {description[props.lang][4]}
    <br /><b>{description[props.lang][1]}Firefox</b> {description[props.lang][5]}
</p>
</div>
        </>
        )
}