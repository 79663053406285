import Flag from './Flag';

export default function Lang(props){
    return(

    <div className="main-container">
        <br />
        <table id="lang-table">
            <tr>
                <td style={{minWidth:"64px"}}>
                    <img src="../assets/human-icon.svg" title="Translated by human" height="32px" /> 
                </td>
                <td>
                    <a href="en">
                    <img width='32px' src="../assets/flags/en.svg" /> 
                    </a>
                </td>
            </tr>
            <tr>
                <td>
                    <img width='32px' src="../assets/computer-icon.svg" title="Translated by machine" height="32px"/> 
                </td>
                <td>
                    <a href="pt">
                    <img width='32px' src="../assets/flags/pt_BR.svg" />
                    </a>
                </td>
                <td>
                    <a href="es">
                    <img width='32px' src="../assets/flags/es.svg" />
                    </a>
                </td>
                <td>
                    <a href="fr">
                    <img width='32px' src="../assets/flags/fr.svg" />
                    </a>
                </td>
                <td>
                    <a href="de">
                    <img width='32px' src="../assets/flags/de.svg" />
                    </a>
                </td>
                <td>
                    <a href="it">
                    <img width='32px' src="../assets/flags/it.svg" />
                    </a>
                </td>
                <td>
                    <a href="hu">
                    <img width='32px' src="../assets/flags/hu.svg" />
                    </a>
                </td>
                <td>
                    <a href="tr">
                    <img width='32px' src="../assets/flags/tr.svg" />
                    </a>
                </td>
                <td>
                    <a href="ru">
                    <img width='32px' src="../assets/flags/ru.svg" />
                    </a>
                </td>
                <td>
                    <a href="he">
                    <img width='32px' src="../assets/flags/he.svg" />
                    </a>
                </td>
                <td>
                    <a href="th">
                    <img width='32px' src="../assets/flags/th.svg" /> 
                    </a>
                </td>
                <td>
                    <a href="vi">
                    <img width='32px' src="../assets/flags/vi.svg" /> 
                    </a>
                </td>
                <td>
                    <a href="ko">
                    <img width='32px' src="../assets/flags/ko.svg" /> 
                    </a>
                </td>
                <td>
                    <a href="zh_CN">
                    <img width='32px' src="../assets/flags/zh_CN.svg" />
                    </a>
                </td>
                <td>
                    <a href="zh_TW">
                    <img width='32px' src="../assets/flags/zh_TW.svg" />
                    </a>
                </td>
                <td>
                    <a href="ja">
                    <img width='32px' className="lang-icon" src="../assets/flags/ja.svg" />
                    </a>
                </td>
            </tr>
        </table>
        </div>
    )
}