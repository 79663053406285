import { HashLink as Link } from 'react-router-hash-link';
import PinExtension from "../en/PinExtension.js";
import SupportUS from "../en/SupportUs.js";
import Header from '../Header.js';
import { useEffect } from 'react';
import { useHash } from '../UseHash';
import { scrollToSection } from '../Utils';
import { useParams } from "react-router-dom";

import Lang from '../Lang';
import '../en/Image Downloader/styles.css';

export default function ImageDownloaderGeneral(props){
    
    const description = {
          "en": [
            "Quickly find and download images from web pages in bulk",
            "You can filter the images based on image characteristics.",
            "You can select which image to download or download all images that pass filtering.",
            "You can choose a custom folder for the images and a custom file name sequence.",
            "Main features:",
            "Very quick and clean group display of all images.",
            "Download a single, multiple or all images in the web page.",
            "Filter images by: width, height, type, orientation and aspect ratio or URL.",
            "Custom or auto generated folder and file names.",
            "Auto convert files to JPEG, PNG or WebP.",
            "Side panel support.",
            "Available for: "
          ],
          "de": [
            "Schnell Bilder von Webseiten in großen Mengen finden und herunterladen",
            "Sie können die Bilder nach Bildmerkmalen filtern.",
            "Sie können auswählen, welches Bild Sie herunterladen möchten oder alle Bilder, die den Filter bestehen, herunterladen.",
            "Sie können einen benutzerdefinierten Ordner für die Bilder und eine benutzerdefinierte Dateinamen-Sequenz wählen.",
            "Hauptfunktionen:",
            "Sehr schnelle und saubere Gruppenanzeige aller Bilder.",
            "Laden Sie ein einzelnes, mehrere oder alle Bilder auf der Webseite herunter.",
            "Filtern Sie Bilder nach: Breite, Höhe, Typ, Ausrichtung und Seitenverhältnis oder URL.",
            "Benutzerdefinierte oder automatisch generierte Ordner- und Dateinamen.",
            "Automatische Konvertierung von Dateien in JPEG, PNG oder WebP.",
            "Seitenleistenunterstützung.",
            "Verfügbar für: "
          ],
          "es": [
            "Encuentra y descarga rápidamente imágenes de páginas web en masa",
            "Puedes filtrar las imágenes según las características de la imagen.",
            "Puedes seleccionar qué imagen descargar o descargar todas las imágenes que pasen el filtro.",
            "Puedes elegir una carpeta personalizada para las imágenes y una secuencia de nombres de archivo personalizada.",
            "Características principales:",
            "Visualización rápida y limpia de todas las imágenes.",
            "Descarga una sola, varias o todas las imágenes de la página web.",
            "Filtra las imágenes por: ancho, alto, tipo, orientación y relación de aspecto o URL.",
            "Nombres de carpetas y archivos personalizados o generados automáticamente.",
            "Convierte automáticamente los archivos a JPEG, PNG o WebP.",
            "Soporte de panel lateral.",
            "Disponible para: "
          ],
          "he": [
            "מצא והורד תמונות מעמודי אינטרנט בכמויות גדולות",
            "תוכל לסנן את התמונות על פי מאפיינים של תמונה.",
            "תוכל לבחור איזו תמונה להוריד או להוריד את כל התמונות שעוברות את הסינון.",
            "תוכל לבחור תיקיה מותאמת אישית לתמונות ורצף שמות קבצים מותאם אישית.",
            "תכונות עיקריות:",
            "תצוגה מהירה ונקייה של כל התמונות.",
            "הורד תמונה בודדת, מספר תמונות או את כל התמונות בדף האינטרנט.",
            "סנן תמונות לפי: רוחב, גובה, סוג, אוריינטציה ויחס גובה-רוחב או כתובת URL.",
            "שמות תיקיות וקבצים מותאמים אישית או שנוצרים אוטומטית.",
            "המרת קבצים אוטומטית ל-JPEG, PNG או WebP.",
            "תמיכה בפנל צד.",
            "זמין ל:"
          ],
          "hu": [
            "Gyorsan találjon és töltsön le képeket weboldalakról tömegesen",
            "A képeket a képi jellemzők alapján szűrheti.",
            "Kiválaszthatja, melyik képet szeretné letölteni, vagy letöltheti az összes képet, amely megfelel a szűrésnek.",
            "Testreszabott mappát és fájlnevet is választhat a képekhez.",
            "Főbb jellemzők:",
            "Nagyon gyors és tiszta csoportos megjelenítés minden képről.",
            "Letölthet egyetlen, több vagy az összes képet a weboldalon.",
            "Szűrje a képeket: szélesség, magasság, típus, tájolás és képarány vagy URL szerint.",
            "Egyéni vagy automatikusan generált mappák és fájlnevek.",
            "Automatikus fájlátalakítás JPEG, PNG vagy WebP formátumra.",
            "Oldalsáv támogatás.",
            "Elérhető: "
          ],
          "fr": [
            "Trouvez et téléchargez rapidement des images depuis des pages web en masse",
            "Vous pouvez filtrer les images en fonction de leurs caractéristiques.",
            "Vous pouvez choisir quelle image télécharger ou télécharger toutes les images qui passent le filtrage.",
            "Vous pouvez choisir un dossier personnalisé pour les images et une séquence de noms de fichiers personnalisée.",
            "Principales fonctionnalités :",
            "Affichage rapide et propre de toutes les images.",
            "Téléchargez une image unique, plusieurs images ou toutes les images de la page web.",
            "Filtrer les images par : largeur, hauteur, type, orientation et ratio ou URL.",
            "Noms de dossiers et de fichiers personnalisés ou générés automatiquement.",
            "Conversion automatique des fichiers en JPEG, PNG ou WebP.",
            "Prise en charge du panneau latéral.",
            "Disponible pour : "
          ],
          "it": [
            "Trova e scarica rapidamente immagini da pagine web in blocco",
            "Puoi filtrare le immagini in base alle caratteristiche dell'immagine.",
            "Puoi selezionare quale immagine scaricare o scaricare tutte le immagini che passano il filtro.",
            "Puoi scegliere una cartella personalizzata per le immagini e una sequenza di nomi file personalizzata.",
            "Caratteristiche principali:",
            "Visualizzazione molto veloce e pulita di tutte le immagini.",
            "Scarica una singola, più o tutte le immagini dalla pagina web.",
            "Filtra le immagini per: larghezza, altezza, tipo, orientamento e rapporto di aspetto o URL.",
            "Nomi cartelle e file personalizzati o generati automaticamente.",
            "Converti automaticamente i file in JPEG, PNG o WebP.",
            "Supporto pannello laterale.",
            "Disponibile per: "
          ],
          "ja": [
            "ウェブページから画像を一括で素早く見つけてダウンロード",
            "画像の特徴に基づいて画像をフィルタリングできます。",
            "ダウンロードする画像を選択するか、フィルタリングを通過したすべての画像をダウンロードできます。",
            "画像用のカスタムフォルダーとカスタムファイル名シーケンスを選択できます。",
            "主な機能：",
            "すべての画像の非常に迅速でクリーンなグループ表示。",
            "ウェブページから単一の画像、複数の画像、またはすべての画像をダウンロード。",
            "画像を次の条件でフィルタリング：幅、高さ、タイプ、方向、アスペクト比、またはURL。",
            "カスタムまたは自動生成されたフォルダーとファイル名。",
            "JPEG、PNG、またはWebPに自動変換。",
            "サイドパネルのサポート。",
            "利用可能なもの："
          ],
          "ko": [
            "웹 페이지에서 이미지를 대량으로 빠르게 찾고 다운로드",
            "이미지 특성에 따라 이미지를 필터링할 수 있습니다.",
            "다운로드할 이미지를 선택하거나 필터링을 통과한 모든 이미지를 다운로드할 수 있습니다.",
            "이미지에 대해 사용자 지정 폴더와 사용자 지정 파일 이름 시퀀스를 선택할 수 있습니다.",
            "주요 기능:",
            "모든 이미지에 대해 매우 빠르고 깔끔한 그룹 표시.",
            "웹 페이지에서 단일 이미지, 여러 이미지 또는 모든 이미지를 다운로드.",
            "다음 기준에 따라 이미지 필터링: 너비, 높이, 유형, 방향 및 가로세로 비율 또는 URL.",
            "사용자 지정 또는 자동 생성된 폴더 및 파일 이름.",
            "파일을 JPEG, PNG 또는 WebP로 자동 변환.",
            "사이드 패널 지원.",
            "사용 가능:"
          ],
          "ru": [
            "Быстро находите и загружайте изображения с веб-страниц оптом",
            "Вы можете фильтровать изображения по характеристикам изображения.",
            "Вы можете выбрать, какое изображение скачать, или скачать все изображения, которые проходят фильтрацию.",
            "Вы можете выбрать настраиваемую папку для изображений и последовательность настраиваемых имен файлов.",
            "Основные функции:",
            "Очень быстрый и чистый групповой вывод всех изображений.",
            "Скачайте одно, несколько или все изображения с веб-страницы.",
            "Фильтруйте изображения по: ширине, высоте, типу, ориентации, соотношению сторон или URL.",
            "Пользовательские или автоматически генерируемые папки и имена файлов.",
            "Автоматическое преобразование файлов в JPEG, PNG или WebP.",
            "Поддержка боковой панели.",
            "Доступно для:"
          ],
          "pt": [
            "Encontre e baixe rapidamente imagens de páginas web em massa",
            "Você pode filtrar as imagens com base em características da imagem.",
            "Você pode selecionar qual imagem baixar ou baixar todas as imagens que passam pelo filtro.",
            "Você pode escolher uma pasta personalizada para as imagens e uma sequência personalizada de nomes de arquivos.",
            "Principais características:",
            "Exibição muito rápida e limpa de todas as imagens.",
            "Baixe uma única, várias ou todas as imagens da página da web.",
            "Filtre as imagens por: largura, altura, tipo, orientação e proporção ou URL.",
            "Nomes de pastas e arquivos personalizados ou gerados automaticamente.",
            "Conversão automática de arquivos para JPEG, PNG ou WebP.",
            "Suporte a painel lateral.",
            "Disponível para:"
          ],
          "th": [
            "ค้นหาและดาวน์โหลดภาพจากหน้าเว็บเป็นจำนวนมากได้อย่างรวดเร็ว",
            "คุณสามารถกรองภาพตามลักษณะของภาพได้",
            "คุณสามารถเลือกภาพที่ต้องการดาวน์โหลดหรืดาวน์โหลดภาพทั้งหมดที่ผ่านการกรอง",
            "คุณสามารถเลือกโฟลเดอร์ที่กำหนดเองสำหรับภาพและลำดับชื่อไฟล์ที่กำหนดเองได้",
            "คุณสมบัติหลัก:",
            "แสดงผลภาพทั้งหมดอย่างรวดเร็วและสะอาดตา",
            "ดาวน์โหลดภาพเดี่ยว, หลายภาพ หรือทั้งหมดจากหน้าเว็บ",
            "กรองภาพตาม: ความกว้าง, ความสูง, ประเภท, ทิศทาง และอัตราส่วน หรือ URL",
            "ชื่อโฟลเดอร์และไฟล์ที่กำหนดเองหรือสร้างขึ้นโดยอัตโนมัติ",
            "แปลงไฟล์อัตโนมัติเป็น JPEG, PNG หรือ WebP",
            "รองรับแผงด้านข้าง",
            "สามารถใช้ได้กับ:"
          ],
          "tr": [
            "Web sayfalarından toplu olarak hızlıca resimler bulun ve indirin",
            "Resimleri, görüntü özelliklerine göre filtreleyebilirsiniz.",
            "İndirilecek resmi seçebilir veya filtrelemeyi geçen tüm resimleri indirebilirsiniz.",
            "Resimler için özel bir klasör ve özel bir dosya adı sırası seçebilirsiniz.",
            "Ana özellikler:",
            "Tüm resimlerin çok hızlı ve temiz grup görüntüsü.",
            "Web sayfasından tek bir, birden fazla veya tüm resimleri indirin.",
            "Resimleri şu şekilde filtreleyin: genişlik, yükseklik, tür, yönelim ve en-boy oranı veya URL.",
            "Özel veya otomatik oluşturulmuş klasör ve dosya adları.",
            "JPEG, PNG veya WebP'ye otomatik dönüştürme.",
            "Yan panel desteği.",
            "Mevcut: "
          ],
          "vi": [
            "Tìm và tải xuống hình ảnh từ các trang web nhanh chóng và hàng loạt",
            "Bạn có thể lọc các hình ảnh dựa trên các đặc điểm hình ảnh.",
            "Bạn có thể chọn hình ảnh để tải xuống hoặc tải tất cả các hình ảnh vượt qua bộ lọc.",
            "Bạn có thể chọn thư mục tùy chỉnh cho các hình ảnh và một chuỗi tên tệp tùy chỉnh.",
            "Các tính năng chính:",
            "Hiển thị nhóm tất cả các hình ảnh nhanh chóng và sạch sẽ.",
            "Tải xuống một, nhiều hoặc tất cả các hình ảnh từ trang web.",
            "Lọc các hình ảnh theo: chiều rộng, chiều cao, loại, hướng và tỷ lệ khung hình hoặc URL.",
            "Tên thư mục và tệp tùy chỉnh hoặc tự động tạo.",
            "Chuyển đổi tệp tự động thành JPEG, PNG hoặc WebP.",
            "Hỗ trợ bảng điều khiển bên.",
            "Có sẵn cho:"
          ],
          "zh_CN": [
            "快速查找并批量下载网页中的图像",
            "您可以根据图像特征过滤图像。",
            "您可以选择下载哪些图像或下载所有通过过滤的图像。",
            "您可以为图像选择自定义文件夹和自定义文件名序列。",
            "主要功能：",
            "非常快速和清晰的所有图像分组显示。",
            "从网页中下载单个图像、多个图像或所有图像。",
            "按以下条件过滤图像：宽度、高度、类型、方向和宽高比或URL。",
            "自定义或自动生成的文件夹和文件名。",
            "自动将文件转换为JPEG、PNG或WebP。",
            "支持侧边面板。",
            "适用于："
          ],
          "zh_TW": [
            "快速從網頁批量查找和下載圖像",
            "您可以根據圖像特徵過濾圖像。",
            "您可以選擇下載哪些圖像或下載所有通過過濾的圖像。",
            "您可以為圖像選擇自定義文件夾和自定義文件名序列。",
            "主要特點：",
            "非常快速和清晰的所有圖像分組顯示。",
            "從網頁下載單個圖像、複數圖像或所有圖像。",
            "按以下條件過濾圖像：寬度、高度、類型、方向和寬高比或URL。",
            "自定義或自動生成的文件夾和文件名。",
            "自動將文件轉換為JPEG、PNG或WebP。",
            "支持側邊面板。",
            "適用於："
          ]
        };
    const limition = {
  "en": [
    "Limitations",
    "(All) extensions do not work on add-on download sites such as the Chrome Web Store and non-URL sites such as the first page when you open a new tab.",
    "Usually, only images that are loaded prior to using the extension can be detected.",
    "If a site uses a thumbnail, you are most likely not able to capture the larger image.",
    "Some sites employ special techniques that prevent the detection and download of images.",
    "Before using the Image Downloader extension, follow the instructions below to ensure that the browser settings won't interfere."
  ],
  "de": [
    "Einschränkungen",
    "(Alle) Erweiterungen funktionieren nicht auf Download-Websites für Add-ons wie der Chrome Web Store und nicht-URL-Seiten wie der ersten Seite, wenn Sie einen neuen Tab öffnen.",
    "In der Regel können nur Bilder erkannt werden, die vor der Nutzung der Erweiterung geladen wurden.",
    "Wenn eine Seite ein Thumbnail verwendet, können Sie höchstwahrscheinlich das größere Bild nicht erfassen.",
    "Einige Seiten verwenden spezielle Techniken, die das Erkennen und Herunterladen von Bildern verhindern.",
    "Bevor Sie die 'Bild Downloader'-Erweiterung verwenden, folgen Sie den untenstehenden Anweisungen, um sicherzustellen, dass die Browsereinstellungen nicht stören."
  ],
  "es": [
    "Limitaciones",
    "(Todas) las extensiones no funcionan en sitios de descarga de complementos como la Chrome Web Store y sitios no-URL como la primera página cuando abres una nueva pestaña.",
    "Por lo general, solo las imágenes cargadas antes de usar la extensión pueden ser detectadas.",
    "Si un sitio usa una miniatura, lo más probable es que no puedas capturar la imagen más grande.",
    "Algunos sitios emplean técnicas especiales que evitan la detección y descarga de imágenes.",
    "Antes de usar la extensión 'Descargador de Imágenes', sigue las instrucciones a continuación para asegurarte de que la configuración del navegador no interfiera."
  ],
  "he": [
    "מגבלות",
    "(כל) ההרחבות לא פועלות באתרים להורדת תוספים כמו ה-Chrome Web Store ובאתרים שאינם כתובת URL, כגון הדף הראשון כאשר פותחים כרטיסייה חדשה.",
    "בדרך כלל, רק תמונות שהועמסו לפני השימוש בהרחבה ניתנות לזיהוי.",
    "אם אתר משתמש בתמונה ממוזערת, סביר להניח שלא תוכל ללכוד את התמונה הגדולה.",
    "חלק מהאתרים משתמשים בטכניקות מיוחדות שמונעות את זיהוי והורדת התמונות.",
    "לפני השימוש בהרחבת 'הורדת תמונות', עקוב אחרי ההוראות למטה על מנת לוודא שהגדרות הדפדפן לא יתערבו."
  ],
  "hu": [
    "Korlátozások",
    "(Minden) kiterjesztés nem működik az olyan bővítmény letöltő oldalakon, mint a Chrome Web Áruház és a nem-URL oldalak, mint az első oldal, amikor új lapot nyitsz.",
    "Általában csak azok a képek észlelhetők, amelyek a kiterjesztés használata előtt töltődtek be.",
    "Ha egy oldal miniatűr képet használ, valószínűleg nem fogod tudni rögzíteni a nagyobb képet.",
    "Néhány oldal speciális technikákat alkalmaz, amelyek megakadályozzák a képek észlelését és letöltését.",
    "A 'Kép Letöltő' kiterjesztés használata előtt kövesd az alábbi utasításokat, hogy biztosítsd, hogy a böngésző beállításai ne zavarják."
  ],
  "fr": [
    "Limitations",
    "(Toutes) les extensions ne fonctionnent pas sur des sites de téléchargement d'extensions tels que le Chrome Web Store et des sites non-URL comme la première page lorsque vous ouvrez un nouvel onglet.",
    "En général, seules les images chargées avant l'utilisation de l'extension peuvent être détectées.",
    "Si un site utilise une miniature, il est fort probable que vous ne puissiez pas capturer l'image plus grande.",
    "Certains sites utilisent des techniques spéciales qui empêchent la détection et le téléchargement des images.",
    "Avant d'utiliser l'extension 'Téléchargeur d'Images', suivez les instructions ci-dessous pour vous assurer que les paramètres du navigateur n'interfèrent pas."
  ],
  "it": [
    "Limitazioni",
    "(Tutte) le estensioni non funzionano su siti di download di componenti aggiuntivi come il Chrome Web Store e su siti non-URL come la prima pagina quando apri una nuova scheda.",
    "Di solito, solo le immagini caricate prima di utilizzare l'estensione possono essere rilevate.",
    "Se un sito utilizza una miniatura, è molto probabile che non sarai in grado di catturare l'immagine più grande.",
    "Alcuni siti utilizzano tecniche speciali che impediscono il rilevamento e il download delle immagini.",
    "Prima di utilizzare l'estensione 'Scaricatore di Immagini', segui le istruzioni qui sotto per assicurarti che le impostazioni del browser non interferiscano."
  ],
  "ja": [
    "制限事項",
    "(すべての)拡張機能は、Chrome Web Storeなどのアドオンダウンロードサイトや、新しいタブを開いたときの最初のページのような非URLサイトでは動作しません。",
    "通常、拡張機能を使用する前に読み込まれた画像のみが検出されます。",
    "サイトがサムネイルを使用している場合、大きな画像をキャプチャできない可能性が高いです。",
    "一部のサイトでは、画像の検出やダウンロードを防ぐ特別な技術が使用されています。",
    "拡張機能を使用する前に、下記の手順に従って、ブラウザの設定が干渉しないようにしてください。"
  ],
  "ko": [
    "제한 사항",
    "(모든) 확장 프로그램은 Chrome 웹 스토어와 같은 추가 기능 다운로드 사이트와 새 탭을 열 때 표시되는 첫 번째 페이지와 같은 비-URL 사이트에서 작동하지 않습니다.",
    "일반적으로 확장 프로그램을 사용하기 전에 로드된 이미지만 감지할 수 있습니다.",
    "사이트가 썸네일을 사용하는 경우, 큰 이미지를 캡처할 수 없을 가능성이 큽니다.",
    "일부 사이트는 이미지 감지 및 다운로드를 방지하는 특별한 기술을 사용합니다.",
    "확장 프로그램을 사용하기 전에 아래 지침을 따라 브라우저 설정이 방해되지 않도록 하세요."
  ],
  "ru": [
    "Ограничения",
    "(Все) расширения не работают на сайтах для загрузки дополнений, таких как Chrome Web Store, и на не-URL сайтах, таких как первая страница при открытии новой вкладки.",
    "Обычно только изображения, загруженные до использования расширения, могут быть обнаружены.",
    "Если сайт использует миниатюру, скорее всего, вы не сможете захватить более крупное изображение.",
    "Некоторые сайты используют специальные методы, которые предотвращают обнаружение и загрузку изображений.",
    "Перед использованием расширения следуйте инструкциям ниже, чтобы убедиться, что настройки браузера не мешают."
  ],
  "pt": [
    "Limitações",
    "(Todas) as extensões não funcionam em sites de download de complementos como a Chrome Web Store e em sites não-URL como a primeira página quando você abre uma nova aba.",
    "Normalmente, apenas as imagens carregadas antes de usar a extensão podem ser detectadas.",
    "Se um site usa uma miniatura, é muito provável que você não consiga capturar a imagem maior.",
    "Alguns sites utilizam técnicas especiais que impedem a detecção e o download de imagens.",
    "Antes de usar a extensão 'Baixador de Imagens', siga as instruções abaixo para garantir que as configurações do navegador não interfiram."
  ],
  "th": [
    "ข้อจำกัด",
    "(ทุก) ส่วนขยายไม่ทำงานบนเว็บไซต์ดาวน์โหลดส่วนขยายเช่น Chrome Web Store และเว็บไซต์ที่ไม่มี URL เช่นหน้าหลักเมื่อคุณเปิดแท็บใหม่",
    "โดยปกติแล้วจะตรวจจับได้เฉพาะภาพที่โหลดก่อนการใช้ส่วนขยาย",
    "หากเว็บไซต์ใช้ภาพขนาดย่อ คุณอาจไม่สามารถจับภาพขนาดใหญ่ได้",
    "บางเว็บไซต์ใช้เทคนิคพิเศษที่ป้องกันการตรวจจับและการดาวน์โหลดภาพ",
    "ก่อนใช้ส่วนขยายโปรดทำตามคำแนะนำด้านล่างเพื่อให้แน่ใจว่าไม่มีกำหนดค่าของเบราว์เซอร์ที่ขัดขวาง"
  ],
  "tr": [
    "Sınırlamalar",
    "(Tüm) uzantılar, Chrome Web Mağazası gibi eklenti indirme sitelerinde ve yeni bir sekme açtığınızda görünen ilk sayfalar gibi URL olmayan sitelerde çalışmaz.",
    "Genellikle, uzantıyı kullanmadan önce yüklenen resimler algılanabilir.",
    "Bir site küçük resim (thumbnail) kullanıyorsa, büyük resmi yakalayamayabilirsiniz.",
    "Bazı siteler, resimlerin tespiti ve indirilmesini engelleyen özel teknikler kullanır.",
    "Uzantıyı kullanmadan önce, tarayıcı ayarlarınızın müdahale etmediğinden emin olmak için aşağıdaki talimatları izleyin."
  ],
  "vi": [
    "Hạn chế",
    "(Tất cả) các tiện ích mở rộng không hoạt động trên các trang tải xuống tiện ích bổ sung như Chrome Web Store và các trang không phải URL như trang đầu tiên khi bạn mở một tab mới.",
    "Thông thường, chỉ những hình ảnh đã được tải trước khi sử dụng tiện ích mở rộng mới có thể được phát hiện.",
    "Nếu một trang sử dụng hình thu nhỏ, bạn rất có thể không thể chụp được hình ảnh lớn hơn.",
    "Một số trang web sử dụng các kỹ thuật đặc biệt ngăn chặn việc phát hiện và tải xuống hình ảnh.",
    "Trước khi sử dụng tiện ích mở rộng, vui lòng làm theo hướng dẫn dưới đây để đảm bảo rằng các cài đặt trình duyệt của bạn không can thiệp."
  ],
  "zh_CN": [
    "限制",
    "(所有)扩展程序无法在像Chrome Web商店这样的插件下载站点和没有URL的站点（如打开新标签页时的第一页）上工作。",
    "通常，只有在使用扩展程序之前加载的图像才能被检测到。",
    "如果网站使用缩略图，您很可能无法捕获较大的图像。",
    "一些网站使用特殊技术来防止检测和下载图像。",
    "在使用扩展程序之前，请按照下面的说明操作，以确保浏览器设置不会干扰。"
  ],
  "zh_TW": [
    "限制",
    "(所有)擴充功能無法在像是 Chrome Web 商店這類插件下載站點以及沒有 URL 的站點（例如當你開啟新分頁時的首頁）上運作。",
    "通常，只有在使用擴充功能之前已經加載的圖片才能被偵測到。",
    "如果一個網站使用縮圖，您很可能無法捕捉到較大的圖片。",
    "有些網站使用特殊技術來防止偵測和下載圖片。",
    "在使用擴充功能之前，請按照下面的說明操作，確保瀏覽器的設定不會干擾。"
  ]
};
    const checkSettings = {
        "en": [
          "Check browser settings",
          "On "," and "," go to ","settings -> downloads",", make sure that ","\"Ask where to save each file before downloading\""," is turned ","off",".",
          "On "," go to ","settings -> general -> files and applications",", make sure that ","\"Always ask you where to save files\""," is ","unchecked","."
        ],
        "de": [
          "Überprüfen Sie die Browsereinstellungen",
          "Gehen Sie in "," und "," zu "," Einstellungen -> Downloads",", stellen Sie sicher, dass ","\"Vor dem Download von Dateien nach dem Speicherort fragen\""," auf ","aus"," gestellt ist.",
          "Gehen Sie in "," zu "," Einstellungen -> Allgemein -> Dateien und Anwendungen",", stellen Sie sicher, dass ","\"Jedes Mal nachfragen, wo eine Datei gespeichert werden soll\""," ","deaktiviert"," ist."
        ],
        "es": [
          "Verificar la configuración del navegador",
          "En "," y ",", ve a ","configuración -> descargas",", asegúrate de que ","\"Preguntar dónde se guardará cada archivo antes de descargarlo\""," esté ","desactivado",".",
          "En ",", ve a ","ajustes -> general -> archivos y aplicaciones",", asegúrate de que ","\"Preguntar siempre dónde guardar los archivos\""," esté ","desmarcado","."
        ],
        "he": [
          "בדוק את הגדרות הדפדפן",
          "ב-"," ו-"," עבור אל ","הגדרות -> הורדות",", ודא כי ","\"יש לשאול היכן לשמור כל קובץ לפני הורדתו\""," ","מכובה",".",
          "ב-"," עבור אל ","הגדרות -> כללי -> קבצים ויישומים",", ודא כי ","\"לשאול תמיד היכן לשמור קבצים\""," ","לא נבחר","."
        ],
        "hu": [
          "Ellenőrizze a böngésző beállításait",
          ""," és "," esetén lépjen a ","beállítások -> letöltések"," menübe, és győződjön meg arról, hogy ","\"A letöltés előtt kérdezze meg, hová mentse az adott fájlokat\""," ","kikapcsolva"," van.",
          ""," esetén lépjen a ","beállítások -> általános -> fájlok és alkalmazások"," menübe, és győződjön meg arról, hogy ","\"Mindig kérdezzen rá a fájlok letöltési helyére\""," ","nincs bepipálva","."
        ],
        "fr": [
          "Vérifiez les paramètres du navigateur",
          "Sur "," et ",", allez dans ","paramètres -> téléchargements",", assurez-vous que ","\"Toujours demander où enregistrer les fichiers\""," est ","désactivé",".",
          "Sur ",", allez dans ","paramètres -> général -> fichiers et applications",", assurez-vous que ","\"Toujours demander où enregistrer les fichiers\""," est ","décoché","."
        ],
        "it": [ 
          "Controlla le impostazioni del browser",
          "In "," e "," vai su ","impostazioni -> download",", assicurati che ","\"Chiedi dove salvare il file prima di scaricarlo\""," sia ","disattivato",".",
          "In "," vai su ","impostazioni -> generale -> file e applicazioni",", assicurati che ","\"Chiedi dove salvare ogni file\""," ","non sia selezionato","."
        ],
        "ja": [
          "ブラウザ設定を確認",
          "","と","では、","設定 -> ダウンロード","に移動し、","\"ダウンロード前に各ファイルの保存場所を確認する\"","が","オフ","になっていることを確認してください。",
          "","では、","設定 -> 一般 -> ファイルとプログラム","に移動し、","\"ファイルごとに保存先を指定する\"","が","オフ","になっていることを確認してください。"
        ],
        "ko": [
          "브라우저 설정 확인",
          "","과 ","에서 ","설정 -> 다운로드","로 이동하여 ","\"다운로드 전에 각 파일의 저장 위치 확인\"","가 ","꺼짐","으로 설정되어 있는지 확인하세요.",
          "","에서 ","설정 -> 일반 -> 파일 및 애플리케이션","으로 이동하여 ","\"파일을 저장할 위치를 항상 묻기\"","가 ","선택 해제됨","인지 확인하세요."
        ],
        "ru": [
          "Проверьте настройки браузера",
          "В "," и "," перейдите в ","настройки -> Скачанные файлы",", убедитесь, что ","\"Перед скачиванием спрашивать, куда сохранить файл\""," ","отключено",".",
          "В "," перейдите в ","настройки -> Основные -> файлы и приложения",", убедитесь, что ","\"Всегда выдавать запрос на сохранение файлов\""," отключено","."
        ],
        "pt": [
          "Verificar as configurações do navegador",
          "No "," e no ",", vá para ","configurações -> downloads",", certifique-se de que ","\"Perguntar onde salvar cada arquivo antes de fazer download\""," esteja ","desligado",".",
          "No ",", vá para ","configurações -> geral -> arquivos e aplicativos",", certifique-se de que ","\"Sempre perguntar onde salvar arquivos\""," esteja ","desmarcado","."
        ],
        "th": [
          "ตรวจสอบการตั้งค่าของเบราว์เซอร์",
          "ใน "," และ "," ไปที่ ","การตั้งค่า -> ดาวน์โหลด"," และตรวจสอบให้แน่ใจว่า ","\"สอบถามที่เก็บไฟล์ก่อนดาวน์โหลด\""," ","ปิดอยู่","",
          "ใน "," ไปที่ ","การตั้งค่า -> ทั่วไป -> ไฟล์และแอปพลิเคชัน"," และตรวจสอบให้แน่ใจว่า ","\"ถามคุณเสมอว่าจะบันทึกไฟล์ที่ไหน\""," ถูก ","ยกเลิกการเลือก",""
        ],
        "tr": [
          "Tarayıcı ayarlarını kontrol et",
          ""," ve ","'de ","ayarlar -> indirmeler"," sekmesine gidin, ","\"İndirmeden önce her dosyanın nereye kaydedileceğini sor\""," seçeneğinin ","kapalı"," olduğundan emin olun.",
          "","'ta ","ayarlar -> genel -> dosyalar ve uygulamalar"," sekmesine gidin, ","\"Dosyaların nereye kaydedileceğini her zaman sor\""," seçeneğinin ","işaretsiz"," olduğundan emin olun."
        ],
        "vi": [
          "Kiểm tra cài đặt trình duyệt",
          "Trên "," và ",", vào ","cài đặt -> Tệp đã tải xuống",", đảm bảo rằng ","\"Hỏi vị trí lưu từng tệp trước khi tải xuống\""," đã được ","tắt",".",
          "Trên ",", vào ","cài đặt -> Tổng quát -> Tập tin và ứng dụng",", đảm bảo rằng ","\"Luôn hỏi bạn nơi để lưu các tập tin\""," đã được ","bỏ chọn","."
        ],
        "zh_CN": [
          "检查浏览器设置",
          "在 "," 和 "," 上，进入 ","设置 -> 下载内容","，确保 ","\"下载前询问每个文件的保存位置\""," 被设置为 ","关闭","。",
          "在 "," 上，进入 ","设置 -> 常规 -> 文件与应用程序","，确保 ","\"总是询问保存至何处\""," 被 ","取消勾选","。"
        ],
        "zh_TW": [
          "檢查瀏覽器設置",
          "在 "," 和 "," 上，進入 ","設置 -> 下載","，確保 ","\"下載每個檔案前先詢問儲存位置\""," 被設置為 ","關閉","。",
          "在 "," 上，進入 ","設定 -> 一般 -> 檔案與應用程式","，確保 ","\"每次都問您要存到何處\""," 被 ","取消勾選","。"
        ]
      };
      const userGuideOpening = {
        "en": [
          "User guide",
          "To use the Image Downloader, press the extension icon. This will open the popup window (or side panel - see the Display submenu section) and start scanning the web page for images. The popup menu is divided into 3 main parts: the main menu, the submenu and the results.",
          "Use the menu buttons to change the submenu being displayed. ",
          "Press the save icon ",
          " in the submenu to save the settings of that submenu (only of that submenu).",
          "You can select one or more images by clicking on them in the result part. ",
          "Notice that large images have this ",
          " \"open in a new tab\" icon. ",
          "To download your selected images click the download selected images icon ",
          ". ",
          "To download all the images in the result part press the download all icon",
          ".",
          "The last icon in the main menu ",
          " opens a dropdown menu there you will find, among others, a link to this guide."
        ],
        "pt": [
          "Guia do usuário",
          "Para usar o downloader de imagens, pressione o ícone da extensão. Isso abrirá a janela pop-up (ou painel lateral - veja a seção do submenu Exibir) e começará a escanear a página da web em busca de imagens. O menu pop-up é dividido em 3 partes principais: o menu principal, o submenu e os resultados.",
          "Use os botões de menu para alterar o submenu que está sendo exibido.",
          "Pressione o ícone salvar ",
          " no submenu para salvar as configurações desse submenu (somente desse submenu).",
          "Você pode selecionar uma ou mais imagens clicando nelas na parte de resultados. ",
          "Observe que imagens grandes têm este ícone ",
          " \"abrir em uma nova guia\". ",
          "Para baixar suas imagens selecionadas, clique no ícone baixar imagens selecionadas ",
          ". ",
          "Para baixar todas as imagens na parte de resultados, pressione o ícone baixar tudo ",
          ".",
          "O último ícone no menu principal ",
          " abre um menu suspenso onde você encontrará, entre outros, um link para este guia."
        ],
        "es": [
          "Guía del usuario",
          "Para utilizar el Descargador de imágenes, pulse el icono de la extensión. Esto abrirá la ventana emergente (o el panel lateral - consulte la sección del submenú Visualizar) y comenzará a escanear la página web en busca de imágenes. El menú emergente se divide en 3 partes principales: el menú principal, el submenú y los resultados.",
          "Utilice los botones del menú para cambiar el submenú que se muestra. ",
          "Pulse el icono de guardar ",
          " en el submenú para guardar la configuración de ese submenú (solo de ese submenú).",
          "Puede seleccionar una o más imágenes haciendo clic en ellas en la parte de resultados. ",
          "Observe que las imágenes grandes tienen este ",
          " icono de \"abrir en una nueva pestaña\". ",
          "Para descargar las imágenes seleccionadas, haga clic en el icono de descargar imágenes seleccionadas ",
          ". ",
          "Para descargar todas las imágenes en la parte de resultados, pulse el icono de descargar todo",
          ".",
          "El último icono del menú principal ",
          " abre un menú desplegable en el que encontrará, entre otros, un enlace a esta guía."
        ],
        "fr": [
          "Guide de l'utilisateur",
          "Pour utiliser le téléchargeur d'images, appuyez sur l'icône d'extension. Cela ouvrira la fenêtre contextuelle (ou le panneau latéral - voir la section Sous-menu d'affichage) et commencera à rechercher des images sur la page Web. Le menu contextuel est divisé en 3 parties principales : le menu principal, le sous-menu et les résultats.",
          "Utilisez les boutons de menu pour modifier le sous-menu affiché. ",
          "Appuyez sur l'icône d'enregistrement ",
          " dans le sous-menu pour enregistrer les paramètres de ce sous-menu (uniquement de ce sous-menu).",
          "Vous pouvez sélectionner une ou plusieurs images en cliquant dessus dans la partie des résultats. ",
          "Notez que les grandes images ont cette icône ",
          " « ouvrir dans un nouvel onglet ». ",
          "Pour télécharger vos images sélectionnées, cliquez sur l'icône de téléchargement des images sélectionnées ",
          ". ",
          "Pour télécharger toutes les images de la partie des résultats, appuyez sur l'icône de téléchargement de tout",
          ".",
          "La dernière icône du menu principal ",
          " ouvre un menu déroulant dans lequel vous trouverez, entre autres, un lien vers ce guide."
        ],
        "de": [
          "Benutzerhandbuch",
          "Um den Bild-Downloader zu verwenden, drücken Sie auf das Erweiterungssymbol. Dadurch wird das Popup-Fenster (oder die Seitenleiste – siehe Abschnitt \"Untermenü anzeigen\") geöffnet und die Webseite wird nach Bildern durchsucht. Das Popup-Menü ist in drei Hauptteile unterteilt: das Hauptmenü, das Untermenü und die Ergebnisse.",
          "Verwenden Sie die Menüschaltflächen, um das angezeigte Untermenü zu ändern. ",
          "Drücken Sie im Untermenü auf das Speichersymbol, ",
          " um die Einstellungen dieses Untermenüs (nur dieses Untermenüs) zu speichern.",
          "Sie können ein oder mehrere Bilder auswählen, indem Sie im Ergebnisteil darauf klicken. ",
          "Beachten Sie, dass große Bilder dieses ",
          " Symbol \"In neuem Tab öffnen\" haben. ",
          "Um Ihre ausgewählten Bilder herunterzuladen, klicken Sie auf das Symbol \"Ausgewählte Bilder herunterladen\" ",
          ". ",
          "Um alle Bilder im Ergebnisteil herunterzuladen, drücken Sie auf das Symbol \"Alle herunterladen\"",
          ".",
          "Das letzte Symbol im Hauptmenü ",
          " öffnet ein Dropdown-Menü, in dem Sie unter anderem einen Link zu dieser Anleitung finden."
      ],
      "it": [
        "Guida utente",
        "Per usare il downloader di immagini, premi l'icona dell'estensione. Questo aprirà la finestra popup (o pannello laterale, vedi la sezione del sottomenu Visualizza) e inizierà a scansionare la pagina web per le immagini. Il menu popup è diviso in 3 parti principali: il menu principale, il sottomenu e i risultati.",
        "Usa i pulsanti del menu per cambiare il sottomenu visualizzato. ",
        "Premi l'icona di salvataggio ",
        " nel sottomenu per salvare le impostazioni di quel sottomenu (solo di quel sottomenu).",
        "Puoi selezionare una o più immagini cliccandoci sopra nella parte dei risultati. ",
        "Nota che le immagini di grandi dimensioni hanno questa icona ",
        " \"apri in una nuova scheda\". ",
        "Per scaricare le immagini selezionate clicca sull'icona di download delle immagini selezionate ",
        ". ",
        "Per scaricare tutte le immagini nella parte dei risultati premi l'icona di download di tutto",
        ".",
        "L'ultima icona nel menu principale ",
        " apre un menu a discesa dove troverai, tra gli altri, un link a questa guida."
      ],
      "hu": [
        "Felhasználói útmutató",
        "A képletöltő használatához nyomja meg a kiterjesztés ikonját. Ez megnyitja a felugró ablakot (vagy oldalsó panelt – lásd a Megjelenítés almenü részt), és elkezdi beolvasni a weboldalt a képekért. A felugró menü 3 fő részre oszlik: a főmenüre, az almenüre és az eredményekre.",
        "Használja a menü gombokat a megjelenített almenü megváltoztatásához.",
        "Nyomja meg a mentés ikont ",
        " az almenüben az adott almenü beállításainak mentéséhez (csak az adott almenüé).",
        "Egy vagy több képet kiválaszthat, ha rákattint a találati részben.",
        "Figyelje meg, hogy a nagy képeken ez a ",
        " \"megnyitás új lapon\" ikon látható.",
        " A kiválasztott képek letöltéséhez kattintson a kiválasztott képek letöltése ikonra ",
        ". ",
        "Az eredmény részben található összes kép letöltéséhez nyomja meg az összes letöltése ikont",
        ".",
        "A főmenü utolsó ikonja ",
        " egy legördülő menüt nyit meg, ahol többek között egy hivatkozást talál erre az útmutatóra."
      ],
      "tr": [
        "Kullanıcı kılavuzu",
        "Resim indiricisini kullanmak için uzantı simgesine basın. Bu, açılır pencereyi (veya yan paneli - Görüntüleme alt menü bölümüne bakın) açacak ve web sayfasını resimler için taramaya başlayacaktır. Açılır menü 3 ana bölüme ayrılmıştır: ana menü, alt menü ve sonuçlar.",
        "Görüntülenen alt menüyü değiştirmek için menü düğmelerini kullanın. ",
        "Alt menüdeki kaydetme simgesine basarak ",
        " o alt menünün ayarlarını kaydedin (sadece o alt menünün).",
        "Sonuç bölümünde üzerlerine tıklayarak bir veya daha fazla resim seçebilirsiniz. ",
        "Büyük resimlerin \"yeni sekmede aç\" simgesine sahip olduğunu unutmayın ",
        ". ",
        "Seçtiğiniz resimleri indirmek için seçili resimleri indir simgesine tıklayın ",
        ". ",
        "Sonuç bölümündeki tüm resimleri indirmek için tümünü indir simgesine basın",
        ".",
        "Ana menüdeki son simge ",
        " bir açılır menü açar, orada diğerlerinin yanı sıra bu kılavuza bir bağlantı bulacaksınız."
      ],
      "ru": [
        "Руководство пользователя",
        "Чтобы использовать загрузчик изображений, нажмите значок расширения. Это откроет всплывающее окно (или боковую панель — см. раздел Подменю «Отображение») и начнет сканирование веб-страницы на наличие изображений. Всплывающее меню разделено на 3 основные части: главное меню, подменю и результаты.",
        "Используйте кнопки меню, чтобы изменить отображаемое подменю. ",
        "Нажмите значок сохранения ",
        " в подменю, чтобы сохранить настройки этого подменю (только этого подменю).",
        "Вы можете выбрать одно или несколько изображений, нажав на них в части результатов. ",
        "Обратите внимание, что большие изображения имеют этот значок ",
        " «открыть в новой вкладке». ",
        "Чтобы загрузить выбранные изображения, нажмите значок загрузки выбранных изображений ",
        ". ",
        "Чтобы загрузить все изображения в части результатов, нажмите значок загрузки всех ",
        ".",
        "Последний значок в главном меню ",
        " открывает раскрывающееся меню, в котором вы найдете, среди прочего, ссылку на это руководство."
      ],
"he": [
  "מדריך למשתמש",
  "כדי להשתמש בכלי הורדת התמונות, לחץ על סמל התוסף. פעולה זו תפתח את החלון הקופץ (או הלוח הצדדי - ראה סעיף תפריט המשנה תצוגה) ותתחיל לסרוק את דף האינטרנט לאיתור תמונות. התפריט הקופץ מחולק ל-3 חלקים עיקריים: התפריט הראשי, תפריט המשנה והתוצאות.",
  "השתמש בלחצני התפריט כדי לשנות את תפריט המשנה המוצג. ",
  "לחץ על סמל השמירה ",
  " בתפריט המשנה כדי לשמור את ההגדרות של אותו תפריט משנה (רק של אותו תפריט משנה).",
  "ניתן לבחור תמונה אחת או יותר על ידי לחיצה עליהן בחלק התוצאה. ",
  "שימו לב שלתמונות גדולות יש את הסמל הזה ",
  " של \"פתח בכרטיסייה חדשה\". ",
  "כדי להוריד את התמונות שבחרת לחץ על סמל הורדת התמונות שנבחרו ",
  ". ",
  "כדי להוריד את כל התמונות בחלק התוצאה, לחץ על סמל הורד הכל",
  ".",
  "האייקון האחרון בתפריט הראשי ",
  " פותח תפריט נפתח שם תמצאו, בין היתר, קישור למדריך זה."
],
"th": [
  "คู่มือการใช้งาน",
  "หากต้องการใช้โปรแกรมดาวน์โหลดรูปภาพ ให้กดไอคอนส่วนขยาย หน้าต่างแบบป๊อปอัป (หรือแผงด้านข้าง - ดูส่วนเมนูย่อย Display) จะเปิดขึ้น และเริ่มสแกนหน้าเว็บเพื่อค้นหารูปภาพ เมนูแบบป๊อปอัปจะแบ่งออกเป็น 3 ส่วนหลัก: เมนูหลัก เมนูย่อย และผลลัพธ์.",
  "ใช้ปุ่มเมนูเพื่อเปลี่ยนเมนูย่อยที่กำลังแสดง.",
  "ใช้ไอคอนบันทึกในเมนูย่อยเพื่อบันทึกการตั้งค่าของเมนูย่อยนั้น (เฉพาะเมนูย่อยนั้นเท่านั้น).",
  "คุณสามารถเลือกรูปภาพหนึ่งภาพหรือมากกว่านั้นได้โดยคลิกที่รูปภาพเหล่านั้นในส่วนผลลัพธ์.",
  "โปรดสังเกตว่ารูปภาพขนาดใหญ่จะมีไอคอน \"เปิดในแท็บใหม่\".",
  "หากต้องการดาวน์โหลดรูปภาพที่เลือก ให้คลิกไอคอนดาวน์โหลดรูปภาพที่เลือก.",
  "หากต้องการดาวน์โหลดรูปภาพทั้งหมดในส่วนผลลัพธ์ ให้กดไอคอนดาวน์โหลดทั้งหมด.",
  "ไอคอนสุดท้ายในเมนูหลักจะเปิดเมนูแบบดรอปดาวน์ ซึ่งคุณจะพบลิงก์ไปยังคู่มือนี้ รวมถึงลิงก์อื่นๆ."
],
"vi": [
  "Hướng dẫn sử dụng",
  "Để sử dụng trình tải xuống hình ảnh, hãy nhấn vào biểu tượng tiện ích mở rộng. Thao tác này sẽ mở cửa sổ bật lên (hoặc bảng điều khiển bên - xem phần menu phụ Hiển thị) và bắt đầu quét trang web để tìm hình ảnh. Menu bật lên được chia thành 3 phần chính: menu chính, menu phụ và kết quả.",
  "Sử dụng các nút menu để thay đổi menu phụ đang hiển thị. ",
  "Nhấn vào biểu tượng lưu ",
  " trong menu phụ để lưu cài đặt của menu phụ đó (chỉ của menu phụ đó).",
  "Bạn có thể chọn một hoặc nhiều hình ảnh bằng cách nhấp vào chúng trong phần kết quả. ",
  "Lưu ý rằng hình ảnh lớn có biểu tượng ",
  " \"mở trong tab mới\". ",
  "Để tải xuống hình ảnh đã chọn, hãy nhấp vào biểu tượng tải xuống hình ảnh đã chọn ",
  " . ",
  "Để tải xuống tất cả hình ảnh trong phần kết quả, hãy nhấn vào biểu tượng tải xuống tất cả",
  " .",
  "Biểu tượng cuối cùng trong menu chính ",
  " mở ra một menu thả xuống, tại đó bạn sẽ tìm thấy, trong số những thứ khác, một liên kết đến hướng dẫn này."
],
"zh_TW": [
  "使用者指南",
  "若要使用圖像下載器，請按擴充圖示。這將打開彈出視窗（或側面板 - 請參閱顯示子選單部分）並開始掃描網頁上的圖像。彈出式選單分為 3 個主要部分：主選單、子選單和結果。",
  "使用選單按鈕變更正在顯示的子選單。",
  "按下子選單中的儲存圖示 ",
  " 可以儲存該子選單的設定（僅限該子選單）。",
  "您可以透過點擊結果部分來選擇一個或多個圖像。",
  "請注意，大圖像有 ",
  " “在新分頁中開啟”圖示。",
  "若要下載您選擇的圖像，請按一下下載選定的圖像圖示 ",
  " 。",
  "若要下載結果部分的所有圖像，請按全部下載圖示 ",
  " 。",
  "主選單中的最後一個圖示 ",
  " 會開啟一個下拉選單，您會在那裡找到本指南的連結等。"
],
"zh_CN": [
  "用户指南",
  "要使用图片下载器，请按扩展图标。这将打开弹出窗口（或侧面板 - 参见显示子菜单部分）并开始扫描网页中的图片。弹出菜单分为 3 个主要部分：主菜单、子菜单和结果。",
  "使用菜单按钮更改显示的子菜单。",
  "按子菜单中的保存图标 ",
  " 可保存该子菜单的设置（仅限该子菜单）。",
  "您可以通过在结果部分单击来选择一张或多张图片。",
  "请注意，大图片有 ",
  " “在新选项卡中打开”图标。",
  "要下载所选图片，请单击下载所选图片图标 ",
  " 。",
  "要下载结果部分中的所有图片，请按下载全部图标 ",
  " 。",
  "主菜单中的最后一个图标 ",
  " 会打开一个下拉菜单，您会在其中找到本指南的链接等。"
],
"th": [
  "คู่มือการใช้งาน",
  "หากต้องการใช้โปรแกรมดาวน์โหลดรูปภาพ ให้กดไอคอนส่วนขยาย หน้าต่างแบบป๊อปอัป (หรือแผงด้านข้าง - ดูส่วนเมนูย่อย Display) จะเปิดขึ้น และเริ่มสแกนหน้าเว็บเพื่อค้นหารูปภาพ เมนูแบบป๊อปอัปจะแบ่งออกเป็น 3 ส่วนหลัก: เมนูหลัก เมนูย่อย และผลลัพธ์",
  "ใช้ปุ่มเมนูเพื่อเปลี่ยนเมนูย่อยที่กำลังแสดง ",
  "กดไอคอนบันทึก ",
  " ในเมนูย่อยเพื่อบันทึกการตั้งค่าของเมนูย่อยนั้น (เฉพาะเมนูย่อยนั้นเท่านั้น)",
  "คุณสามารถเลือกรูปภาพหนึ่งภาพหรือมากกว่านั้นได้โดยคลิกที่รูปภาพเหล่านั้นในส่วนผลลัพธ์ ",
  "โปรดสังเกตว่ารูปภาพขนาดใหญ่จะมีไอคอน ",
  " \"เปิดในแท็บใหม่\" ",
  " หากต้องการดาวน์โหลดรูปภาพที่เลือก ให้คลิกไอคอนดาวน์โหลดรูปภาพที่เลือก ",
  "",
  " หากต้องการดาวน์โหลดรูปภาพทั้งหมดในส่วนผลลัพธ์ ให้กดไอคอนดาวน์โหลดทั้งหมด",
  "",
  "ไอคอนสุดท้ายในเมนูหลัก ",
  " จะเปิดเมนูแบบดรอปดาวน์ ซึ่งคุณจะพบลิงก์ไปยังคู่มือนี้ รวมถึงลิงก์อื่นๆ"
],
"ko": [
  "사용자 가이드",
    "이미지 다운로더를 사용하려면 확장 프로그램 아이콘을 누릅니다. 그러면 팝업 창(또는 사이드 패널 - 디스플레이 하위 메뉴 섹션 참조)이 열리고 웹 페이지에서 이미지를 스캔하기 시작합니다. 팝업 메뉴는 3가지 주요 부분으로 나뉩니다. 주 메뉴, 하위 메뉴, 결과입니다.",
    "메뉴 버튼을 사용하여 표시되는 하위 메뉴를 변경합니다.",
    "하위 메뉴에서 저장 아이콘 ",
    " 을 눌러 해당 하위 메뉴의 설정을 저장합니다(해당 하위 메뉴의 설정만).",
    "결과 부분에서 이미지를 클릭하여 하나 이상의 이미지를 선택할 수 있습니다.",
    "큰 이미지에는 이 ",
    " \"새 탭에서 열기\" 아이콘이 있습니다.",
    "선택한 이미지를 다운로드하려면 선택한 이미지 다운로드 아이콘 ",
    " 을 클릭합니다.",
    "결과 부분의 모든 이미지를 다운로드하려면 모두 다운로드 아이콘을 누릅니다",".",
    "주 메뉴의 마지막 아이콘 ",
    " 을 누르면 드롭다운 메뉴가 열리고, 거기에는 이 가이드에 대한 링크 등이 있습니다."
  ],
"ja": [
  "ユーザーガイド",
  "画像ダウンローダーを使用するには、拡張機能アイコンを押します。これにより、ポップアップ ウィンドウ (またはサイド パネル - サブメニューの表示セクションを参照) が開き、Web ページの画像のスキャンが開始されます。ポップアップ メニューは、メイン メニュー、サブメニュー、結果の 3 つの主要な部分に分かれています。",
  "メニュー ボタンを使用して、表示されるサブメニューを変更します。",
  "サブメニューの保存アイコンを押すと、",
  " そのサブメニューの設定が保存されます (そのサブメニューのみ)。",
  "結果部分で画像をクリックすると、1 つまたは複数の画像を選択できます。",
  "大きな画像には ",
  " 「新しいタブで開く」アイコンがあることに注意してください。",
  "選択した画像をダウンロードするには、選択した画像をダウンロード アイコンをクリックします。",
  " ",
  "結果部分にあるすべての画像をダウンロードするには、すべてをダウンロード アイコンを押します。",
  " ",
  "メイン メニューの最後のアイコンをクリックすると、",
  " ドロップダウン メニューが開き、その中にこのガイドへのリンクなどが表示されます。"
]
      }
      const size = {
        "en": ["Size submenu","This submenu is opened by default, use it to filter images by pixel width and height. You can use the ","S M L A ","buttons to quickly set the filter settings to show ","S","mall, ", "M","edium, ","L"
          ,"arge, or ","A","ll images. Leave the max values empty if you don't want to set an upper limit."],
          "ja": [
            "サイズサブメニュー",
            "このサブメニューはデフォルトで開かれており、ピクセル幅と高さで画像をフィルターするために使用します。"," S M L A ",
            "ボタンを使用すると、フィルター設定をすばやく設定して、小さい",
            " (S)、",
            "中",
            " (M)、",
            "大きい",
            " (L)、",
            "またはすべての画像",
            " (A)",
            " を表示できます。上限を設定しない場合は、最大値を空のままにしておきます。"
        ],
        "ko": [
          "크기 하위 메뉴",
          "이 하위 메뉴는 기본적으로 열리며, 픽셀 너비와 높이로 이미지를 필터링하는 데 사용합니다. ",
          "S M L A ",
          "버튼을 사용하여 필터 설정을 빠르게 설정하여 작은",
          " (S), ",
          "중간",
          " (M), ",
          "큰",
          " (L), ",
          "또는 모든 이미지",
          " (A)",
          "를 표시할 수 있습니다. 상한을 설정하지 않으려면 최대값을 비워 둡니다."
      ],
      "th": [
        "เมนูย่อยขนาด",
        "เมนูย่อยนี้จะเปิดขึ้นตามค่าเริ่มต้น ใช้เพื่อกรองภาพตามความกว้างและความสูงแบบพิกเซล คุณสามารถใช้ปุ่ม ",
        "S M L A ",
        "เพื่อตั้งค่าตัวกรองอย่างรวดเร็วเพื่อแสดงภาพขนาดเล็ก",
        " (S), ",
        "ขนาดกลาง",
        " (M), ",
        "ขนาดใหญ่",
        " (L), ",
        "หรือภาพทั้งหมด",
        " (A)",
        " ปล่อยค่าสูงสุดว่างไว้หากคุณไม่ต้องการตั้งค่าขีดจำกัดสูงสุด."
    ],
    "vi": [
      "Menu phụ kích thước", 
      "Menu phụ này được mở theo mặc định, sử dụng để lọc hình ảnh theo chiều rộng và chiều cao pixel. Bạn có thể sử dụng các nút ", 
      "S M L A ", 
      "để nhanh chóng thiết lập cài đặt bộ lọc để hiển thị nhỏ", 
      " (S), ", 
      "trung bình", 
      " (M), ", 
      "lớn", 
      " (L), ", 
      "hoặc tất cả hình ảnh", 
      " (A)", 
      ". Để trống các giá trị tối đa nếu bạn không muốn đặt giới hạn trên."
  ],
  "zh_CN": [
    "大小子菜单",
    "此子菜单默认打开，使用它按像素宽度和高度过滤图像。您可以使用 ",
    "S M L A ",
    "按钮快速设置过滤器设置以显示小",
    " (S)、",
    "中",
    " (M)、",
    "大",
    " (L) ",
    "或所有图像",
    " (A)",
    "。如果您不想设置上限，请将最大值留空。"
],
"he": [
  "תת-תפריט גודל ",
  "תפריט משנה זה נפתח כברירת מחדל, השתמש בו כדי לסנן תמונות לפי רוחב וגובה פיקסלים. אתה יכול להשתמש בלחצני-",
  " S M L A ",
  "כדי להגדיר במהירות את הגדרות הסינון כך שיציגו תמונות קטנות",
  " (S), ",
  "בינוניות",
  " (M), ",
  "גדולות",
  " (L), ",
  "או את כל התמונות",
  " (A)",
  ". השאר את ערכי המקסימום ריקים אם אינך רוצה להגדיר גבול עליון."
],
"tr": [
  "Boyut alt menüsü",
  "Bu alt menü varsayılan olarak açıktır, bunu görüntüleri piksel cinsinden genişlik ve yüksekliğe göre filtrelemek için kullanın. Filtre ayarlarını küçük",
  " (S)"," orta"," (M)",", büyük"," (L)"," veya tüm resimleri"," (A)"," görüntüleyecek şekilde hızlıca ayarlamak için ",
  "S M L A ",
  "düğmelerini kullanabilirsiniz."
],
"de": [
  "Untermenü „Größe“",
  "Dieses Untermenü ist standardmäßig geöffnet. Filtern Sie Bilder nach Pixelbreite und -höhe. Mit den ",
  "Schaltflächen „S M L A“ ",
  "können Sie die Filtereinstellungen schnell so einstellen, dass nur kleine",
  " (S), ",
  "mittlere",
  " (M), ",
  "große",
  " (L) ",
  "oder alle Bilder",
  " (A) ",
  "angezeigt werden. Lassen Sie die Maximalwerte leer, wenn Sie keine Obergrenze festlegen möchten."
],
"it": [
  "Sottomenu Dimensioni",
  "Questo sottomenu è aperto di default, utilizzalo per filtrare le immagini in base alla larghezza e all'altezza dei pixel. Puoi usare i pulsanti",
  " S M L A ",
  "per impostare rapidamente le impostazioni del filtro per mostrare piccole",
  " (S), ",
  "medie",
  " (M), ",
  "grandi",
  " (L), ",
  "o tutte le immagini",
  " (A)",
  ". Lascia i valori massimi vuoti se non vuoi impostare un limite superiore."
],
"fr": [
  "Sous-menu Taille",
  "Ce sous-menu est ouvert par défaut. Utilisez-le pour filtrer les images par largeur et hauteur en pixels. Vous pouvez utiliser les boutons",
  " S, M, L, A ",
  "pour définir rapidement les paramètres de filtre et afficher les images de petite taille",
  " (S), ",
  "moyennes",
  " (M), ",
  "grandes",
  " (L), ",
  "ou toutes les images",
  " (A)",
  ". Laissez les valeurs maximales vides si vous ne souhaitez pas définir de limite supérieure."
],
"pt": [
  "Submenu Tamanho",
  "Este submenu é aberto por padrão, use-o para filtrar imagens por largura e altura de pixel. Você pode usar os botões ",
  "S M L A ",
  "para definir rapidamente as configurações do filtro para mostrar pequeno"," (S), ",
  "médio"," (M), ",
  "grande"," (L), ",
  "ou todas as imagens"," (A)",". Deixe os valores máximos vazios se não quiser definir um limite superior."
],
"es": ["Submenú Tamaño", "Este submenú se abre por defecto. Úselo para filtrar imágenes por ancho y alto en píxeles. Puede usar los botones"," S M L A ", "para configurar rápidamente el filtro y mostrar imágenes pequeñas", " (S), ", "medianas", " (M), ", "grandes", " (L), ", "o todas", " (A)", ". Deje los valores máximos en blanco si no desea establecer un límite superior."],
"hu": ["Méret almenü","Ez az almenü alapértelmezés szerint megnyílik, ezzel szűrheti a képeket pixelszélesség és -magasság szerint. Az ","S M L A ","gombokkal gyorsan beállíthatja a szűrőbeállításokat úgy, hogy kicsi"," (S), ","közepes"," (M), ","nagy"," (L) ","vagy az összes kép"," (A) ","jelenjen meg. Ha nem akar felső határt beállítani, hagyja üresen a maximális értékeket."],
"zh_TW": ["大小子選單", "此子選單預設為打開，使用它來按像素寬度和高度過濾圖像。您可以使用 ", "S M L A ", "按鈕快速設定濾鏡設定以顯示小", " (S)、", "中", " (M)、", "大", " (L)、", "或所有影像", " (A)。", "如果不想設定上限，請將最大值留空。"],
"ru": ["Подменю размера","Это подменю открыто по умолчанию, используйте его для фильтрации изображений по ширине и высоте в пикселях. Вы можете использовать кнопки ","S M L A",", чтобы быстро задать настройки фильтра для отображения маленьких"," (S), ","средних"," (M), ","больших"," (L), ","или всех изображений"," (A)",". Оставьте максимальные значения пустыми, если вы не хотите устанавливать верхний предел."],
          "temp": ["Size submenu","This submenu is opened by default, use it to filter images by pixel width and height. You can use the ","S M L A ","buttons to quickly set the filter settings to show small"," (S), ","medium"," (M), ","large"," (L), ","or all images"," (A)",". Leave the max values empty if you don't want to set an upper limit."]
      };
      const typeLayout = {
      "en": [
    "Type submenu",
    "Here you set the file extensions you want to filter.",
    "Layout submenu",
    "You can use this submenu to filter images based on orientation by pressing one of the left buttons, or more specifically by width to height ratio by changing the sign and value in the input box. Leave the ratio empty to show all ratios (or just press the \"All\" button)."
  ],
  "pt": [
    "Submenu de tipo",
    "Aqui você define as extensões de arquivo que deseja filtrar.",
    "Submenu de layout",
    "Você pode usar este submenu para filtrar imagens com base na orientação pressionando um dos botões à esquerda, ou mais especificamente pela proporção largura-altura, alterando o sinal e o valor na caixa de entrada. Deixe a proporção vazia para mostrar todas as proporções (ou simplesmente pressione o botão \"Todos\")."
  ],
  "es": [
    "Submenú de tipo",
    "Aquí configuras las extensiones de archivo que deseas filtrar.",
    "Submenú de diseño",
    "Puedes usar este submenú para filtrar imágenes según la orientación presionando uno de los botones de la izquierda, o más específicamente por la relación de ancho a alto cambiando el signo y el valor en el cuadro de entrada. Deja la relación vacía para mostrar todas las proporciones (o simplemente presiona el botón \"Todos\")."
  ],
  "fr": [
    "Sous-menu Type",
    "Ici, vous définissez les extensions de fichier que vous souhaitez filtrer.",
    "Sous-menu Disposition",
    "Vous pouvez utiliser ce sous-menu pour filtrer les images en fonction de l'orientation en appuyant sur l'un des boutons à gauche, ou plus spécifiquement en fonction du rapport largeur/hauteur en modifiant le signe et la valeur dans la boîte de saisie. Laissez le rapport vide pour afficher tous les rapports (ou appuyez simplement sur le bouton \"Tous\")."
  ],
  "de": [
    "Typ-Untermenü",
    "Hier legen Sie die Dateierweiterungen fest, die Sie filtern möchten.",
    "Layout-Untermenü",
    "Sie können dieses Untermenü verwenden, um Bilder nach ihrer Ausrichtung zu filtern, indem Sie eine der linken Tasten drücken, oder genauer gesagt nach dem Breiten-Höhen-Verhältnis, indem Sie das Vorzeichen und den Wert im Eingabefeld ändern. Lassen Sie das Verhältnis leer, um alle Verhältnisse anzuzeigen (oder drücken Sie einfach die Schaltfläche \"Alle\")."
  ],
  "it": [
    "Sottomenu Tipo",
    "Qui imposti le estensioni di file che desideri filtrare.",
    "Sottomenu Layout",
    "Puoi usare questo sottomenu per filtrare le immagini in base all'orientamento premendo uno dei pulsanti a sinistra, o più precisamente in base al rapporto larghezza-altezza modificando il segno e il valore nella casella di input. Lascia il rapporto vuoto per mostrare tutti i rapporti (o premi semplicemente il pulsante \"Tutti\")."
  ],
  "hu": [
    "Típus almenü",
    "Itt állíthatja be a szűrni kívánt fájlkiterjesztéseket.",
    "Elrendezés almenü",
    "Ezt az almenüt használhatja a képek tájolás szerinti szűrésére a bal oldali gombok egyikének megnyomásával, vagy konkrétabban a szélesség-magasság arány alapján a jel és az érték megváltoztatásával a beviteli mezőben. Hagyja üresen az arányt az összes arány megjelenítéséhez (vagy egyszerűen nyomja meg az \"Összes\" gombot)."
  ],
  "tr": [
    "Tür alt menüsü",
    "Burada filtrelemek istediğiniz dosya uzantılarını ayarlarsınız.",
    "Düzen alt menüsü",
    "Bu alt menüyü kullanarak görüntüleri yönelime göre filtreleyebilir veya daha spesifik olarak genişlik-yükseklik oranına göre işaret ve değeri giriş kutusunda değiştirerek filtreleyebilirsiniz. Tüm oranları göstermek için oranı boş bırakın (veya sadece \"Tümü\" düğmesine basın)."
  ],
  "ru": [
    "Подменю типа",
    "Здесь вы устанавливаете расширения файлов, которые хотите отфильтровать.",
    "Подменю макета",
    "Вы можете использовать это подменю для фильтрации изображений по ориентации, нажав одну из кнопок слева, или более конкретно – по соотношению ширины к высоте, изменяя знак и значение в поле ввода. Оставьте поле пустым, чтобы показать все соотношения (или просто нажмите кнопку \"Все\")."
  ],
  "he": [
    "תת-תפריט סוג",
    "כאן ניתן להגדיר את סיומות הקבצים שברצונך לסנן.",
    "תת-תפריט פריסה",
    "באפשרותך להשתמש בתת-תפריט זה כדי לסנן תמונות לפי כיוון באמצעות לחיצה על אחד מהלחצנים השמאליים, או באופן ספציפי יותר לפי יחס רוחב-גובה על ידי שינוי הסימן והערך בתיבת הקלט. השאר את היחס ריק כדי להציג את כל היחסים (או פשוט לחץ על כפתור \"הכל\")."
  ],
  "vi": [
    "Trình đơn phụ loại",
    "Tại đây bạn thiết lập các phần mở rộng tệp muốn lọc.",
    "Trình đơn phụ bố cục",
    "Bạn có thể sử dụng trình đơn phụ này để lọc hình ảnh dựa trên hướng bằng cách nhấn một trong các nút bên trái, hoặc cụ thể hơn là theo tỷ lệ chiều rộng và chiều cao bằng cách thay đổi dấu và giá trị trong hộp nhập. Để trống tỷ lệ để hiển thị tất cả các tỷ lệ (hoặc chỉ cần nhấn nút \"Tất cả\")."
  ],
  "th": [
    "เมนูย่อยประเภท",
    "ที่นี่คุณสามารถตั้งค่าส่วนขยายไฟล์ที่คุณต้องการกรอง",
    "เมนูย่อยเค้าโครง",
    "คุณสามารถใช้เมนูย่อยนี้เพื่อกรองรูปภาพตามแนวการวาง โดยกดปุ่มด้านซ้าย หรือกรองตามอัตราส่วนความกว้างต่อความสูงโดยเปลี่ยนเครื่องหมายและค่าที่กล่องป้อนข้อมูล ปล่อยอัตราส่วนว่างไว้เพื่อแสดงทุกอัตราส่วน (หรือกดปุ่ม \"ทั้งหมด\")"
  ],
  "ko": [
    "유형 하위 메뉴",
    "여기에서 필터링할 파일 확장자를 설정할 수 있습니다.",
    "레이아웃 하위 메뉴",
    "이 하위 메뉴를 사용하여 왼쪽 버튼 중 하나를 눌러 이미지 방향별로 필터링하거나, 입력 상자에서 기호와 값을 변경하여 너비 대비 높이 비율별로 더 구체적으로 필터링할 수 있습니다. 모든 비율을 표시하려면 비율을 비워두세요 (또는 '모두' 버튼을 누르세요)."
  ],
  "zh_CN": [
    "类型子菜单",
    "在这里设置要过滤的文件扩展名。",
    "布局子菜单",
    "您可以使用此子菜单通过按左侧按钮按方向筛选图片，或者更具体地通过更改输入框中的符号和值按宽高比进行筛选。留空比率以显示所有比例（或直接按“全部”按钮）。"
  ],
  "zh_TW": [
    "類型子選單",
    "在這裡設定要過濾的檔案副檔名。",
    "佈局子選單",
    "您可以使用此子選單透過按左側按鈕依方向篩選圖片，或更具體地透過更改輸入框中的符號和值依寬高比篩選。留空比例以顯示所有比例（或直接按「全部」按鈕）。"
  ],
  "ja": [
    "タイプサブメニュー",
    "ここでフィルタリングしたいファイル拡張子を設定します。",
    "レイアウトサブメニュー",
    "このサブメニューを使用すると、左のボタンを押して画像を向きでフィルタリングするか、入力ボックスで符号と値を変更して、幅と高さの比率でより具体的にフィルタリングできます。比率を空のままにすると、すべての比率が表示されます（または「すべて」ボタンを押してください）。"
  ]
}
const advanced = {
  "en": ["Advanced submenu",
    "The Image Downloader extension offers advanced filtering options, such as filtering images based on partial URL matches. You can choose to include or exclude images whose URLs contain a specific sequence. To disable this filter, leave the URL field empty. You can also press the clean icon ",
    " to remove all URLs. ",
    "To match a URL using multiple terms, separate them with a semicolon (without spaces). For example: wikipedia;google;pixabay.",
    "Another advanced filtering option in this submenu is filtering by image version. Some websites provide alternative versions of an image, typically in different sizes. Here, you can choose which version to capture. By default, the extension captures the largest version that meets your other filters. Other options include capturing the smallest version, all versions, or only those with unique dimensions."],
    "pt": ["Submenu avançado",
      "A extensão Baixador de imagens oferece opções avançadas de filtragem, como filtrar imagens com base em correspondências parciais de URL. Você pode escolher incluir ou excluir imagens cujos URLs contenham uma sequência específica. Para desabilitar esse filtro, deixe o campo URL vazio. Você também pode pressionar o ícone de limpeza ",
      " para remover todos os URLs. ",
      "Para corresponder a um URL usando vários termos, separe-os com ponto e vírgula (sem espaços). Por exemplo: wikipedia;google;pixabay.",
      "Outra opção avançada de filtragem neste submenu é filtrar por versão da imagem. Alguns sites fornecem versões alternativas de uma imagem, normalmente em tamanhos diferentes. Aqui, você pode escolher qual versão capturar. Por padrão, a extensão captura a maior versão que atende aos seus outros filtros. Outras opções incluem capturar a menor versão, todas as versões ou apenas aquelas com dimensões exclusivas."],
      "es": ["Submenú avanzado",
        "La extensión Descargador de Imágenes ofrece opciones avanzadas de filtrado, como el filtrado de imágenes basado en coincidencias parciales de URL. Puede incluir o excluir imágenes cuyas URL contengan una secuencia específica. Para desactivar este filtro, deje el campo de URL vacío. También puede pulsar el icono de limpieza ",
        " para eliminar todas las URL. ",
        "Para buscar coincidencias con una URL utilizando varios términos, sepárelos con punto y coma (sin espacios). Por ejemplo: wikipedia;google;pixabay.",
        "Otra opción avanzada de filtrado en este submenú es el filtrado por versión de la imagen. Algunos sitios web ofrecen versiones alternativas de una imagen, generalmente en diferentes tamaños. Aquí puede elegir qué versión capturar. Por defecto, la extensión captura la versión más grande que cumple con los otros filtros. Otras opciones incluyen capturar la versión más pequeña, todas las versiones o solo aquellas con dimensiones únicas."],
          "fr": ["Sous-menu Avancé",
            "L'extension Téléchargeur d'images propose des options de filtrage avancées, comme le filtrage des images en fonction des correspondances d'URL partielles. Vous pouvez choisir d'inclure ou d'exclure les images dont les URL contiennent une séquence spécifique. Pour désactiver ce filtre, laissez le champ URL vide. Vous pouvez également appuyer sur l'icône de nettoyage ",
            " pour supprimer toutes les URL. ",
            "Pour rechercher une URL contenant plusieurs termes, séparez-les par un point-virgule (sans espace). Par exemple : wikipedia;google;pixabay.",
            "Une autre option de filtrage avancée de ce sous-menu est le filtrage par version d'image. Certains sites web proposent des versions alternatives d'une image, généralement dans des tailles différentes. Vous pouvez choisir la version à capturer. Par défaut, l'extension capture la version la plus grande correspondant à vos autres filtres. Vous pouvez également capturer la version la plus petite, toutes les versions ou uniquement celles ayant des dimensions uniques."],
              "de": ["Erweitert",
                "Die Erweiterung „Bild-Downloader“ bietet erweiterte Filteroptionen, beispielsweise das Filtern von Bildern anhand von teilweisen URL-Übereinstimmungen. Sie können Bilder, deren URLs eine bestimmte Sequenz enthalten, ein- oder ausschließen. Um diesen Filter zu deaktivieren, lassen Sie das URL-Feld leer. Alternativ können Sie auf das Löschsymbol klicken ",
                ", um alle URLs zu entfernen. ",
                "Um eine URL mit mehreren Begriffen abzugleichen, trennen Sie diese durch ein Semikolon (ohne Leerzeichen). Beispiel: wikipedia;google;pixabay.",
                "Eine weitere erweiterte Filteroption in diesem Untermenü ist das Filtern nach Bildversion. Einige Websites bieten alternative Versionen eines Bildes an, typischerweise in unterschiedlichen Größen. Hier können Sie auswählen, welche Version erfasst werden soll. Standardmäßig erfasst die Erweiterung die größte Version, die Ihren anderen Filtern entspricht. Weitere Optionen sind die Erfassung der kleinsten Version, aller Versionen oder nur der Versionen mit unterschiedlichen Abmessungen."],
                "it": ["Sottomenu avanzato",
                  "L'estensione Scaricatore di immagini offre opzioni di filtro avanzate, come il filtraggio delle immagini in base alle corrispondenze parziali degli URL. Puoi scegliere di includere o escludere le immagini i cui URL contengono una sequenza specifica. Per disattivare questo filtro, lascia vuoto il campo URL. Puoi anche premere l'icona di pulizia ",
                  " per rimuovere tutti gli URL. ",
                  "Per abbinare un URL utilizzando più termini, separali con un punto e virgola (senza spazi). Ad esempio: wikipedia;google;pixabay.",
                  "Un'altra opzione di filtro avanzata in questo sottomenu è il filtro per versione dell'immagine. Alcuni siti Web forniscono versioni alternative di un'immagine, in genere in diverse dimensioni. Qui puoi scegliere quale versione catturare. Per impostazione predefinita, l'estensione cattura la versione più grande che soddisfa gli altri filtri. Altre opzioni includono la cattura della versione più piccola, di tutte le versioni o solo di quelle con dimensioni univoche."],
                  "hu": ["Speciális almenü",
                    "Az Képletöltő bővítmény speciális szűrési lehetőségeket kínál, például a képek szűrését részleges URL-egyezések alapján. Dönthet úgy, hogy felveszi vagy kizárja azokat a képeket, amelyek URL-je meghatározott sorozatot tartalmaz. A szűrő letiltásához hagyja üresen az URL mezőt. Az összes URL eltávolításához megnyomhatja a tiszta ikont is",
                    ". Ha több kifejezést használ egy URL-hez, pontosvesszővel válassza el őket (szóközök nélkül). Például: wikipedia;google;pixabay.",
                    "","Egy másik speciális szűrési lehetőség ebben az almenüben a képverzió szerinti szűrés. Egyes webhelyek a képek alternatív változatait kínálják, jellemzően különböző méretekben. Itt kiválaszthatja, hogy melyik verziót rögzítse. Alapértelmezés szerint a bővítmény a legnagyobb verziót rögzíti, amely megfelel a többi szűrőjének. További lehetőségek közé tartozik a legkisebb verzió, az összes verzió vagy csak az egyedi méretű verziók rögzítése."],
                    "tr": ["Gelişmiş alt menü",
                      "Resim İndirici uzantısı, kısmi URL eşleşmelerine göre görüntüleri filtreleme gibi gelişmiş filtreleme seçenekleri sunar. URL'leri belirli bir dizi içeren görüntüleri dahil etmeyi veya hariç tutmayı seçebilirsiniz. Bu filtreyi devre dışı bırakmak için URL alanını boş bırakın. Ayrıca tüm URL'leri kaldırmak için temiz simgesine basabilirsiniz ",
                      ". Birden fazla terim kullanarak bir URL'yi eşleştirmek için bunları noktalı virgülle (boşluksuz) ayırın. Örneğin: wikipedia;google;pixabay.",
                      ,"Bu alt menüdeki bir diğer gelişmiş filtreleme seçeneği de görüntü sürümüne göre filtrelemedir. Bazı web siteleri, genellikle farklı boyutlarda bir görüntünün alternatif sürümlerini sağlar. Burada, hangi sürümü yakalayacağınızı seçebilirsiniz. Varsayılan olarak, uzantı diğer filtrelerinizi karşılayan en büyük sürümü yakalar. Diğer seçenekler arasında en küçük sürümü, tüm sürümleri veya yalnızca benzersiz boyutlara sahip olanları yakalamak yer alır."],
                      "ru": ["Расширенное подменю",
                        "Расширение Загрузчик изображений предлагает расширенные параметры фильтрации, такие как фильтрация изображений на основе частичных совпадений URL. Вы можете включить или исключить изображения, URL-адреса которых содержат определенную последовательность. Чтобы отключить этот фильтр, оставьте поле URL пустым. Вы также можете нажать значок очистки ",
                        ", чтобы удалить все URL-адреса. ",
                        "Чтобы сопоставить URL-адрес с несколькими терминами, разделите их точкой с запятой (без пробелов). Например: wikipedia;google;pixabay.",
                        "Еще один расширенный параметр фильтрации в этом подменю — фильтрация по версии изображения. Некоторые веб-сайты предоставляют альтернативные версии изображения, как правило, разных размеров. Здесь вы можете выбрать, какую версию захватывать. По умолчанию расширение захватывает самую большую версию, которая соответствует вашим другим фильтрам. Другие параметры включают захват самой маленькой версии, всех версий или только тех, которые имеют уникальные размеры."],
                        "he": ["תת-תפריט מתקדם",
                          "תוסף הורדת התמונות מציע אפשרויות סינון מתקדמות, כגון סינון תמונות על סמך התאמות חלקיות של כתובות אתרים. אתה יכול לבחור לכלול או לא לכלול תמונות שכתובות האתר שלהן מכילות רצף מסוים. כדי להשבית מסנן זה, השאר את שדה כתובת האתר ריק. אתה יכול גם ללחוץ על האייקון נקה ",
                          " כדי להסיר את כל כתובות האתרים. ",
                          "כדי להתאים כתובת אתר באמצעות מונחים מרובים, הפרד אותם באמצעות נקודה-פסיק (ללא רווחים). לדוגמה: wikipedia;google;pixabay.",
                          "אפשרות סינון מתקדמת נוספת בתת תפריט זה היא סינון לפי גרסת תמונה. חלק מהאתרים מספקים גרסאות חלופיות של תמונה, בדרך כלל בגדלים שונים. כאן, אתה יכול לבחור איזו גרסה ללכוד. כברירת מחדל, התוסף לוכד את הגרסה הגדולה ביותר העונה למסננים האחרים שלך. אפשרויות אחרות כוללות לכידת הגרסה הקטנה ביותר, כל הגרסאות, או רק אלה עם ממדים ייחודיים."],
                          "th": ["เมนูย่อยขั้นสูง",
                            "ส่วนขยายโปรแกรมดาวน์โหลดรูปภาพมีตัวเลือกการกรองขั้นสูง เช่น การกรองรูปภาพตามการจับคู่ URL บางส่วน คุณสามารถเลือกที่จะรวมหรือไม่รวมรูปภาพที่ URL มีลำดับเฉพาะ หากต้องการปิดใช้งานตัวกรองนี้ ให้ปล่อยช่อง URL ว่างไว้ นอกจากนี้ คุณยังสามารถกดไอคอน ",
                            " เพื่อลบ URL ทั้งหมดได้อีกด้วย ",
                            "หากต้องการจับคู่ URL โดยใช้คำหลายคำ ให้คั่นด้วยเครื่องหมายเซมิโคลอน (โดยไม่เว้นวรรค) ตัวอย่างเช่น wikipedia;google;pixabay",
                            "ตัวเลือกการกรองขั้นสูงอีกตัวเลือกหนึ่งในเมนูย่อยนี้คือการกรองตามเวอร์ชันของรูปภาพ เว็บไซต์บางแห่งมีเวอร์ชันทางเลือกของรูปภาพ โดยปกติจะมีขนาดต่างกัน ที่นี่ คุณสามารถเลือกเวอร์ชันที่จะจับภาพได้ โดยค่าเริ่มต้น ส่วนขยายจะจับภาพเวอร์ชันที่ใหญ่ที่สุดที่ตรงกับตัวกรองอื่นๆ ของคุณ ตัวเลือกอื่นๆ ได้แก่ จับภาพเวอร์ชันที่เล็กที่สุด เวอร์ชันทั้งหมด หรือเฉพาะเวอร์ชันที่มีขนาดเฉพาะ"],
                            "vi": ["Menu phụ nâng cao",
                              "Phần mở rộng trình tải xuống hình ảnh cung cấp các tùy chọn lọc nâng cao, chẳng hạn như lọc hình ảnh dựa trên các URL trùng khớp một phần. Bạn có thể chọn bao gồm hoặc loại trừ hình ảnh có URL chứa một chuỗi cụ thể. Để tắt bộ lọc này, hãy để trống trường URL. Bạn cũng có thể nhấn vào biểu tượng ",
                              " để xóa tất cả các URL. ",
                              "Để khớp một URL bằng nhiều thuật ngữ, hãy phân tách chúng bằng dấu chấm phẩy (không có khoảng trắng). Ví dụ: wikipedia;google;pixabay.",
                              "Một tùy chọn lọc nâng cao khác trong menu phụ này là lọc theo phiên bản hình ảnh. Một số trang web cung cấp các phiên bản thay thế của hình ảnh, thường ở các kích thước khác nhau. Tại đây, bạn có thể chọn phiên bản nào để chụp. Theo mặc định, phần mở rộng sẽ chụp phiên bản lớn nhất đáp ứng các bộ lọc khác của bạn. Các tùy chọn khác bao gồm chụp phiên bản nhỏ nhất, tất cả các phiên bản hoặc chỉ những phiên bản có kích thước duy nhất."],
                              "ko": ["고급 하위 메뉴",
                                "이미지 다운로더 확장 프로그램은 부분 URL 일치에 따라 이미지를 필터링하는 것과 같은 고급 필터링 옵션을 제공합니다. URL에 특정 시퀀스가 포함된 이미지를 포함하거나 제외하도록 선택할 수 있습니다. 이 필터를 비활성화하려면 URL 필드를 비워 둡니다. 아이콘을 눌러 ",
                                " 모든 URL을 제거할 수도 있습니다. ",
                                "여러 용어를 사용하여 URL을 일치시키려면 세미콜론(공백 없음)으로 구분합니다. 예: wikipedia;google;pixabay.",
                                "이 하위 메뉴의 또 다른 고급 필터링 옵션은 이미지 버전별 필터링입니다. 일부 웹사이트는 일반적으로 크기가 다른 대체 버전의 이미지를 제공합니다. 여기에서 캡처할 버전을 선택할 수 있습니다. 기본적으로 확장 프로그램은 다른 필터를 충족하는 가장 큰 버전을 캡처합니다. 다른 옵션으로는 가장 작은 버전, 모든 버전 또는 고유한 크기가 있는 버전만 캡처하는 것이 있습니다."],
                                "ja": ["詳細サブメニュー",
                                  "画像ダウンローダー拡張機能には、部分的な URL 一致に基づいて画像をフィルタリングするなど、高度なフィルタリング オプションがあります。URL に特定のシーケンスが含まれる画像を含めるか除外するかを選択できます。このフィルタを無効にするには、URL フィールドを空のままにします。アイコンを押して ",
                                  " すべての URL を削除することもできます。",
                                  "複数の用語を使用して URL を一致させるには、セミコロン (スペースなし) で区切ります。例: wikipedia;google;pixabay。",
                                  "このサブメニューのもう 1 つの高度なフィルタリング オプションは、画像バージョンによるフィルタリングです。一部の Web サイトでは、通常は異なるサイズの画像の代替バージョンが提供されています。ここで、キャプチャするバージョンを選択できます。デフォルトでは、拡張機能は他のフィルタを満たす最大のバージョンをキャプチャします。その他のオプションには、最小バージョン、すべてのバージョン、または一意の寸法を持つバージョンのみをキャプチャすることが含まれます。"],
                                  "zh_CN": ["高级子菜单",
                                    "图像下载器扩展程序提供高级过滤选项，例如根据部分 URL 匹配过滤图像。您可以选择包含或排除 URL 包含特定序列的图像。要禁用此过滤器，请将 URL 字段留空。您也可以按图标 ",
                                    " 删除所有 URL。",
                                    "要使用多个术语匹配 URL，请用分号（不带空格）分隔它们。例如：wikipedia;google;pixabay。",
                                    "此子菜单中的另一个高级过滤选项是按图像版本过滤。一些网站提供图像的替代版本，通常大小不同。在这里，您可以选择要捕获哪个版本。默认情况下，扩展程序会捕获符合其他过滤器的最大版本。其他选项包括捕获最小版本、所有版本或仅捕获具有唯一尺寸的版本。"],
                                    "zh_TW": ["進階子選單",
                                      "圖像下載器擴充功能提供了高級過濾選項，例如根據部分 URL 匹配過濾圖像。您可以選擇包含或排除 URL 包含特定序列的圖像。若要停用此過濾器，請將 URL 欄位留空。您也可以按圖示刪除所有 URL ",
                                      " 。若要使用多個術語來匹配 URL，請用分號（不含空格）分隔它們。例如：wikipedia；google；pixabay。","",
                                      "此子選單中的另一個進階過濾選項是按影像版本過濾。一些網站提供圖像的替代版本，通常尺寸不同。在這裡，您可以選擇要捕獲哪個版本。預設情況下，擴充功能會捕捉符合其他濾鏡的最大版本。其他選項包括捕獲最小版本、所有版本或僅捕獲具有獨特尺寸的版本。"]
                                  };
    const save = {
      'en':["(Save config) submenu","Here you can set the folders, file names, and auto-conversion of file formats.",
        "All folders are saved in your downloads folder. By default each time you download an image the extension generates an increasing number as a folder name, made from the date and time. To set the folder manually change the radio button to the custom field. Leave it empty to save directly to your downloads folder or set a custom name, you can also set a sub folder.",
        "Depending on your settings file names will be generated automatically or with a custom prefix name followed by an auto generated number. If you set the prefix to cat, then upon downloading 3 file, the names of the files will be: cat1, cat2, cat3.",
        "To set the auto format converter, press the convert icon ",
        ". In the pop-up window that opens, choose the file format to convert to. You can convert to JPEG, PNG, or WebP. For JPEG and WebP, you can also set the quality between 1 and 10, with 10 being the highest quality and 1 being the smallest file size. PNG is a lossless format, so the quality is always set to 10."],
        "ja": ["(設定を保存)サブメニュー",
          "ここでは、フォルダー、ファイル名、ファイル形式の自動変換を設定できます。",
          "すべてのフォルダーはダウンロードフォルダーに保存されます。デフォルトでは、画像をダウンロードするたびに、拡張機能は日付と時刻から作成された増加する番号をフォルダー名として生成します。フォルダーを手動で設定するには、ラジオボタンをカスタムフィールドに変更します。空のままにしてダウンロードフォルダーに直接保存するか、カスタム名を設定します。サブフォルダーを設定することもできます。",
          "設定に応じて、ファイル名は自動的に生成されるか、カスタムのプレフィックス名とそれに続く自動生成された番号を使用して生成されます。プレフィックスを cat に設定すると、3 つのファイルをダウンロードすると、ファイル名は cat1、cat2、cat3 になります。",
          "自動フォーマットコンバーターを設定するには、変換アイコンを押します",
          "。開いたポップアップウィンドウで、変換するファイル形式を選択します。JPEG、PNG、WebP に変換できます。JPEG と WebP の場合、品質を 1 ～ 10 の間で設定することもできます。10 が最高品質、1 が最小ファイルサイズになります。PNG はロスレス形式なので、品質は常に 10 に設定されます。"],
          "zh_CN": ["（保存配置）子菜单",
            "在这里您可以设置文件夹、文件名以及文件格式的自动转换。",
            "所有文件夹都保存在您的下载文件夹中。默认情况下，每次下载图像时，扩展程序都会生成一个由日期和时间组成的递增数字作为文件夹名称。要手动设置文件夹，请将单选按钮更改为自定义字段。将其留空以直接保存到您的下载文件夹或设置自定义名称，您也可以设置子文件夹。",
            "根据您的设置，文件名将自动生成或使用自定义前缀名称后跟自动生成的数字。如果将前缀设置为 cat，那么下载 3 个文件时，文件的名称将是：cat1、cat2、cat3。",
            "要设置自动格式转换器，请按转换图标 ",
            "。在打开的弹出窗口中，选择要转换的文件格式。您可以转换为 JPEG、PNG 或 WebP。对于 JPEG 和 WebP，您还可以将质量设置为 1 到 10 之间，其中 10 表示最高质量，1 表示最小文件大小。PNG 是无损格式，因此质量始终设置为 10。"],
            "zh_TW": ["（儲存配置）子選單",
    "在這裡您可以設定資料夾、檔案名稱以及檔案格式的自動轉換。",
    "所有資料夾都保存在您的下載資料夾中。預設情況下，每次下載映像時，擴充功能都會產生一個由日期和時間組成的遞增數字作為資料夾名稱。若要手動設定資料夾，請將單選按鈕變更為自訂欄位。將其留空以直接儲存到您的下載資料夾或設定自訂名稱，您也可以設定子資料夾。",
    "根據您的設置，檔案名稱將自動產生或使用自訂前綴名稱後跟自動產生的數字。如果將前綴設為 cat，下載 3 個檔案時，檔案的名稱將是：cat1、cat2、cat3。",
    "若要設定自動格式轉換器，請按轉換圖示 ",
    "。在開啟的彈出視窗中，選擇要轉換的檔案格式。您可以轉換為 JPEG、PNG 或 WebP。對於 JPEG 和 WebP，您還可以將品質設為 1 到 10 之間，其中 10 表示最高品質，1 表示最小檔案大小。PNG 是無損格式，因此品質始終設定為 10。"],
    "ko": ["(구성 저장) 하위 메뉴",
      "여기서 폴더, 파일 이름, 파일 형식의 자동 변환을 설정할 수 있습니다.",
      "모든 폴더는 다운로드 폴더에 저장됩니다. 기본적으로 이미지를 다운로드할 때마다 확장 프로그램은 날짜와 시간을 기반으로 폴더 이름으로 증가하는 숫자를 생성합니다. 폴더를 수동으로 설정하려면 라디오 버튼을 사용자 정의 필드로 변경하세요. 다운로드 폴더에 직접 저장하려면 비워두거나 사용자 지정 이름을 설정하세요. 하위 폴더도 설정할 수 있습니다.",
      "설정에 따라 파일 이름이 자동으로 생성되거나 사용자 정의 접두사 이름 뒤에 자동 생성된 숫자가 붙습니다. 접두사를 cat으로 설정하면 3개의 파일을 다운로드할 때 파일 이름은 cat1, cat2, cat3이 됩니다.",
      "자동 형식 변환기를 설정하려면 변환 아이콘을 누르세요 ",
      ". 팝업창이 열리면 변환할 파일 형식을 선택합니다. JPEG, PNG 또는 WebP로 변환할 수 있습니다. JPEG 및 WebP의 경우 품질을 1~10 사이로 설정할 수 있으며, 10이 가장 높은 품질이고 1이 가장 작은 파일 크기가 됩니다. PNG는 손실 없는 형식이므로 품질은 항상 10으로 설정됩니다."],
      "vi": ["(Lưu cấu hình) menu phụ",
        "Tại đây bạn có thể thiết lập thư mục, tên tệp và tự động chuyển đổi định dạng tệp.",
        "Tất cả các thư mục đều được lưu trong thư mục tải xuống của bạn. Theo mặc định, mỗi lần bạn tải xuống một hình ảnh, tiện ích mở rộng sẽ tạo ra một số tăng dần làm tên thư mục, được tạo thành từ ngày và giờ. Để thiết lập thư mục theo cách thủ công, hãy thay đổi nút radio thành trường tùy chỉnh. Để trống để lưu trực tiếp vào thư mục tải xuống hoặc đặt tên tùy chỉnh, bạn cũng có thể đặt thư mục con.",
        "Tùy thuộc vào cài đặt của bạn, tên tệp sẽ được tạo tự động hoặc có tiền tố tên tùy chỉnh theo sau là số tự động tạo. Nếu bạn đặt tiền tố là cat, thì khi tải xuống 3 tệp, tên của các tệp sẽ là: cat1, cat2, cat3.",
        "Để cài đặt trình chuyển đổi định dạng tự động, hãy nhấn vào biểu tượng chuyển đổi ",
        ". Trong cửa sổ bật lên mở ra, hãy chọn định dạng tệp để chuyển đổi. Bạn có thể chuyển đổi sang JPEG, PNG hoặc WebP. Đối với JPEG và WebP, bạn cũng có thể đặt chất lượng từ 1 đến 10, trong đó 10 là chất lượng cao nhất và 1 là kích thước tệp nhỏ nhất. PNG là định dạng không mất dữ liệu, vì vậy chất lượng luôn được đặt ở mức 10."],
        "th": ["เมนูย่อย (บันทึกการกำหนดค่า)",
          "คุณสามารถตั้งค่าโฟลเดอร์ ชื่อไฟล์ และการแปลงรูปแบบไฟล์อัตโนมัติได้ที่นี่",
          "โฟลเดอร์ทั้งหมดจะถูกบันทึกไว้ในโฟลเดอร์ดาวน์โหลดของคุณ ตามค่าเริ่มต้น ทุกครั้งที่คุณดาวน์โหลดรูปภาพ ส่วนขยายจะสร้างหมายเลขที่เพิ่มขึ้นเป็นชื่อโฟลเดอร์ โดยสร้างขึ้นจากวันที่และเวลา หากต้องการตั้งค่าโฟลเดอร์ด้วยตนเอง ให้เปลี่ยนปุ่มตัวเลือกเป็นฟิลด์ที่กำหนดเอง ปล่อยให้ว่างเปล่าเพื่อบันทึกโดยตรงไปยังโฟลเดอร์ดาวน์โหลดของคุณหรือตั้งชื่อแบบกำหนดเอง นอกจากนี้คุณยังตั้งค่าโฟลเดอร์ย่อยได้อีกด้วย",
          "ชื่อไฟล์จะถูกสร้างขึ้นโดยอัตโนมัติหรือโดยใช้ชื่อคำนำหน้าที่กำหนดเองตามด้วยหมายเลขที่สร้างโดยอัตโนมัติ ขึ้นอยู่กับการตั้งค่าของคุณ หากคุณตั้งค่าคำนำหน้าเป็น cat เมื่อดาวน์โหลดไฟล์ 3 ไฟล์ ชื่อของไฟล์จะเป็นดังนี้: cat1, cat2, cat3",
          "หากต้องการตั้งค่าตัวแปลงรูปแบบอัตโนมัติ ให้กดไอคอนแปลง ",
          " ในหน้าต่างป๊อปอัปที่เปิดขึ้น ให้เลือกรูปแบบไฟล์ที่จะแปลง คุณสามารถแปลงเป็น JPEG, PNG หรือ WebP ได้ สำหรับ JPEG และ WebP คุณยังสามารถตั้งค่าคุณภาพได้ระหว่าง 1 ถึง 10 โดย 10 คือคุณภาพสูงสุด และ 1 คือขนาดไฟล์ที่เล็กที่สุด PNG เป็นรูปแบบที่ไม่มีการสูญเสีย ดังนั้นคุณภาพจึงถูกตั้งเป็น 10 เสมอ."],
          "he": ["תת-תפריט הגדרות שמירה",
            "כאן אתה יכול להגדיר את התיקיות, שמות הקבצים והמרה אוטומטית של פורמטים של קבצים.",
            "כל התיקיות נשמרות בתיקיית ההורדות שלך. כברירת מחדל בכל פעם שאתה מוריד תמונה, התוסף מייצר מספר הולך וגדל כשם תיקיה, אשר מורכב מהתאריך והשעה. כדי להגדיר את התיקיה ידנית שנה את לחצן הבחירה לשדה המותאם אישית. השאר אותו ריק כדי לשמור ישירות בתיקיית ההורדות שלך או הגדר שם מותאם אישית, אתה יכול גם להגדיר תיקיית משנה.",
            "בהתאם להגדרות שלך שמות קבצים יופקו באופן אוטומטי או עם שם קידומת מותאם אישית ואחריו מספר שנוצר אוטומטית. אם תגדיר את הקידומת ל-cat, אז עם הורדת 3 קבצים, שמות הקבצים יהיו: cat1, cat2, cat3.",
            "כדי להגדיר את ממיר הפורמט האוטומטי, לחץ על סמל ההמרה ",
            ". בחלון המוקפץ שנפתח, בחר את פורמט הקובץ להמרה. אתה יכול להמיר ל-JPEG, PNG או WebP. עבור JPEG ו-WebP, אתה יכול גם להגדיר את האיכות בין 1 ל-10, כאשר 10 הוא האיכות הגבוהה ביותר ו-1 הוא גודל הקובץ הקטן ביותר. PNG הוא פורמט ללא אובדן, כך שהאיכות תמיד מוגדרת ל-10."],
            "ru": ["(Сохранить конфигурацию) подменю",
              "Здесь вы можете задать папки, имена файлов и автоматическое преобразование форматов файлов.",
              "Все папки сохраняются в папке загрузок. По умолчанию каждый раз, когда вы загружаете изображение, расширение генерирует увеличивающийся номер в качестве имени папки, составленный из даты и времени. Чтобы задать папку вручную, измените переключатель на пользовательское поле. Оставьте его пустым, чтобы сохранить непосредственно в папке загрузок или задать пользовательское имя, вы также можете задать подпапку.",
              "В зависимости от ваших настроек имена файлов будут генерироваться автоматически или с пользовательским префиксом имени, за которым следует автоматически сгенерированный номер. Если вы установите префикс cat, то при загрузке 3 файлов имена файлов будут следующими: cat1, cat2, cat3.",
              "Чтобы задать автоматический конвертер форматов, нажмите значок конвертации ",
              ". В открывшемся всплывающем окне выберите формат файла для конвертации. Вы можете конвертировать в JPEG, PNG или WebP. Для JPEG и WebP вы также можете установить качество в диапазоне от 1 до 10, где 10 — самое высокое качество, а 1 — самый маленький размер файла. PNG — это формат без потерь, поэтому качество всегда устанавливается на уровне 10."],
              "tr": ["(Yapılandırmayı kaydet) alt menüsü",
                "Burada klasörleri, dosya adlarını ve dosya biçimlerinin otomatik dönüştürülmesini ayarlayabilirsiniz.",
                "Tüm klasörler indirmeler klasörünüze kaydedilir. Varsayılan olarak her seferinde bir resim indirdiğinizde uzantı, tarih ve saatten oluşan klasör adı olarak artan bir sayı üretir. Klasörü manuel olarak ayarlamak için radyo düğmesini özel alana değiştirin. Doğrudan indirmeler klasörünüze kaydetmek için boş bırakın veya özel bir ad ayarlayın, ayrıca bir alt klasör de ayarlayabilirsiniz.",
                "Ayarlarınıza bağlı olarak dosya adları otomatik olarak veya özel bir önek adı ve ardından otomatik olarak oluşturulan bir sayı ile oluşturulacaktır. Öneki cat olarak ayarlarsanız, 3 dosya indirildiğinde dosyaların adları şu şekilde olacaktır: cat1, cat2, cat3.",
                "Otomatik biçim dönüştürücüyü ayarlamak için dönüştür simgesine basın",
                ". Açılan açılır pencerede dönüştürülecek dosya biçimini seçin. JPEG, PNG veya WebP'ye dönüştürebilirsiniz. JPEG ve WebP için kaliteyi 1 ile 10 arasında da ayarlayabilirsiniz; 10 en yüksek kalite, 1 ise en küçük dosya boyutudur. PNG kayıpsız bir formattır, bu nedenle kalite her zaman 10 olarak ayarlanır."],
                "hu": ["(Konfiguráció mentése) almenü",
                  "Itt állíthatja be a mappákat, a fájlneveket és a fájlformátumok automatikus konvertálását.",
                  "Az összes mappa a letöltések mappájába kerül. Alapértelmezés szerint minden alkalommal, amikor letölt egy képet, a kiterjesztés egyre nagyobb számot generál mappanévként, a dátum és az idő alapján. A mappa kézi beállításához állítsa a választógombot az egyéni mezőre. Hagyja üresen, ha közvetlenül a letöltési mappába szeretné menteni, vagy beállíthat egyéni nevet, de beállíthat egy almappát is.",
                  "A beállításoktól függően a fájlnevek automatikusan vagy egyéni előtagnévvel, majd egy automatikusan generált számmal jönnek létre. Ha az előtagot cat-ra állítod, akkor 3 fájl letöltésekor a fájlok neve: cat1, cat2, cat3.",
                  "Az automatikus formátum konverter beállításához nyomja meg a konvertálás ikont ",
                  ". A megnyíló felugró ablakban válassza ki a konvertálni kívánt fájlformátumot. Konvertálhat JPEG, PNG vagy WebP formátumba. JPEG és WebP esetén a minőséget 1 és 10 között is beállíthatja, ahol a 10 a legjobb minőség, az 1 pedig a legkisebb fájlméret. A PNG veszteségmentes formátum, ezért a minőség mindig 10-re van állítva."],
                  "it": ["(Sottomenu Salva configurazione)",
                    "Qui puoi impostare le cartelle, i nomi dei file e la conversione automatica dei formati dei file.",
                    "Tutte le cartelle vengono salvate nella cartella dei download. Per impostazione predefinita, ogni volta che scarichi un'immagine, l'estensione genera un numero crescente come nome della cartella, creato in base alla data e all'ora. Per impostare manualmente la cartella, modifica il pulsante di scelta nel campo personalizzato. Lascialo vuoto per salvare direttamente nella cartella dei download o imposta un nome personalizzato, puoi anche impostare una sottocartella.",
                    "A seconda delle impostazioni, i nomi dei file verranno generati automaticamente o con un nome di prefisso personalizzato seguito da un numero generato automaticamente. Se imposti il ​​prefisso su cat, dopo aver scaricato 3 file, i nomi dei file saranno: cat1, cat2, cat3.",
                    "Per impostare il convertitore di formato automatico, premi l'icona di conversione ",
                    ". Nella finestra pop-up che si apre, scegli il formato del file in cui convertire. Puoi convertire in JPEG, PNG o WebP. Per JPEG e WebP, puoi anche impostare la qualità tra 1 e 10, dove 10 è la qualità più alta e 1 è la dimensione minima del file. PNG è un formato lossless, quindi la qualità è sempre impostata su 10."],
                    "de": ["Untermenü (Konfiguration speichern)",
                      "Hier können Sie Ordner, Dateinamen und die automatische Konvertierung von Dateiformaten festlegen.",
                      "Alle Ordner werden in Ihrem Download-Ordner gespeichert. Standardmäßig generiert die Erweiterung bei jedem Bilddownload eine aufsteigende Nummer als Ordnernamen, basierend auf Datum und Uhrzeit. Um den Ordner manuell festzulegen, ändern Sie das Optionsfeld in das benutzerdefinierte Feld. Lassen Sie das Feld leer, um direkt im Download-Ordner zu speichern, oder legen Sie einen benutzerdefinierten Namen fest. Sie können auch einen Unterordner festlegen.",
                      "Je nach Ihren Einstellungen werden Dateinamen automatisch generiert oder mit einem benutzerdefinierten Präfix gefolgt von einer automatisch generierten Nummer. Wenn Sie das Präfix „cat“ festlegen, lauten die Dateinamen nach dem Herunterladen von drei Dateien: „cat1“, „cat2“, „cat3“.",
                      "Um die automatische Formatkonvertierung einzustellen, klicken Sie auf das Konvertierungssymbol ",
                      ". Wählen Sie im sich öffnenden Popup-Fenster das zu konvertierende Dateiformat. Sie können in JPEG, PNG oder WebP konvertieren. Für JPEG und WebP können Sie die Qualität ebenfalls zwischen 1 und 10 einstellen, wobei 10 die höchste Qualität und 1 die kleinste Dateigröße darstellt. PNG ist ein verlustfreies Format, daher ist die Qualität immer auf 10 eingestellt."],
                      "fr": ["Sous-menu (Enregistrer la configuration)",
                        "Ce menu vous permet de définir les dossiers, les noms de fichiers et la conversion automatique des formats de fichiers.",
                        "Tous les dossiers sont enregistrés dans votre dossier de téléchargements. Par défaut, à chaque téléchargement d'une image, l'extension génère un nom de dossier croissant, composé de la date et de l'heure. Pour définir manuellement le dossier, sélectionnez le champ personnalisé. Laissez-le vide pour enregistrer directement dans votre dossier de téléchargements ou définissez un nom personnalisé. Vous pouvez également définir un sous-dossier.",
                        "Selon vos paramètres, les noms de fichiers seront générés automatiquement ou avec un préfixe personnalisé suivi d'un numéro généré automatiquement. Si vous définissez le préfixe sur cat, lors du téléchargement de trois fichiers, les noms des fichiers seront : cat1, cat2, cat3.",
                        "Pour configurer le convertisseur de format automatique, appuyez sur l'icône de conversion ",
                        ". Dans la fenêtre contextuelle qui s'ouvre, choisissez le format de fichier à convertir. Vous pouvez convertir en JPEG, PNG ou WebP. Pour les formats JPEG et WebP, vous pouvez également définir la qualité entre 1 et 10, 10 correspondant à la qualité maximale et 1 à la taille minimale. Le PNG étant un format sans perte, la qualité est toujours fixée à 10."],
                        "es": ["Submenú (Guardar configuración)",
                          "Aquí puede configurar las carpetas, los nombres de los archivos y la conversión automática de formatos.",
                          "Todas las carpetas se guardan en su carpeta de descargas. De forma predeterminada, cada vez que descarga una imagen, la extensión genera un número creciente como nombre de carpeta, basado en la fecha y la hora. Para configurar la carpeta manualmente, cambie el botón de opción al campo personalizado. Déjelo vacío para guardar directamente en su carpeta de descargas o establecer un nombre personalizado. También puede establecer una subcarpeta.",
                          "Según su configuración, los nombres de los archivos se generarán automáticamente o con un prefijo personalizado seguido de un número generado automáticamente. Si establece el prefijo cat, al descargar 3 archivos, los nombres de los archivos serán: cat1, cat2, cat3.",
                          "Para configurar el conversor de formato automático, pulse el icono de conversión ",
                          ". En la ventana emergente, seleccione el formato de archivo al que desea convertir. Puede convertir a JPEG, PNG o WebP. Para JPEG y WebP, también puede configurar la calidad entre 1 y 10, donde 10 representa la calidad más alta y 1 el tamaño de archivo más pequeño. PNG es un formato sin pérdida, por lo que la calidad siempre se establece en 10."],
                          "pt": ["(Salvar configuração) submenu",
    "Aqui você pode definir as pastas, nomes de arquivos e conversão automática de formatos de arquivo.",
    "Todas as pastas são salvas na sua pasta de downloads. Por padrão, cada vez que você baixa uma imagem, a extensão gera um número crescente como um nome de pasta, feito a partir da data e hora. Para definir a pasta manualmente, altere o botão de opção para o campo personalizado. Deixe-o vazio para salvar diretamente na sua pasta de downloads ou defina um nome personalizado, você também pode definir uma subpasta.",
    "Dependendo das suas configurações, os nomes dos arquivos serão gerados automaticamente ou com um nome de prefixo personalizado seguido por um número gerado automaticamente. Se você definir o prefixo como cat, ao baixar 3 arquivos, os nomes dos arquivos serão: cat1, cat2, cat3.",
    "Para definir o conversor de formato automático, pressione o ícone de conversão ",
    ". Na janela pop-up que se abre, escolha o formato de arquivo para o qual converter. Você pode converter para JPEG, PNG ou WebP. Para JPEG e WebP, você também pode definir a qualidade entre 1 e 10, com 10 sendo a qualidade mais alta e 1 sendo o menor tamanho de arquivo. PNG é um formato sem perdas, então a qualidade é sempre definida como 10."]
    };
    const display = {
      "en":["(Display) submenu","You can set here the following:",
        "Whether the extension opens in a popup or as a side panel (not available in Firefox). In side panel mode, the extension stays active even when switching tabs.",
        "The maximum width of the image in the result area (make it smaller to fit more images in the visible area at any given time).",
        "The theme.","The user interface language."],
        "he": ["תת-תפריט תצוגה", 
          "כאן ניתן להגדיר את האפשרויות הבאות:", 
          "האם ההרחבה תיפתח בחלון קופץ או כפאנל צד (לא זמין ב-Firefox). במצב פאנל צד, ההרחבה נשארת פעילה גם בעת מעבר בין כרטיסיות.", 
          "הרוחב המרבי של התמונה באזור התוצאות (הקטנת הערך תאפשר הצגת יותר תמונות באזור הגלוי בכל רגע נתון).", 
          "ערכת הנושא.", 
          "שפת ממשק המשתמש."],
          "pt": ["(Exibição) submenu",
            "Aqui você pode configurar as seguintes opções:",
            "Se a extensão será aberta em um pop-up ou como um painel lateral (não disponível no Firefox). No modo painel lateral, a extensão permanece ativa mesmo ao alternar entre abas.",
            "A largura máxima da imagem na área de resultados (reduza para ajustar mais imagens na área visível a qualquer momento).",
            "O tema.",
            "O idioma da interface do usuário."
          ],
          "es": ["(Visualización) submenú",
            "Aquí puede configurar las siguientes opciones:",
            "Si la extensión se abrirá en una ventana emergente o como un panel lateral (no disponible en Firefox). En modo panel lateral, la extensión permanece activa incluso al cambiar de pestañas.",
            "El ancho máximo de la imagen en el área de resultados (reduzca el valor para mostrar más imágenes en el área visible en un momento dado).",
            "El tema.",
            "El idioma de la interfaz de usuario."
          ],
          "fr": ["(Affichage) sous-menu",
            "Vous pouvez configurer ici les options suivantes :",
            "Si l’extension s’ouvre dans une fenêtre popup ou en tant que panneau latéral (non disponible sur Firefox). En mode panneau latéral, l’extension reste active même lors du changement d’onglet.",
            "La largeur maximale de l’image dans la zone de résultats (réduisez-la pour afficher plus d’images simultanément dans la zone visible).",
            "Le thème.",
            "La langue de l’interface utilisateur."
          ],
          "de": ["(Anzeige) Untermenü",
            "Hier können Sie die folgenden Optionen festlegen:",
            "Ob die Erweiterung in einem Popup oder als Seitenpanel geöffnet wird (nicht verfügbar in Firefox). Im Seitenpanel-Modus bleibt die Erweiterung auch beim Wechseln zwischen Tabs aktiv.",
            "Die maximale Breite des Bildes im Ergebnisbereich (verkleinern, um mehr Bilder gleichzeitig im sichtbaren Bereich anzuzeigen).",
            "Das Thema.",
            "Die Sprache der Benutzeroberfläche."
          ],
          "it": ["(Visualizzazione) sottomenu",
            "Qui puoi configurare le seguenti opzioni:",
            "Se l'estensione si apre in un popup o come pannello laterale (non disponibile in Firefox). In modalità pannello laterale, l'estensione rimane attiva anche quando si cambia scheda.",
            "La larghezza massima dell'immagine nell'area dei risultati (riducila per visualizzare più immagini nell'area visibile in qualsiasi momento).",
            "Il tema.",
            "La lingua dell'interfaccia utente."
          ],
          "hu": ["(Kijelző) almenü",
            "Itt beállíthatja a következő lehetőségeket:",
            "Megnyíljon-e a bővítmény egy felugró ablakban vagy egy oldalsó panelként (Firefoxban nem elérhető). Oldalsó panel módban a bővítmény aktív marad még lapváltáskor is.",
            "A kép maximális szélessége az eredményterületen (csökkentse, hogy egyszerre több kép férjen el a látható területen).",
            "A téma.",
            "A felhasználói felület nyelve."
          ],
          "tr": ["(Görüntüleme) alt menü",
            "Burada aşağıdaki ayarları yapabilirsiniz:",
            "Uzantının bir açılır pencerede mi yoksa yan panel olarak mı açılacağını (Firefox'ta mevcut değildir). Yan panel modunda uzantı, sekmeler arasında geçiş yaparken bile aktif kalır.",
            "Sonuç alanındaki görüntünün maksimum genişliği (daha fazla görüntüyü aynı anda görünür alana sığdırmak için küçültün).",
            "Tema.",
            "Kullanıcı arayüzü dili."
          ],
          "ru": ["(Отображение) подменю",
            "Здесь вы можете настроить следующие параметры:",
            "Будет ли расширение открываться во всплывающем окне или в боковой панели (в Firefox недоступно). В режиме боковой панели расширение остается активным даже при переключении вкладок.",
            "Максимальная ширина изображения в области результатов (уменьшите, чтобы одновременно помещалось больше изображений).",
            "Тема.",
            "Язык интерфейса пользователя."
          ],
          "vi": ["(Hiển thị) menu con",
            "Tại đây bạn có thể cài đặt các tùy chọn sau:",
            "Tiện ích sẽ mở dưới dạng cửa sổ bật lên hay bảng bên (không khả dụng trên Firefox). Ở chế độ bảng bên, tiện ích vẫn hoạt động ngay cả khi chuyển đổi giữa các tab.",
            "Chiều rộng tối đa của hình ảnh trong khu vực kết quả (giảm để hiển thị nhiều hình ảnh hơn trong vùng nhìn thấy cùng một lúc).",
            "Chủ đề.",
            "Ngôn ngữ giao diện người dùng."
          ],
          "th": ["(แสดงผล) เมนูย่อย",
            "คุณสามารถตั้งค่าตัวเลือกต่อไปนี้:",
            "ส่วนขยายจะเปิดในป๊อปอัปหรือเป็นแถบด้านข้าง (ใช้ไม่ได้ใน Firefox) ในโหมดแถบด้านข้าง ส่วนขยายจะยังคงทำงานอยู่แม้จะเปลี่ยนแท็บก็ตาม",
            "ความกว้างสูงสุดของรูปภาพในพื้นที่ผลลัพธ์ (ลดขนาดเพื่อให้แสดงรูปภาพได้มากขึ้นในพื้นที่ที่มองเห็นได้ในคราวเดียว)",
            "ธีม.",
            "ภาษาของอินเทอร์เฟซผู้ใช้."
          ],
          "ko": ["(디스플레이) 하위 메뉴",
            "여기에서 다음 옵션을 설정할 수 있습니다:",
            "확장 프로그램이 팝업 창에서 열릴지 아니면 사이드 패널에서 열릴지 설정합니다(Firefox에서는 사용할 수 없음). 사이드 패널 모드에서는 탭을 전환해도 확장 프로그램이 활성 상태로 유지됩니다.",
            "결과 영역에서 이미지의 최대 너비(더 많은 이미지를 한 번에 표시하려면 크기를 줄이세요).",
            "테마.",
            "사용자 인터페이스 언어."
          ],
          "zh_CN": ["(显示) 子菜单",
            "您可以在此处设置以下选项：",
            "扩展程序是以弹出窗口还是侧边面板的方式打开（Firefox 不支持）。在侧边面板模式下，即使切换标签，扩展程序仍然保持活动状态。",
            "结果区域中图像的最大宽度（缩小可以在可见区域内显示更多图像）。",
            "主题。",
            "用户界面语言。"
          ],
          "zh_TW": ["(顯示) 子選單",
            "您可以在此處設定以下選項：",
            "擴充功能是以彈出視窗還是側邊面板的方式開啟（Firefox 不支援）。在側邊面板模式下，即使切換標籤頁，擴充功能仍然保持啟動狀態。",
            "結果區域中圖片的最大寬度（縮小可在可見區域內顯示更多圖片）。",
            "主題。",
            "使用者介面語言。"
          ],
          "ja": ["(ディスプレイ) サブメニュー",
            "ここで以下の設定を行えます：",
            "拡張機能をポップアップで開くか、サイドパネルで開くかを設定できます（Firefoxでは利用不可）。サイドパネルモードでは、タブを切り替えても拡張機能がアクティブのままになります。",
            "結果エリアの画像の最大幅（小さくすると、同時に表示できる画像の数が増えます）。",
            "テーマ。",
            "ユーザーインターフェースの言語。"
          ]
    }
    const {language} = useParams();
    let lang = language;
    document.title = "Image downloader";
    const locals = ['pt','es','fr','de','it','hu','tr','ru','he','vi','th','ko','zh_CN','zh_TW','ja'];                
    if(!locals.includes(lang)){
      lang='en';
    }
    let langT = 'en';
    if(lang == 'ja' || lang == 'zh_TW' || lang =='zh_CN' || lang == 'ko'){
      langT = lang;
    }
    const hash = useHash();
  useEffect(() => {
    const section = hash.replace("#", "");
    if (section) scrollToSection(section);
  }, [hash]);
    return(
        <div style={{direction: lang != 'he' ? 'ltr' : 'rtl'}}>
        <Header title="Image Downloader" x="20"/>
        <Lang />
        <div id="i-d-quick-nav">
                <b>
                    <ul style={{listStyleType:'none', margin: '0 0 0 0', padding: '0 0 0 0'}}>
                        <li><a href="#limitations">{limition[lang][0]}</a></li>
                        <li><a href="#check-settings">{checkSettings[lang][0]}</a></li>
                        <li><a href="#pin-extension-icon">Pin extension icon</a></li>
                        <li><a href="#tutorial">{userGuideOpening[lang][0]}</a></li>
                        <li><a href="#troubleshooting">Troubleshooting</a></li>
                        <li><a href="#support-us">Support us</a></li>
                    </ul>
                </b>
            </div>
        <div className="article">
        <p><b>{description[lang][0]}</b></p>
        <p>
{description[lang][1]}<br/>
{description[lang][2]}<br/>
{description[lang][3]}</p>
<p>
<b>{description[lang][4]}</b>
<br/>
<ul>
    <li>{description[lang][5]}</li>
    <li>{description[lang][6]}</li>
    <li>{description[lang][7]}</li>
    <li>{description[lang][8]}</li>
    <li>{description[lang][9]}</li>
    <li>{description[lang][10]}</li>
    </ul></p>
    {description[lang][11]}<a href="https://chromewebstore.google.com/detail/image-downloader-save-all/fpelahbljekknahkcaegamhcndkfpfnc" className="button-link">Chrome</a> &nbsp;
 <a href="https://microsoftedge.microsoft.com/addons/detail/image-downloader-save-a/flbolonkboilcbnohjlchlfgdalomdpl" className="button-link">Edge</a>  &nbsp;
 <a href="https://addons.mozilla.org/en-US/firefox/addon/image-downloader-for-firefox/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search" className="button-link">Firefox</a>
        </div>
<div id="limitations" className="main-title">
    <p>{limition[lang][0]}</p>
</div>
<div className="article">
    <ul>
        <li>{limition[lang][1]}</li>
        <li>{limition[lang][2]}</li>
        <li>{limition[lang][3]}</li>
        <li>{limition[lang][4]}</li>
        <li>{limition[lang][5]}</li>
    </ul>
</div>
            <div id="check-settings" className="main-title"><p>{checkSettings[lang][0]}</p></div>
        <div className="article">
            {checkSettings[lang][1]}<b>Chrome</b>{checkSettings[lang][2]}<b>Edge</b>{checkSettings[lang][3]}<b>{checkSettings[lang][4]}</b>{checkSettings[lang][5]}<b>{checkSettings[lang][6]}</b>{checkSettings[lang][7]}<b>{checkSettings[lang][8]}</b>{checkSettings[lang][9]}
            <br/><img id="i-d-check" src={"../assets/image downloader/"+lang+"/check-browser.png"} style={{marginTop:"24px"}}/><br/>
            {checkSettings[lang][10]}<b>Firefox</b>{checkSettings[lang][11]}<b>{checkSettings[lang][12]}</b>{checkSettings[lang][13]}<b>{checkSettings[lang][14]}</b>{checkSettings[lang][15]}<b>{checkSettings[lang][16]}</b>{checkSettings[lang][17]}
        </div>
        <PinExtension lang={lang} />
        <div id="tutorial" className="main-title"><p> {userGuideOpening[lang][0]}</p></div>
        <div className="article">
        <p>
        {userGuideOpening[lang][1]}
        </p><img src="../assets/image downloader 1.png" /> <br/>
        {userGuideOpening[lang][2]}{userGuideOpening[lang][3]}<img src="../assets/save-icon.png" />{userGuideOpening[lang][4]}
        <p>
        {userGuideOpening[lang][5]}{userGuideOpening[lang][6]}<img src="../assets/new-tab.svg" width="20px" />{userGuideOpening[lang][7]}{userGuideOpening[lang][8]}<img src="../assets/download-selected.png" />{userGuideOpening[lang][9]}{userGuideOpening[lang][10]} <img src="../assets/download-all.svg" width="20px" />{userGuideOpening[lang][11]}
        </p>
        {userGuideOpening[lang][12]}<img src="../assets/hamburger.svg" width="20px" />{userGuideOpening[lang][13]}
    <p><h3 className='submenu'>{size[lang][0]}</h3></p> {size[lang][1]}<b>{size[lang][2]}</b>{size[lang][3]}<b>{size[lang][4]}</b>{size[lang][5]}<b>{size[lang][6]}</b>{size[lang][7]}<b>{size[lang][8]}</b>{size[lang][9]}<b>{size[lang][10]}</b>{size[lang][11]}<br/>
            <p><h3 className='submenu'>{typeLayout[lang][0]}</h3></p>{typeLayout[lang][1]}
            <p><h3 className='submenu'>{typeLayout[lang][2]}</h3></p>{typeLayout[lang][3]}
            <p><h3 className='submenu'>{advanced[lang][0]}</h3></p>
             <p>{advanced[lang][1]}<img src="../assets/trash.svg" width="20px" />{advanced[lang][2]}
             {advanced[lang][3]}
             </p><p> {advanced[lang][4]}</p>
                          <p><h3 className='submenu' id="save-config"><img src="../assets/save config.svg" width="30px" /> {save[lang][0]}</h3>
             </p>
             {save[lang][1]}
             <p>{save[lang][2]}</p> 
                <p>
                  {save[lang][3]}
                    </p>
                    <p>{save[lang][4]} <img src="../assets/convarte.svg" width="36px" />
                    {save[lang][5]}
                </p>
                <p><h3 className='submenu'><img src="../assets/display.svg" width="24px" /> {display[lang][0]}</h3></p> {display[lang][1]}<br/><ul>
                <li>
                <img src="../assets/popup.svg" width="32px" />&nbsp;<img src="../assets/side-panel.svg" width="32px" />&nbsp;{display[lang][2]}                  
                </li>
                    <li>
                    <img src="../assets/result-width.svg" width="32px" />&nbsp;{display[lang][3]}
                    </li>
                    <li>
                        <img src="../assets/theme.png"/>&nbsp;&nbsp;{display[lang][4]}
                    </li>
                    <li>
                        <img src="../assets/language.svg" width="32px" />&nbsp;{display[lang][5]}
                    </li>
                    </ul>
        </div>
        <div id="troubleshooting" className="main-title">
    <p>Troubleshooting</p>
</div>

<div className="article">
    <div>
        <h3 className='submenu'>Extension failed to capture the images</h3>
        <p>
            Refresh the page, wait until the images are loaded on the page, then open the extension. If it still fails, it is possible your filter is set too strict or that the site employs techniques that prevent image detection. See if you can capture images on a different site.
        </p>
    </div>
    
    <div>
        <h3 className='submenu'>Can't set the name prefix</h3>
        <p>
        This is probably due to a conflict with another later installed extension. try to disable and then enable the image downloader extension.
        </p>
    </div>

    <div>
        <h3 className='submenu'>Browser prompts you to manually save every image</h3>
        <p>
            Follow the <Link to="#check-settings"><span className="button-link">Check browser settings</span></Link> section instructions.
        </p>
    </div>

    <div>
        <h3 className='submenu'>Unable to use in private mode</h3>
        <p>
            Browsers require users to explicitly allow extensions to work in private (incognito) mode. Here are Instructions on how to set the extension in private mode for:&nbsp; 
            <a href="https://support.google.com/chrome/a/answer/13130396?hl=en" className="button-link">Chrome</a>&nbsp;
            <a href="https://www.tenforums.com/tutorials/166235-allow-extensions-inprivate-browsing-microsoft-edge-chromium.html" className="button-link">Edge</a>&nbsp;
            <a href="https://support.mozilla.org/en-US/kb/extensions-private-browsing" className="button-link">Firefox</a>
        </p>
    </div>

    <div>
        <h3><b>If you encounter an issue, you can always <Link to="/contact/en"><span className="button-link">contact</span></Link> us for help.</b></h3>
    </div>
</div>
        <img src="../assets/rate-us.png" style={{paddingTop:'48px',paddingLeft:'16px', float: 'right'}}/>

            <SupportUS />
        </div>
        )
}{/*ToDo:     
translate pin extetesion
    */}